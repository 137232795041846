import React from 'react'

import PageSectionContent from './PageSectionContent'
import PageSectionHeader from './PageSectionHeader'
import PageSectionSubtitle from './PageSectionSubtitle'
import PageSectionTitle from './PageSectionTitle'
import { snakeCase } from 'lodash'

const PageSection = ({ children, className, testKey, title }: any) => {
  return (
    <section css={styles} data-test={testKey || `${snakeCase(title)}_section`} className={className}>
      {children}
    </section>
  )
}

const styles = {
  display: 'grid',
  gridTemplateColumns: '1fr',
}

PageSection.Content = PageSectionContent
PageSection.Header = PageSectionHeader
PageSection.Subtitle = PageSectionSubtitle
PageSection.Title = PageSectionTitle

export default PageSection
