import React from 'react'

import AddressSelectorInput from '../../../components/Forms/AddressSelectorInput'
import EmailInput from '../../../components/Forms/EmailInput'
import Input from '../../../components/Forms/Input'
import PhoneInput from '../../../components/Forms/PhoneInput'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import SmartTextarea from '../../../components/Forms/SmartTextarea'
import YesNoRadioGroup from '../../../components/Forms/elements/YesNoRadioGroup'
import YesNoRadioGroupWithTextarea from '../../../components/Forms/elements/YesNoRadioGroupWithTextarea'
import SignatureInput from '../../../components/Forms/SignatureInput'
import DateInput from '../../../components/Forms/DateInput'

export const INSPECTION_CHECKLIST_FORM = [
  {
    title: 'Organization Information',
    model: 'organization_info',
    fields: [
      {
        label: 'Name of Organization',
        model: 'name',
        component: ({ isRequired }) => (
          <Input
            label="Name of Organization"
            model="organization_info.name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Home Name',
        model: 'home_name',
        component: ({ isRequired }) => (
          <Input
            label="Home Name"
            model="organization_info.home_name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Property owner if different',
        model: 'property_owner',
        component: ({ isRequired }) => (
          <Input
            label="Property owner if different"
            model="organization_info.property_owner"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Number of Current Residents',
        model: 'current_residents',
        component: ({ isRequired }) => (
          <Input
            label="Number of Current Residents"
            model="organization_info.current_residents"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'ADA Accessible?',
        model: 'ada_accessible',
        component: ({ isRequired }) => (
          <>
            <YesNoRadioGroup
              label="ADA Accessible?"
              model="organization_info.ada_accessible"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
            <SmartTextarea
              useDictation
              useQuickText={false}
              label="Please explain"
              model="organization_info.ada_accessible_description"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
          </>
        ),
      },
      {
        label: 'Organization Address',
        model: 'address',
        component: ({ isRequired }) => (
          <AddressSelectorInput
            label="Organization Address"
            model="organization_info.address"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Organization Contact Name',
        model: 'contact_name',
        component: ({ isRequired }) => (
          <Input
            label="Organization Contact Name"
            model="organization_info.contact_name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Organization Contact Phone',
        model: 'contact_phone',
        component: ({ isRequired }) => (
          <Input
            label="Organization Contact Phone"
            model="organization_info.contact_phone"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Email',
        model: 'email',
        component: ({ isRequired }) => (
          <EmailInput
            label="Email"
            model="organization_info.email"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Fax Number',
        model: 'fax',
        component: ({ isRequired }) => (
          <PhoneInput
            label="Fax Number"
            model="organization_info.fax"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'House Manager Name',
        model: 'house_manager_name',
        component: ({ isRequired }) => (
          <Input
            label="House Manager Name"
            model="organization_info.house_manager_name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Does house manager live on site?',
        model: 'manager_onsite',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Does house manager live on site?"
            textareaLabel="Please explain"
            model="organization_info.manager_onsite"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Inspection Details',
    model: 'inspection_details',
    fields: [
      {
        label: 'Type of Inspection',
        model: 'type',
        component: ({ isRequired }) => (
          <RadioGroup
            label="Type of Inspection"
            layout="vertical-dense"
            model="inspection_details.type"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          >
            <Radio label="Initial" value="initial" />
            <Radio label="Special" value="special" />
            <Radio label="Renewal" value="renewal" />
          </RadioGroup>
        ),
      },
      {
        label: 'Neighborhood type/location - does property blend',
        model: 'property_blend',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Neighborhood type/location - does property blend"
            textareaLabel="Please explain"
            model="inspection_details.property_blend"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Mailbox',
        model: 'mailbox',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Mailbox"
            textareaLabel="Please explain"
            model="inspection_details.mailbox"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Address visible',
        model: 'address_visible',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Address visible"
            textareaLabel="Please explain"
            model="inspection_details.address_visible"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'HOA Yes or No',
        model: 'hoa',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="HOA Yes or No"
            model="inspection_details.hoa"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'HOA do they know property is recovery residence',
        model: 'hoa_aware',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="HOA do they know property is recovery residence"
            textareaLabel="Please explain"
            model="inspection_details.hoa_aware"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Are you approved per codes & zoning',
        model: 'codes_zoning_approved',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Are you approved per codes & zoning"
            textareaLabel="Please explain"
            model="inspection_details.codes_zoning_approved"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Home type/gender',
        model: 'home_type_gender',
        component: ({ isRequired }) => (
          <Input
            label="Home type/gender"
            model="inspection_details.home_type_gender"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Property Condition',
    model: 'property_condition',
    fields: [
      {
        label: 'Does home feel like a home, not clinical/institutional?',
        model: 'home_feel',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Does home feel like a home, not clinical/institutional?"
            textareaLabel="Please explain"
            model="property_condition.home_feel"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'No interior furniture being used outdoors',
        model: 'no_interior_furniture_outdoors',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="No interior furniture being used outdoors"
            model="property_condition.no_interior_furniture_outdoors"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Furniture in good repair',
        model: 'furniture_good_repair',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Furniture in good repair"
            model="property_condition.furniture_good_repair"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Furniture being used for intended purpose',
        model: 'furniture_intended_purpose',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Furniture being used for intended purpose"
            model="property_condition.furniture_intended_purpose"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Residents park cars in permitted/acceptable areas',
        model: 'parking_acceptable',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Residents park cars in permitted/acceptable areas"
            model="property_condition.parking_acceptable"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Property is smoke-free or designated smoking outside',
        model: 'smoke_free_or_designated',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Property is smoke-free or designated smoking outside"
            model="property_condition.smoke_free_or_designated"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Entrance and exits are in good repair and safe',
        model: 'entrances_exits_safe',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Entrance and exits are in good repair and safe"
            model="property_condition.entrances_exits_safe"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Residents are not locked in or out',
        model: 'residents_not_locked',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Residents are not locked in or out"
            model="property_condition.residents_not_locked"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Door does lock',
        model: 'door_locks',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Door does lock"
            model="property_condition.door_locks"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Resident Amenities',
    model: 'resident_amenities',
    fields: [
      {
        label: 'Resident Handbook/binder or posting available',
        model: 'handbook_available',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Resident Handbook/binder or posting available"
            model="resident_amenities.handbook_available"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Residents may use kitchen to prepare meals when they want',
        model: 'kitchen_access',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Residents may use kitchen to prepare meals when they want"
            model="resident_amenities.kitchen_access"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Shared phone available',
        model: 'shared_phone',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Shared phone available"
            model="resident_amenities.shared_phone"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'One fridge per five residents',
        model: 'fridge_ratio',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="One fridge per five residents"
            model="resident_amenities.fridge_ratio"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'If gas appliances, carbon monoxide detector in kitchen',
        model: 'co_detector_kitchen',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="If gas appliances, carbon monoxide detector in kitchen"
            model="resident_amenities.co_detector_kitchen"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Residents have access to common area large enough to accommodate home (hold house meetings, recreate, etc)',
        model: 'common_area_access',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Residents have access to common area large enough to accommodate home (hold house meetings, recreate, etc)"
            model="resident_amenities.common_area_access"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Residents store food in kitchen and not rooms',
        model: 'food_storage_kitchen',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Residents store food in kitchen and not rooms"
            model="resident_amenities.food_storage_kitchen"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Covered waste receptacle',
        model: 'covered_waste_receptacle',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Covered waste receptacle"
            model="resident_amenities.covered_waste_receptacle"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'House chores appear to be followed (common areas, bathrooms, food storage, dishes, etc)',
        model: 'chores_followed',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="House chores appear to be followed (common areas, bathrooms, food storage, dishes, etc)"
            model="resident_amenities.chores_followed"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Interior Condition',
    model: 'interior_condition',
    fields: [
      {
        label: 'Interior Paint is well maintained',
        model: 'paint_maintained',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Interior Paint is well maintained"
            model="interior_condition.paint_maintained"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Flooring, carpets, etc in good condition (whole property)',
        model: 'flooring_good_condition',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Flooring, carpets, etc in good condition (whole property)"
            model="interior_condition.flooring_good_condition"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'House has adequate cleaning supplies',
        model: 'adequate_cleaning_supplies',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="House has adequate cleaning supplies"
            model="interior_condition.adequate_cleaning_supplies"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Rooms have adequate storage space, including for Rx containers, locked for valuables, etc',
        model: 'adequate_storage',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Rooms have adequate storage space, including for Rx containers, locked for valuables, etc"
            model="interior_condition.adequate_storage"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: '2 ft of hanging space per person',
        model: 'hanging_space',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="2 ft of hanging space per person"
            model="interior_condition.hanging_space"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: '2-4 drawers per person',
        model: 'drawers_per_person',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="2-4 drawers per person"
            model="interior_condition.drawers_per_person"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Each sink has supplies appropriate for hand washing',
        model: 'sink_supplies',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Each sink has supplies appropriate for hand washing"
            model="interior_condition.sink_supplies"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Laundry Facilities',
    model: 'laundry_facilities',
    fields: [
      {
        label: 'Washer and dryer provided OR residents have access to local laundromat',
        model: 'laundry_access',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Washer and dryer provided OR residents have access to local laundromat"
            model="laundry_facilities.laundry_access"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Washer and dryer in good condition',
        model: 'machines_good_condition',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Washer and dryer in good condition"
            model="laundry_facilities.machines_good_condition"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Fire extinguisher in laundry room',
        model: 'fire_extinguisher',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Fire extinguisher in laundry room"
            model="laundry_facilities.fire_extinguisher"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Climate Control and Plumbing',
    model: 'climate_plumbing',
    fields: [
      {
        label: 'Ventilation/Cooling',
        model: 'ventilation_cooling',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Ventilation/Cooling"
            model="climate_plumbing.ventilation_cooling"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Plumbing/Sewer',
        model: 'plumbing_sewer',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Plumbing/Sewer"
            model="climate_plumbing.plumbing_sewer"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Bathroom Facilities',
    model: 'bathroom_facilities',
    fields: [
      {
        label: 'One Sink, shower, and toilet per 6 residents',
        model: 'facilities_ratio',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="One Sink, shower, and toilet per 6 residents"
            model="bathroom_facilities.facilities_ratio"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Bathroom Fixtures in good condition',
        model: 'fixtures_condition',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Bathroom Fixtures in good condition"
            model="bathroom_facilities.fixtures_condition"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Tub/Shower/Toilet/Sink working properly',
        model: 'facilities_working',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Tub/Shower/Toilet/Sink working properly"
            model="bathroom_facilities.facilities_working"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Exterior Condition',
    model: 'exterior_condition',
    fields: [
      {
        label: 'Any external buildings are in good repair / House exterior in good repair',
        model: 'exterior_good_repair',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Any external buildings are in good repair / House exterior in good repair"
            model="exterior_condition.exterior_good_repair"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Foundation in good condition',
        model: 'foundation_condition',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Foundation in good condition"
            model="exterior_condition.foundation_condition"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Stairs, rails, porches in good condition',
        model: 'stairs_rails_porches',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Stairs, rails, porches in good condition"
            model="exterior_condition.stairs_rails_porches"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Lighting on porches/common areas',
        model: 'exterior_lighting',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Lighting on porches/common areas"
            model="exterior_condition.exterior_lighting"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Elevator',
        model: 'elevator',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Elevator"
            model="exterior_condition.elevator"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Roof/Gutters in good condition',
        model: 'roof_gutters',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Roof/Gutters in good condition"
            model="exterior_condition.roof_gutters"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Exterior surfaces in good condition',
        model: 'exterior_surfaces',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Exterior surfaces in good condition"
            model="exterior_condition.exterior_surfaces"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Home and grounds match surroundings',
        model: 'match_surroundings',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Home and grounds match surroundings"
            model="exterior_condition.match_surroundings"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'All painted surfaces free of deteriorated paint',
        model: 'paint_condition',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="All painted surfaces free of deteriorated paint"
            model="exterior_condition.paint_condition"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Safety and Electrical',
    model: 'safety_electrical',
    fields: [
      {
        label: 'Extension cords are used appropriately (example, not being used to bring electricity from room to room)',
        model: 'extension_cords',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Extension cords are used appropriately (example, not being used to bring electricity from room to room)"
            model="safety_electrical.extension_cords"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Nothing to obstruct resident egress in case of emergency',
        model: 'clear_egress',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Nothing to obstruct resident egress in case of emergency"
            model="safety_electrical.clear_egress"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'All cooking appliances stored appropriately',
        model: 'appliances_storage',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="All cooking appliances stored appropriately"
            model="safety_electrical.appliances_storage"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Light switches, electrical outlets, vents, etc have covers',
        model: 'electrical_covers',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Light switches, electrical outlets, vents, etc have covers"
            model="safety_electrical.electrical_covers"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'GFCI Outlets w/i 6 ft of water source',
        model: 'gfci_outlets',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="GFCI Outlets w/i 6 ft of water source"
            model="safety_electrical.gfci_outlets"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Interior Surfaces',
    model: 'interior_surfaces',
    fields: [
      {
        label: 'Ceilings in good repair (whole property)',
        model: 'ceilings',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Ceilings in good repair (whole property)"
            model="interior_surfaces.ceilings"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Wall condition (whole property)',
        model: 'walls',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Wall condition (whole property)"
            model="interior_surfaces.walls"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Floor condition (whole property)',
        model: 'floors',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Floor condition (whole property)"
            model="interior_surfaces.floors"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Window in good repair (whole property)',
        model: 'windows',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Window in good repair (whole property)"
            model="interior_surfaces.windows"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'No loose or missing tiles in kitchen/bathroom',
        model: 'tiles',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="No loose or missing tiles in kitchen/bathroom"
            model="interior_surfaces.tiles"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Utilities and Safety',
    model: 'utilities_safety',
    fields: [
      {
        label: 'House has hot/cold water, heat, electricity',
        model: 'basic_utilities',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="House has hot/cold water, heat, electricity"
            model="utilities_safety.basic_utilities"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Hot water heater in good repair, connected to wall or solid structure',
        model: 'water_heater',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Hot water heater in good repair, connected to wall or solid structure"
            model="utilities_safety.water_heater"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Naloxone is accessible',
        model: 'naloxone_accessible',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Naloxone is accessible"
            model="utilities_safety.naloxone_accessible"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Property Details',
    model: 'property_details',
    fields: [
      {
        label: 'Bed #',
        model: 'bed_count',
        component: ({ isRequired }) => (
          <Input
            label="Bed #"
            model="property_details.bed_count"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Bedroom #',
        model: 'bedroom_count',
        component: ({ isRequired }) => (
          <Input
            label="Bedroom #"
            model="property_details.bedroom_count"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Full bath #',
        model: 'full_bath_count',
        component: ({ isRequired }) => (
          <Input
            label="Full bath #"
            model="property_details.full_bath_count"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Fridge #',
        model: 'fridge_count',
        component: ({ isRequired }) => (
          <Input
            label="Fridge #"
            model="property_details.fridge_count"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Stove',
        model: 'stove',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Stove"
            textareaLabel="Please explain"
            model="property_details.stove"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Oven',
        model: 'oven',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Oven"
            textareaLabel="Please explain"
            model="property_details.oven"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Freezer',
        model: 'freezer',
        component: ({ isRequired }) => (
          <Input
            label="Freezer"
            model="property_details.freezer"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Sink #',
        model: 'sink_count',
        component: ({ isRequired }) => (
          <Input
            label="Sink #"
            model="property_details.sink_count"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Shower #',
        model: 'shower_count',
        component: ({ isRequired }) => (
          <Input
            label="Shower #"
            model="property_details.shower_count"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Toilet #',
        model: 'toilet_count',
        component: ({ isRequired }) => (
          <Input
            label="Toilet #"
            model="property_details.toilet_count"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Narcan expiration',
        model: 'narcan_expiration',
        component: ({ isRequired }) => (
          <Input
            label="Narcan expiration"
            model="property_details.narcan_expiration"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Standard Level',
        model: 'standard_level',
        component: ({ isRequired }) => (
          <Input
            label="Standard Level"
            model="property_details.standard_level"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Home Address',
        model: 'home_address',
        component: ({ isRequired }) => (
          <AddressSelectorInput
            label="Home Address"
            model="property_details.home_address"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'House monitor',
        model: 'house_monitor',
        component: ({ isRequired }) => (
          <Input
            label="House monitor"
            model="property_details.house_monitor"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Program Requirements',
    model: 'program_requirements',
    fields: [
      {
        label: '12-step/recovery meeting requirement',
        model: 'recovery_meeting_requirement',
        component: ({ isRequired }) => (
          <>
            <YesNoRadioGroup
              label="12-step/recovery meeting requirement"
              model="program_requirements.recovery_meeting_requirement"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
            <SmartTextarea
              useDictation
              useQuickText={false}
              label="Please explain"
              model="program_requirements.recovery_meeting_requirement_description"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
          </>
        ),
      },
      {
        label: 'Length of stay allowed',
        model: 'length_of_stay',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Length of stay allowed"
            textareaLabel="Please explain"
            model="program_requirements.length_of_stay"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Documentation received and approved',
        model: 'documentation_approved',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Documentation received and approved"
            textareaLabel="Please explain"
            model="program_requirements.documentation_approved"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Payment has been received',
        model: 'payment_received',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Payment has been received"
            textareaLabel="Please explain"
            model="program_requirements.payment_received"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Inspection Type',
    model: 'inspection_type',
    fields: [
      {
        label: 'Initial application site review',
        model: 'initial_review',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Initial application site review"
            model="inspection_type.initial_review"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Re-certification/annual site review',
        model: 'recertification_review',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Re-certification/annual site review"
            model="inspection_type.recertification_review"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Random/pop-in site review',
        model: 'random_review',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Random/pop-in site review"
            model="inspection_type.random_review"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Program Policies',
    model: 'program_policies',
    fields: [
      {
        label: 'MAT acceptance',
        model: 'mat_acceptance',
        component: ({ isRequired }) => (
          <>
            <YesNoRadioGroup
              label="MAT acceptance"
              model="program_policies.mat_acceptance"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
            <SmartTextarea
              useDictation
              useQuickText={false}
              label="Please explain"
              model="program_policies.mat_acceptance_description"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
          </>
        ),
      },
      {
        label: 'Resident records secure & reviewed',
        model: 'records_secure',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Resident records secure & reviewed"
            textareaLabel="Please explain"
            model="program_policies.records_secure"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Postings and Resources',
    model: 'postings_resources',
    fields: [
      {
        label: 'Posting - code of ethics',
        model: 'code_of_ethics',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Posting - code of ethics"
            textareaLabel="Please explain"
            model="postings_resources.code_of_ethics"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Posting - rules',
        model: 'rules',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Posting - rules"
            textareaLabel="Please explain"
            model="postings_resources.rules"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Post or binder - community resources',
        model: 'community_resources',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Post or binder - community resources"
            textareaLabel="Please explain"
            model="postings_resources.community_resources"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Posting - emergency numbers & home address',
        model: 'emergency_info',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Posting - emergency numbers & home address"
            textareaLabel="Please explain"
            model="postings_resources.emergency_info"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Posting - MARR cleaning list',
        model: 'cleaning_list',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Posting - MARR cleaning list"
            textareaLabel="Please explain"
            model="postings_resources.cleaning_list"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Posting - maintenance list',
        model: 'maintenance_list',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Posting - maintenance list"
            textareaLabel="Please explain"
            model="postings_resources.maintenance_list"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Posting - recipient rights',
        model: 'recipient_rights',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Posting - recipient rights"
            textareaLabel="Please explain"
            model="postings_resources.recipient_rights"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Posting - community resources',
        model: 'community_resources_posting',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Posting - community resources"
            textareaLabel="Please explain"
            model="postings_resources.community_resources_posting"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Posting - grievance & appeals policy',
        model: 'grievance_policy',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Posting - grievance & appeals policy"
            textareaLabel="Please explain"
            model="postings_resources.grievance_policy"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Health and Safety',
    model: 'health_safety',
    fields: [
      {
        label: 'Signs of Pests/Infestations',
        model: 'pests_infestations',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Signs of Pests/Infestations"
            textareaLabel="Please explain"
            model="health_safety.pests_infestations"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Signs of mold/mildew',
        model: 'mold_mildew',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Signs of mold/mildew"
            textareaLabel="Please explain"
            model="health_safety.mold_mildew"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Thermometers in all fridge/freezers / Set appropriately',
        model: 'thermometers',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Thermometers in all fridge/freezers / Set appropriately"
            textareaLabel="Please explain"
            model="health_safety.thermometers"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Resident Interaction',
    model: 'resident_interaction',
    fields: [
      {
        label: 'Met with a resident 1:1',
        model: 'met_resident',
        component: ({ isRequired }) => (
          <>
            <YesNoRadioGroup
              label="Met with a resident 1:1"
              model="resident_interaction.met_resident"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
            <SmartTextarea
              useDictation
              useQuickText={false}
              label="Please explain"
              model="resident_interaction.met_resident_description"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
          </>
        ),
      },
    ],
  },
  {
    title: 'Living Conditions',
    model: 'living_conditions',
    fields: [
      {
        label: 'Bedrooms/Size meet standards',
        model: 'bedroom_standards',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Bedrooms/Size meet standards"
            textareaLabel="Please explain"
            model="living_conditions.bedroom_standards"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Each bedroom has heat and light source',
        model: 'bedroom_heat_light',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Each bedroom has heat and light source"
            textareaLabel="Please explain"
            model="living_conditions.bedroom_heat_light"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Enough seating in house for everyone',
        model: 'sufficient_seating',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Enough seating in house for everyone"
            textareaLabel="Please explain"
            model="living_conditions.sufficient_seating"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Adequate food storage per person',
        model: 'food_storage',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Adequate food storage per person"
            textareaLabel="Please explain"
            model="living_conditions.food_storage"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Food available',
        model: 'food_available',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Food available"
            textareaLabel="Please explain"
            model="living_conditions.food_available"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Provide or refer out for HIV and Hep A, B, C testing',
        model: 'health_testing',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Provide or refer out for HIV and Hep A, B, C testing"
            textareaLabel="Please explain"
            model="living_conditions.health_testing"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'All food and beverages off floor',
        model: 'food_off_floor',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="All food and beverages off floor"
            model="living_conditions.food_off_floor"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Fire Safety',
    model: 'fire_safety',
    fields: [
      {
        label: 'Fire extinguishers met standards',
        model: 'extinguishers_standards',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Fire extinguishers met standards"
            textareaLabel="Please explain"
            model="fire_safety.extinguishers_standards"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Fire extinguisher in plain sight or clearly marked location',
        model: 'extinguisher_visible',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Fire extinguisher in plain sight or clearly marked location"
            textareaLabel="Please explain"
            model="fire_safety.extinguisher_visible"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Smoke detectors meet standards',
        model: 'smoke_detectors_standards',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Smoke detectors meet standards"
            textareaLabel="Please explain"
            model="fire_safety.smoke_detectors_standards"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Extra batteries for smoke/CO detector',
        model: 'extra_batteries',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Extra batteries for smoke/CO detector"
            textareaLabel="Please explain"
            model="fire_safety.extra_batteries"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Smoke detector in kitchen',
        model: 'kitchen_smoke_detector',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Smoke detector in kitchen"
            textareaLabel="Please explain"
            model="fire_safety.kitchen_smoke_detector"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Smoke detector in each bedroom',
        model: 'bedroom_smoke_detectors',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Smoke detector in each bedroom"
            textareaLabel="Please explain"
            model="fire_safety.bedroom_smoke_detectors"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'CO detector within 9 feet of sleeping rooms',
        model: 'co_detector_placement',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="CO detector within 9 feet of sleeping rooms"
            textareaLabel="Please explain"
            model="fire_safety.co_detector_placement"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'CO detectors meet standards',
        model: 'co_detectors_standards',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="CO detectors meet standards"
            textareaLabel="Please explain"
            model="fire_safety.co_detectors_standards"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'No overloaded electrical outlets',
        model: 'no_overloaded_outlets',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="No overloaded electrical outlets"
            textareaLabel="Please explain"
            model="fire_safety.no_overloaded_outlets"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'General Conditions',
    model: 'general_conditions',
    fields: [
      {
        label: 'Home is clean and well-maintained',
        model: 'clean_maintained',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Home is clean and well-maintained"
            textareaLabel="Please explain"
            model="general_conditions.clean_maintained"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Evidence of recovery taking place',
        model: 'recovery_evidence',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Evidence of recovery taking place"
            textareaLabel="Please explain"
            model="general_conditions.recovery_evidence"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Works with those with felonies',
        model: 'accepts_felonies',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Works with those with felonies"
            textareaLabel="Please explain"
            model="general_conditions.accepts_felonies"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Works with prison re-entry',
        model: 'prison_reentry',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Works with prison re-entry"
            textareaLabel="Please explain"
            model="general_conditions.prison_reentry"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'COVID/Variant policies in place',
        model: 'covid_policies',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="COVID/Variant policies in place"
            textareaLabel="Please explain"
            model="general_conditions.covid_policies"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Accept those with OUD & Stimulant Use; Cocaine, Meth, Etc',
        model: 'accepts_substance_use',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Accept those with OUD & Stimulant Use; Cocaine, Meth, Etc"
            textareaLabel="Please explain"
            model="general_conditions.accepts_substance_use"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'At least 2 Identified Exits or Maps',
        model: 'identified_exits',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="At least 2 Identified Exits or Maps"
            textareaLabel="Please explain"
            model="general_conditions.identified_exits"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: '50 sq. ft per resident in room',
        model: 'room_space',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="50 sq. ft per resident in room"
            textareaLabel="Please explain"
            model="general_conditions.room_space"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Form of egress present',
        model: 'egress_present',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Form of egress present"
            textareaLabel="Please explain"
            model="general_conditions.egress_present"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Additional Notes',
    model: 'additional_notes',
    fields: [
      {
        label: 'Notes',
        model: 'notes',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Notes"
            model="additional_notes.notes"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Corrections Needs',
        model: 'corrections',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Corrections Needs"
            model="additional_notes.corrections"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Any infractions endanger resident health and safety',
        model: 'safety_infractions',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Any infractions endanger resident health and safety"
            textareaLabel="Please explain"
            model="additional_notes.safety_infractions"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Site Review Results',
        model: 'review_results',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Site Review Results"
            model="additional_notes.review_results"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Operator Signatures & Dates',
    model: 'operator',
    fields: [
      {
        label: 'Operator Signature',
        model: 'operator_signature',
        component: ({ isRequired }) => (
          <SignatureInput
            label="Operator Signature"
            model="operator.operator_signature"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Operator Name',
        model: 'operator_name',
        component: ({ isRequired }) => (
          <Input
            label="Operator Name"
            model="operator.operator_name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Operator Title',
        model: 'operator_title',
        component: ({ isRequired }) => (
          <Input
            label="Operator Title"
            model="operator.operator_title"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Date',
        model: 'operator_date',
        component: ({ isRequired }) => (
          <DateInput
            label="Date"
            model="operator.operator_date"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Time In',
        model: 'time_in',
        component: ({ isRequired }) => (
          <Input
            label="Time In"
            model="operator.time_in"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Time Out',
        model: 'time_out',
        component: ({ isRequired }) => (
          <Input
            label="Time Out"
            model="operator.time_out"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Inspector Signatures & Dates',
    model: 'inspector',
    fields: [
      {
        label: 'Inspector Name',
        model: 'inspector_name',
        component: ({ isRequired }) => (
          <Input
            label="Inspector Name"
            model="inspector.inspector_name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Inspector Signature',
        model: 'inspector_signature',
        component: ({ isRequired }) => (
          <SignatureInput
            label="Inspector Signature"
            model="inspector.inspector_signature"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Date',
        model: 'inspector_date',
        component: ({ isRequired }) => (
          <DateInput
            label="Date"
            model="inspector.inspector_date"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'NARR Physical Environment Domain E',
    model: 'narr_physical_environment_domain_e',
    fields: [
      {
        label: "The residence is comfortable, inviting, and meets residents' needs. Free from signs of pest issues.",
        model: 'residence_comfortable_and_inviting',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="The residence is comfortable, inviting, and meets residents' needs. Free from signs of pest issues."
            textareaLabel="Please explain"
            model="narr_physical_environment_domain_e.residence_comfortable_and_inviting"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 14"
          />
        ),
      },
      {
        label: 'Verification that the residence is in good repair, clean and well maintained.',
        model: 'residence_good_repair',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Verification that the residence is in good repair, clean and well maintained."
            textareaLabel="Please explain"
            model="narr_physical_environment_domain_e.residence_good_repair"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 14a"
          />
        ),
      },
      {
        label: 'Verification that furnishings are typical of those in single family homes as opposed to institutional settings.',
        model: 'furnishings_typical',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Verification that furnishings are typical of those in single family homes as opposed to institutional settings."
            textareaLabel="Please explain"
            model="narr_physical_environment_domain_e.furnishings_typical"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 14b"
          />
        ),
      },
      {
        label: 'Verification that entrances and exits are home-like vs. institutional or clinical.',
        model: 'entrances_exits_homelike',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Verification that entrances and exits are home-like vs. institutional or clinical."
            textareaLabel="Please explain"
            model="narr_physical_environment_domain_e.entrances_exits_homelike"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 14c"
          />
        ),
      },
      {
        label: 'Sleeping rooms provide residents with adequate space? 75sq.ft -one resident. 50sq. ft per person for 2 or more per room',
        model: 'sleeping_rooms_adequate_space',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Sleeping rooms provide residents with adequate space? 75sq.ft -one resident. 50sq. ft per person for 2 or more per room"
            textareaLabel="Please explain"
            model="narr_physical_environment_domain_e.sleeping_rooms_adequate_space"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 14d"
          />
        ),
      },
      {
        label: 'Bathrooms adequate to serve the maximum population? (Minimum 1 full bathroom per 6 residents) Clean and orderly.',
        model: 'bathrooms_adequate',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Bathrooms adequate to serve the maximum population? (Minimum 1 full bathroom per 6 residents) Clean and orderly."
            textareaLabel="Please explain"
            model="narr_physical_environment_domain_e.bathrooms_adequate"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 14e"
          />
        ),
      },
      {
        label: 'Each resident has adequate dresser and closet space to store their clothing and other personal items?',
        model: 'adequate_storage_space',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Each resident has adequate dresser and closet space to store their clothing and other personal items?"
            textareaLabel="Please explain"
            model="narr_physical_environment_domain_e.adequate_storage_space"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 14f"
          />
        ),
      },
      {
        label: 'Each resident has adequate and clean storage space for food; off the floor and separate from cleaning and hazardous',
        model: 'adequate_food_storage',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Each resident has adequate and clean storage space for food; off the floor and separate from cleaning and hazardous"
            textareaLabel="Please explain"
            model="narr_physical_environment_domain_e.adequate_food_storage"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 14g"
          />
        ),
      },
      {
        label: 'Laundry services are accessible to all residents.',
        model: 'laundry_services_accessible',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Laundry services are accessible to all residents."
            textareaLabel="Please explain"
            model="narr_physical_environment_domain_e.laundry_services_accessible"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 14h"
          />
        ),
      },
      {
        label: 'All appliances are in safe, clean, and working condition.',
        model: 'appliances_safe_clean_working',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="All appliances are in safe, clean, and working condition."
            textareaLabel="Please explain"
            model="narr_physical_environment_domain_e.appliances_safe_clean_working"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 14i"
          />
        ),
      },
      {
        label: 'The living space is conducive to building a community.',
        model: 'living_space_conducive_community',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="The living space is conducive to building a community."
            textareaLabel="Please explain"
            model="narr_physical_environment_domain_e.living_space_conducive_community"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 15"
          />
        ),
      },
      {
        label: 'Verification that a meeting space is large enough to accommodate all residents',
        model: 'meeting_space_large_enough',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Verification that a meeting space is large enough to accommodate all residents"
            textareaLabel="Please explain"
            model="narr_physical_environment_domain_e.meeting_space_large_enough"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 15a"
          />
        ),
      },
      {
        label: 'Verification that there is comfortable space for small group activities and socializing.',
        model: 'comfortable_space_small_groups',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Verification that there is comfortable space for small group activities and socializing."
            textareaLabel="Please explain"
            model="narr_physical_environment_domain_e.comfortable_space_small_groups"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 15b"
          />
        ),
      },
      {
        label: 'Verification that kitchen and dining area are large enough to accommodate all residents sharing meals together.',
        model: 'kitchen_dining_area_large_enough',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Verification that kitchen and dining area are large enough to accommodate all residents sharing meals together."
            textareaLabel="Please explain"
            model="narr_physical_environment_domain_e.kitchen_dining_area_large_enough"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 15c"
          />
        ),
      },
      {
        label: 'Verification that entertainment or recreational areas and/or furnishings promoting social environment are provided.',
        model: 'entertainment_areas_provided',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Verification that entertainment or recreational areas and/or furnishings promoting social environment are provided."
            textareaLabel="Please explain"
            model="narr_physical_environment_domain_e.entertainment_areas_provided"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 15d"
          />
        ),
      },
      {
        label: 'Is there adequate and clean food storage space provided? (Minimum 1 fridge per 6 residents).',
        model: 'adequate_food_storage_space',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Is there adequate and clean food storage space provided? (Minimum 1 fridge per 6 residents)."
            textareaLabel="Please explain"
            model="narr_physical_environment_domain_e.adequate_food_storage_space"
            withHover={false}
            validations={{ isRequired }}
          />
        ),
      },
      {
        label: 'Is there a thermometer in each refrigerator reading at or below 40 degrees and freezers reading 0-5 degrees?',
        model: 'thermometer_in_refrigerator',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Is there a thermometer in each refrigerator reading at or below 40 degrees and freezers reading 0-5 degrees?"
            textareaLabel="Please explain"
            model="narr_physical_environment_domain_e.thermometer_in_refrigerator"
            withHover={false}
            validations={{ isRequired }}
          />
        ),
      },
    ],
  },
  {
    title: 'NARR Promote a Safe and Healthy Environment',
    model: 'narr_promote_safe_healthy_environment',
    fields: [
      {
        label: 'Provide an alcohol and illicit drug free environment.',
        model: 'alcohol_drug_free_environment',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Provide an alcohol and illicit drug free environment."
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.alcohol_drug_free_environment"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 16"
          />
        ),
      },
      {
        label: "Evidence addressing residents' prescription and OTC med usage and storage consistent with residents Level and state law?",
        model: 'prescription_otc_med_usage',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Evidence addressing residents' prescription and OTC med usage and storage consistent with residents Level and state law?"
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.prescription_otc_med_usage"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 16d"
          />
        ),
      },
      {
        label: 'Are there records that document random drug testing of residents and staff?',
        model: 'random_drug_testing_records',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Are there records that document random drug testing of residents and staff?"
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.random_drug_testing_records"
            withHover={false}
            validations={{ isRequired }}
          />
        ),
      },
      {
        label: 'Is it evident that the Operator promotes home safety?',
        model: 'operator_promotes_home_safety',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Is it evident that the Operator promotes home safety?"
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.operator_promotes_home_safety"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 17"
          />
        ),
      },
      {
        label:
          'Operator attests that electrical, mechanical, structural component of property are functional & free of fire and safety hazards.',
        model: 'operator_attests_property_safety',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Operator attests that electrical, mechanical, structural component of property are functional & free of fire and safety hazards."
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.operator_attests_property_safety"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 17a"
          />
        ),
      },
      {
        label: 'Operator and site reviewer agree the home/residence meets Building, Health, and Safety codes.',
        model: 'home_meets_safety_codes',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Operator and site reviewer agree the home/residence meets Building, Health, and Safety codes."
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.home_meets_safety_codes"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 17b"
          />
        ),
      },
      {
        label: 'Do smoke detectors meet the minimum city/county requirements? (Minimum 1 per level)',
        model: 'smoke_detectors_meet_requirements',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Do smoke detectors meet the minimum city/county requirements? (Minimum 1 per level)"
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.smoke_detectors_meet_requirements"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 17c"
          />
        ),
      },
      {
        label: 'Do carbon monoxide detectors meet minimum city/county requirements? (1 per level minimum)',
        model: 'carbon_monoxide_detectors_meet_requirements',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Do carbon monoxide detectors meet minimum city/county requirements? (1 per level minimum)"
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.carbon_monoxide_detectors_meet_requirements"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – c.2"
          />
        ),
      },
      {
        label: 'Do fire extinguishers meet minimum city/county requirements? (1 per level minimum including kitchen & laundry)',
        model: 'fire_extinguishers_meet_requirements',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Do fire extinguishers meet minimum city/county requirements? (1 per level minimum including kitchen & laundry)"
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.fire_extinguishers_meet_requirements"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – c.3"
          />
        ),
      },
      {
        label: 'Regular documented inspections of smoke det., carbon mon., and fire extinguishers.',
        model: 'regular_documented_inspections',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Regular documented inspections of smoke det., carbon mon., and fire extinguishers."
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.regular_documented_inspections"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – c.4"
          />
        ),
      },
      {
        label: 'Monthly Fire, and other emergency evacuation drills take place and are documented.',
        model: 'monthly_emergency_drills',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Monthly Fire, and other emergency evacuation drills take place and are documented."
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.monthly_emergency_drills"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – c.5"
          />
        ),
      },
      {
        label: 'Are there appropriate approved safety disposal containers for smoking materials separate from trash?',
        model: 'safety_disposal_containers',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Are there appropriate approved safety disposal containers for smoking materials separate from trash?"
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.safety_disposal_containers"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 18a"
          />
        ),
      },
      {
        label: 'Procedure regarding exposure to bodily fluids and contagious disease. Adequate First Aid kit for multiple injuries.',
        model: 'bodily_fluids_procedure',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Procedure regarding exposure to bodily fluids and contagious disease. Adequate First Aid kit for multiple injuries."
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.bodily_fluids_procedure"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 18b"
          />
        ),
      },
      {
        label: 'Verification that residence address, emergency numbers, and procedures are posted.',
        model: 'emergency_info_posted',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Verification that residence address, emergency numbers, and procedures are posted."
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.emergency_info_posted"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 19a"
          />
        ),
      },
      {
        label: 'Verification of posted procedure for overdose and other emergency responses, (CPR, First aid, Fire, Tornado, etc.)',
        model: 'emergency_procedures_posted',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Verification of posted procedure for overdose and other emergency responses, (CPR, First aid, Fire, Tornado, etc.)"
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.emergency_procedures_posted"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – a.2"
          />
        ),
      },
      {
        label: 'Documentation that emergency contact information is collected from residents',
        model: 'emergency_contact_info_collected',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Documentation that emergency contact information is collected from residents"
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.emergency_contact_info_collected"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – b"
          />
        ),
      },
      {
        label: 'Documentation that residents are oriented to emergency procedures, (Narcan, CPR, Tornado, Fire, First aid, etc.).',
        model: 'residents_oriented_to_emergency_procedures',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Documentation that residents are oriented to emergency procedures, (Narcan, CPR, Tornado, Fire, First aid, etc.)."
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.residents_oriented_to_emergency_procedures"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – c"
          />
        ),
      },
      {
        label:
          'Verification that Naloxone is accessible at each location, and appropriate individuals are knowledgeable and trained in its use.',
        model: 'naloxone_accessible',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Verification that Naloxone is accessible at each location, and appropriate individuals are knowledgeable and trained in its use."
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.naloxone_accessible"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – d"
          />
        ),
      },
      {
        label: 'Are there at least two properly identified exits and evacuation plans posted.',
        model: 'two_exits_and_evacuation_plans',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Are there at least two properly identified exits and evacuation plans posted."
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.two_exits_and_evacuation_plans"
            withHover={false}
            validations={{ isRequired }}
          />
        ),
      },
      {
        label: 'Is the property address prominently posted in front, and back, if alley is present?',
        model: 'property_address_posted',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Is the property address prominently posted in front, and back, if alley is present?"
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.property_address_posted"
            withHover={false}
            validations={{ isRequired }}
          />
        ),
      },
      {
        label:
          'Are there any overloaded electric outlets or extension cords that could be considered a fire hazard? (Use surge protectors)',
        model: 'no_overloaded_outlets',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Are there any overloaded electric outlets or extension cords that could be considered a fire hazard? (Use surge protectors)"
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.no_overloaded_outlets"
            withHover={false}
            validations={{ isRequired }}
          />
        ),
      },
      {
        label: 'Is the facility free of piles of newspapers, clothes or other stored materials create a fire hazard?',
        model: 'facility_free_of_fire_hazards',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Is the facility free of piles of newspapers, clothes or other stored materials create a fire hazard?"
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.facility_free_of_fire_hazards"
            withHover={false}
            validations={{ isRequired }}
          />
        ),
      },
      {
        label:
          'Is there an available, on-site or on-call staff person responsible for the safety of the residents 24 hours per day, seven days per week?',
        model: 'staff_available_24_7',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Is there an available, on-site or on-call staff person responsible for the safety of the residents 24 hours per day, seven days per week?"
            textareaLabel="Please explain"
            model="narr_promote_safe_healthy_environment.staff_available_24_7"
            withHover={false}
            validations={{ isRequired }}
          />
        ),
      },
    ],
  },
  {
    title: 'NARR Facilitate Active Recovery and Recovery Community Engagement',
    model: 'narr_facilitate_active_recovery',
    fields: [
      {
        label: 'Resource directories, written or electronic, are made available to residents.',
        model: 'resource_directories_available',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Resource directories, written or electronic, are made available to residents."
            textareaLabel="Please explain"
            model="narr_facilitate_active_recovery.resource_directories_available"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 22.A"
          />
        ),
      },
    ],
  },
  {
    title: 'NARR Create a culture of empowerment where residents engage in governance and leadership',
    model: 'narr_create_culture_of_empowerment',
    fields: [
      {
        label: 'Evidence that some rules are made by the residents that the residents (not the staff) implement.',
        model: 'residents_make_rules',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Evidence that some rules are made by the residents that the residents (not the staff) implement."
            textareaLabel="Please explain"
            model="narr_create_culture_of_empowerment.residents_make_rules"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 7.A"
          />
        ),
      },
    ],
  },
  {
    title: 'NARR Adhere to legal and ethical codes and use best business practices',
    model: 'narr_adhere_to_legal_and_ethical_codes',
    fields: [
      {
        label:
          'A policy and practice that provider has a code of ethics that is aligned with the NARR code of ethics. There is evidence that this document is read and signed by all those associated with the operation of the recovery residence, to include owners, operators, staff and volunteers. is aligned with the NARR code of ethics. There is evidence that this document is read and signed by all those associated with the operation of the recovery residence, to include owners, operators, staff and volunteers. is aligned with the NARR code of ethics. There is evidence that this document is read and signed by all those associated with the operation of the recovery residence, to include owners, operators, staff and volunteers.',
        model: 'code_of_ethics_aligned_with_narr',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="A policy and practice that provider has a code of ethics that is aligned with the NARR code of ethics. There is evidence that this document is read and signed by all those associated with the operation of the recovery residence, to include owners, operators, staff and volunteers. is aligned with the NARR code of ethics. There is evidence that this document is read and signed by all those associated with the operation of the recovery residence, to include owners, operators, staff and volunteers. is aligned with the NARR code of ethics. There is evidence that this document is read and signed by all those associated with the operation of the recovery residence, to include owners, operators, staff and volunteers."
            textareaLabel="Please explain"
            model="narr_adhere_to_legal_and_ethical_codes.code_of_ethics_aligned_with_narr"
            withHover={false}
            validations={{ isRequired }}
            description="NARR Standard 3.0 – 2.i"
          />
        ),
      },
    ],
  },
]
