import React from 'react'
import { motion } from 'framer-motion'
import clsx from 'clsx'

import { isDefined } from '@behavehealth/utils/functions'
import { PlainSwitch } from '@behavehealth/components/Forms/Switch'
import { useFormField } from '@behavehealth/components/Forms/hooks/useFormField'
import { usePermissions } from '@behavehealth/hooks/usePermissions'
import { useUpdate } from '@behavehealth/hooks/useNewAPI'
import { withFormContext } from '@behavehealth/components/Forms/context'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Button from '@behavehealth/components/Button'
import Card from '@behavehealth/components/Card'
import Flex from '@behavehealth/components/Flex'
import Form from '@behavehealth/components/Forms/Form'
import Glyph from '@behavehealth/components/Glyph'
import Icon from '@behavehealth/components/Icon'
import MiniRichTextEditor from '@behavehealth/components/Forms/MiniRichTextEditor'
import Page from '@behavehealth/components/Page'
import PageSection from '@behavehealth/components/PageSection/PageSection'
import Status from '@behavehealth/components/Status'
import TreeItem from '@behavehealth/components/TreeItem'

import { DATA as workflows } from './organization_tracks'

const STEPS = {
  organization_details: {
    label: 'Organization Details',
    isRequired: true,
  },
  certifications: {
    label: 'Certifications',
  },
  inspections: {
    label: 'Inspections',
  },
  studies: {
    label: 'Studies',
  },
  surveys: {
    label: 'Surveys',
  },
}

const WORKFLOWS = [
  {
    name: 'Pre-Approve',
    glyph: 'intake_application',
    steps: STEPS,
  },
  {
    name: 'Inspect',
    glyph: 'search',
    steps: STEPS,
  },
  {
    name: 'Approve',
    glyph: 'tick_circle',
    steps: STEPS,
  },
  {
    name: 'Re-Approve',
    glyph: 'reset',
    steps: STEPS,
  },
  {
    name: 'Deny',
    glyph: 'circle_error',
    steps: STEPS,
  },
  {
    name: 'Ban',
    glyph: 'decline',
    steps: STEPS,
  },
]

const animationProps = {
  type: 'spring',
  duration: 0.35,
  bounce: 0,
}

const OrganizationWorkflows = () => {
  return (
    <Page breakpoint={3} title="Organization Workflows" feature="organization_workflows">
      <div className="grid gap-3">
        {workflows?.map?.((workflow) => (
          <OrganizationWorkflow key={workflow.id} workflow={workflow} />
        ))}
      </div>
    </Page>
  )
}

const OrganizationWorkflow = ({ workflow }) => {
  const [isEditable, setIsEditable] = React.useState(false)

  const form = React.useRef()

  const { mutateAsync, isLoading: isSaving } = useUpdate({
    name: ['update_workflow'],
    url: `/organization_workflows/${workflow.id}`,
    invalidate: 'organization_workflows',
  })

  const handleSave = async () => {
    const data = form.current?.getFormValue?.()

    await mutateAsync(data)

    setIsEditable(false)
  }

  return (
    <Card className="!overflow-visible">
      {workflow?.organization_track && (
        <PageSection.Header
          after={
            workflow.organization_track?.id && (
              <Button
                label="Edit Track →"
                glyph="edit"
                size={100}
                link={`/settings/organization-tracks/${workflow.organization_track.id}`}
              />
            )
          }
          className="px-3 py-2 border-b border-0 border-solid border-divider !m-0 sticky top-[3.5rem] bg-white z-[10] rounded-md"
        >
          <div className="flex items-center flex-nowrap">
            <Icon icon="organization_tracks" size={16} className="mr-1.5" />
            <div className="text-text-muted opacity-80 text-[0.85rem] uppercase font-[700] tracking-[0.5px]">Organization Track:</div>
            <div className="text-1rem font-[700] ml-1">{workflow.organization_track?.name}</div>
            {workflow.organization_track?.is_default && <Status small label="Default" color="blue" className="ml-1.5" />}
          </div>
        </PageSection.Header>
      )}

      <Form getForm={form} isEditable={isEditable} initialModel={workflow} key={`updated-${workflow?.updated_at}`}>
        {WORKFLOWS.map((workflow) => (
          <TreeItem
            isOpen={isEditable}
            title={
              <>
                <Glyph glyph={workflow.glyph} size={16} className="mr-1.5" />
                <span>{workflow.name} Workflow</span>
              </>
            }
          >
            {Object.keys(workflow.steps).map((key) => {
              const stepConfig = workflow.steps[key]

              if (stepConfig.isMandatory) return null

              return <WorkflowStep key={key} step={key} stepConfig={stepConfig} model="options.accept" />
            })}
          </TreeItem>
        ))}
      </Form>

      <div className="px-3 py-2 border-t border-0 border-solid border-divider !m-0 sticky bottom-0 bg-white z-[10] rounded-md">
        <Flex gap="0.75rem">
          {isEditable ? (
            <>
              <Button
                label="Save Workflow"
                glyph="tick_circle"
                type="primary"
                color="green"
                size={200}
                display="inline-flex"
                className="min-w-[100px]"
                onClick={handleSave}
                isLoading={isSaving}
              />

              <Button
                label="Cancel"
                size={200}
                display="inline-flex"
                className="min-w-[100px]"
                onClick={() => setIsEditable(false)}
                isDisabled={isSaving}
              />
            </>
          ) : (
            <Button
              label="Edit Workflow"
              glyph="edit"
              type="primary"
              size={200}
              display="inline-flex"
              className="min-w-[100px]"
              onClick={() => setIsEditable(true)}
            />
          )}
        </Flex>
      </div>
    </Card>
  )
}

const RootWorkflowStep = (props: any) => {
  const { model, form, isEditable, step, stepConfig = {} } = props

  const { label, isRequired: isAlwaysRequired, isRequiredOptional, featureFlag, featureFlagV2 } = stepConfig

  const { allowed: hasFeatureFlag } = usePermissions({ featureFlag: featureFlag || featureFlagV2 })

  const visibilityModel = `${model}.${step}`
  const requiredModel = `${model}_required_steps.${step}`
  const instructionsModel = `${model}_instructions.${step}`

  const [visibility, setVisibility] = React.useState(true)
  const [required, setRequired] = React.useState(!!isAlwaysRequired)
  const [instructions, setInstructions] = React.useState('')

  const { formActions: visibilityActions } = useFormField({
    model: visibilityModel,
    form,
    afterReset: () => {
      let initialVisibility = form?.getInitialInputFieldValue(visibilityModel)

      setVisibility(!!initialVisibility)
    },
  })

  const { formActions: requiredActions } = useFormField({
    model: requiredModel,
    form,
    afterReset: () => {
      let initialRequired = form?.getInitialInputFieldValue(requiredModel)

      setRequired(!!initialRequired)
    },
  })

  const { formActions: instructionsActions } = useFormField({
    model: instructionsModel,
    form,
    afterReset: () => {
      let initialInstructions = form?.getInitialInputFieldValue(instructionsModel)

      setInstructions(initialInstructions)
    },
  })

  // ON MOUNT
  React.useEffect(() => {
    let initialVisibility = form?.getInitialInputFieldValue(visibilityModel)
    let initialRequired = form?.getInitialInputFieldValue(requiredModel)
    let initialInstructions = form?.getInitialInputFieldValue(instructionsModel)

    if (isDefined(initialVisibility)) {
      setVisibility(!!initialVisibility)
    }

    if (isDefined(initialRequired)) {
      setRequired(!!initialRequired)
    }

    if (isDefined(initialInstructions)) {
      setInstructions(initialInstructions)
    }
  }, [])

  // UPDATE FORM
  React.useEffect(() => {
    visibilityActions.setValue(visibility)
  }, [visibility])

  React.useEffect(() => {
    requiredActions.setValue(required)
  }, [required])

  React.useEffect(() => {
    instructionsActions.setValue(instructions)
  }, [instructions])

  const showInstructions = isEditable ? visibility : visibility && instructions

  if (!hasFeatureFlag) return null

  return (
    <div className="max-w-[520px] border-b border-0 border-solid border-divider">
      <div className="flex items-center py-3">
        <h4 className={clsx('text-[1.1rem] mr-auto', !visibility && 'opacity-50')}>{label}</h4>

        {isAlwaysRequired ? (
          <div className="flex items-center flex-nowrap">
            <Glyph glyph="check" size={16} />
            <div className="font-[500] text-[0.95rem] ml-1.5">Always Visible & Required</div>
          </div>
        ) : (
          <>
            {isEditable && !isRequiredOptional && (
              <div className="flex items-center flex-nowrap">
                <Glyph glyph="info" size={16} />
                <div className="font-[400] italic text-[0.95rem] ml-1.5">Required Option Coming Soon</div>
              </div>
            )}

            {isRequiredOptional && (
              <div className={clsx('flex items-center flex-nowrap', !visibility && 'hidden')}>
                {!isEditable && <Glyph glyph={required ? 'check' : 'cross'} size={16} />}

                {isEditable && (
                  <PlainSwitch
                    size={100}
                    isChecked={required && visibility}
                    onCheckedChange={(value) => {
                      setRequired(value && visibility)
                    }}
                    isDisabled={!visibility}
                  />
                )}

                <div className="font-[500] text-[0.95rem] ml-1.5">Required</div>
              </div>
            )}

            <div className="flex items-center flex-nowrap ml-4">
              {!isEditable && <Glyph glyph={visibility ? 'check' : 'cross'} size={16} />}
              {isEditable && <PlainSwitch size={100} isChecked={visibility} onCheckedChange={setVisibility} />}
              <div className="font-[500] text-[0.95rem] ml-1.5">Visible</div>
            </div>
          </>
        )}
      </div>

      {(isEditable || showInstructions) && (
        <motion.div
          initial={false}
          className={clsx(!showInstructions && 'overflow-hidden')}
          animate={isEditable && { height: showInstructions ? 'auto' : 0, opacity: showInstructions ? 1 : 0 }}
          transition={{
            height: animationProps,
            opacity: animationProps,
          }}
        >
          <TreeItem
            title={<span className="text-[0.95rem] text-text-muted">Instructions</span>}
            withHover={false}
            headerClassName="!pt-0 !pb-3"
          >
            <div className={clsx(isEditable ? 'mb-4' : 'mb-2')}>
              <MiniRichTextEditor
                isCompact
                withHover={false}
                actions={EDITOR_ACTIONS}
                value={instructions}
                onUpdate={({ value }: any) => {
                  setInstructions(value)
                }}
              />
            </div>
          </TreeItem>
        </motion.div>
      )}
    </div>
  )
}

const EDITOR_ACTIONS = ['inline', 'color']

const WorkflowStep = withFormContext(RootWorkflowStep)

export default withPageError(OrganizationWorkflows)
