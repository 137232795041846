import React from 'react'
import startCase from 'lodash/startCase'

import Status from '../Status'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'
import { useSettings } from '../../hooks'

const columns = (to: Function = () => {}, timezone: string, mainLinkAs) => [
  {
    width: 250,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink as={mainLinkAs} to={to && to(row.original.id)} label={value} avatar="" />,
  },
  {
    width: 130,
    accessor: 'dob',
    Header: 'Date of Birth',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
  },
  {
    width: 130,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => {
      if (value == 'active') return <Status color="green" label="Active" />
      else if (value == 'inactive') return <Status color="red" label="Inactive" />
      return <TableCell.NotApplicable />
    },
  },
  {
    width: 100,
    accessor: 'sex',
    Header: 'Sex',
    Cell: ({ value }: any) => <TableCell value={startCase(value)} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      {
        value: 'male',
        label: 'Male',
      },
      {
        value: 'female',
        label: 'Female',
      },
      {
        value: 'decline_to_respond',
        label: 'Decline To Respond',
      },
      {
        value: 'other',
        label: 'Other',
      },
    ],
  },
  {
    width: 130,
    accessor: 'role',
    Header: 'Role',
    Cell: ({ value }: any) => <TableCell value={startCase(value)} />,
  },
  {
    width: 200,
    accessor: 'npi',
    Header: 'NPI',
  },
  {
    width: 160,
    accessor: 'phone_no',
    Header: 'Phone',
    Cell: TableCell.Phone,
  },
  {
    width: 240,
    accessor: 'email',
    Header: 'Email',
    Cell: TableCell.Email,
  },
  {
    width: 210,
    accessor: 'preferred_contact_method',
    Header: 'Preferred Contact Method',
    Cell: ({ value }: any) => {
      if (!value) return <TableCell.NotApplicable />
      return <TableCell.GlyphValue glyph={value === 'any' ? null : value} value={startCase(value)} />
    },
  },
  {
    width: 180,
    accessor: 'is_shared_with_portal',
    Header: 'Client Portal Sharing',
    Cell: ({ value }: any) => {
      if (!value) return 'Not shared'
      return <TableCell.GlyphValue glyph="portal" value="Shared" />
    },
    Filter: TableFilter.Select,
    filter: 'boolean',
    filterOptions: [
      {
        value: true,
        label: 'Shared',
      },
      {
        value: false,
        label: 'Not shared',
      },
    ],
  },
  {
    width: 130,
    accessor: 'notes',
    Header: 'Notes',
    Cell: ({ value }: any) => <TableCell value={startCase(value)} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  hiddenColumns?: string[]
  isLoading: boolean
  localStorageKey: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const OrganizationContactsTable = (props: Props) => {
  const { to, data, isLoading, emptyActions, hiddenColumns, batchActionsConfig, localStorageKey, titleAfter, mainLinkAs } = props
  const { timezone } = useSettings()

  return (
    <Table
      title="Contacts"
      titleAfter={titleAfter}
      icon="contacts"
      data={data}
      columns={columns(to, timezone, mainLinkAs)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No contacts added yet"
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
    />
  )
}

OrganizationContactsTable.defaultProps = {
  localStorageKey: 'organizations_contacts',
}

export default OrganizationContactsTable
