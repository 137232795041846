import React from 'react'
import { darken } from 'polished'
import { DateTime } from 'luxon'
import { useRouteMatch } from 'react-router-dom'

import { COLORS } from '../../../theme'
import { useDataTable } from '../../DataTable/useDataTable'

import Card from '../../Card'
import Tabs from '../../Tabs'
import Tooltip from '../../Tooltip'
import TransactionStats from './TransactionStats'

import { AutoRepeatingChargesDataTable } from '../../../constructs/Financials/AutoRepeatingChargesDataTable'
import { ChargesDataTable } from '../../../constructs/Financials/ChargesDataTable'
import { CreditsDataTable } from '../../../constructs/Financials/CreditsDataTable'
import { PaymentsDataTable } from '../../../constructs/Financials/PaymentsDataTable'
import { RefundsDataTable } from '../../../constructs/Financials/RefundsDataTable'
import { TransactionsDataTable } from '../../../constructs/Financials/TransactionsDataTable'
import { WriteOffsDataTable } from '../../../constructs/Financials/WriteOffsDataTable'

const Transactions = (props: any) => {
  const { hiddenColumns, isSelectable, name, showRepeatingCharges, showReportLinks, url, useStats } = props

  const match = useRouteMatch()

  const [transactionsCategory, setTransactionsCategory] = React.useState('charge,payment,credit,refund,write_off,fee')

  const tableProps = useDataTable({
    name: [name, transactionsCategory].flat(),
    endpoint: url,
    params: { category: transactionsCategory },
    staleTime: 0,
    keepPreviousData: false,
    localStorageKey: `transactions_${transactionsCategory}_v1`,
  })

  const estTime = DateTime.local().setZone('America/New_York')
  const leftTime = estTime.set({ hour: 8, minute: 0 }).toLocal().toFormat('ha')
  const rightTime = estTime.set({ hour: 10, minute: 0 }).toLocal().toFormat('ha')
  const offsetName = DateTime.local().toFormat('ZZZZZ')

  return (
    <>
      {useStats && <TransactionStats showRepeatingCharges={showRepeatingCharges} name={name} url={url} />}

      <Card testKey="transaction_table" className="!mt-4">
        <Tabs defaultTab="all_transactions">
          <Tabs.List caps className="!pl-4">
            <Tabs.Item
              label="All Transactions"
              name="all_transactions"
              onSelected={() => setTransactionsCategory('charge,payment,credit,refund,write_off')}
            />

            <Tabs.Divider />

            <Tabs.Item label="Charges" name="charges" onSelected={() => setTransactionsCategory('charge')} />
            <Tabs.Item label="Payments" name="payments" onSelected={() => setTransactionsCategory('payment')} />
            <Tabs.Item label="Credits" name="credits" onSelected={() => setTransactionsCategory('credit')} />
            <Tabs.Item label="Refunds" name="refunds" onSelected={() => setTransactionsCategory('refund')} />
            <Tabs.Item label="Write Off's" name="write-offs" onSelected={() => setTransactionsCategory('write_off')} />

            {showRepeatingCharges && (
              <>
                <Tabs.Divider />
                <Tabs.Item
                  label="Auto-Repeating Charges"
                  name="auto_repeating_charges"
                  onSelected={() => setTransactionsCategory('recurring')}
                />
              </>
            )}

            <Tooltip
              color={darken(0.05, COLORS.orange)}
              glyph="question"
              position="top"
              content={
                <span>
                  <i>Future Planned Charges</i> and <i>Auto-Repeating Charges</i> get processed daily between <b>{leftTime}</b> and{' '}
                  <b>{rightTime}</b>, {offsetName}
                </span>
              }
              css={{ marginLeft: 'auto', marginRight: '0.85rem' }}
            />
          </Tabs.List>

          <Tabs.Panels>
            <Tabs.Panel name="all_transactions">
              <TransactionsDataTable
                {...tableProps}
                key={transactionsCategory}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                testKey="all_transactions_table"
                to={({ id, category }: any) => ({
                  pathname: `${match?.url}/${category}/${id}`,
                  parent: match,
                })}
              />
            </Tabs.Panel>

            <Tabs.Panel name="charges">
              <ChargesDataTable
                {...tableProps}
                key={transactionsCategory}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                testKey="charges_table"
                to={({ id }: any) => ({
                  pathname: `${match?.url}/charges/${id}`,
                  parent: match,
                })}
                headerLinksConfig={showReportLinks ? [{ type: 'report', to: '/reports/charges' }] : undefined}
              />
            </Tabs.Panel>

            <Tabs.Panel name="payments">
              <PaymentsDataTable
                {...tableProps}
                key={transactionsCategory}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                testKey="payments_table"
                to={({ id }: any) => ({
                  pathname: `${match?.url}/payments/${id}`,
                  parent: match,
                })}
                headerLinksConfig={showReportLinks ? [{ type: 'report', to: '/reports/payments' }] : undefined}
              />
            </Tabs.Panel>

            <Tabs.Panel name="credits">
              <CreditsDataTable
                {...tableProps}
                key={transactionsCategory}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                testKey="credits_table"
                to={({ id }: any) => ({
                  pathname: `${match?.url}/credits/${id}`,
                  parent: match,
                })}
                headerLinksConfig={showReportLinks ? [{ type: 'report', to: '/reports/credits' }] : undefined}
              />
            </Tabs.Panel>

            <Tabs.Panel name="refunds">
              <RefundsDataTable
                {...tableProps}
                key={transactionsCategory}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                testKey="refunds_table"
                to={({ id }: any) => ({
                  pathname: `${match?.url}/refunds/${id}`,
                  parent: match,
                })}
                headerLinksConfig={showReportLinks ? [{ type: 'report', to: '/reports/refunds' }] : undefined}
              />
            </Tabs.Panel>

            <Tabs.Panel name="write-offs">
              <WriteOffsDataTable
                {...tableProps}
                key={transactionsCategory}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                testKey="write_offs_table"
                to={({ id }: any) => ({
                  pathname: `${match?.url}/write-offs/${id}`,
                  parent: match,
                })}
                headerLinksConfig={showReportLinks ? [{ type: 'report', to: '/reports/write-offs' }] : undefined}
              />
            </Tabs.Panel>

            <Tabs.Panel name="auto_repeating_charges">
              <AutoRepeatingChargesDataTable
                {...tableProps}
                key={transactionsCategory}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                testKey="auto_repeating_charges_table"
                to={({ id }: any) => ({
                  pathname: `${match?.url}/auto-repeating-charges/${id}`,
                  parent: match,
                })}
              />
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      </Card>
    </>
  )
}

Transactions.defaultProps = {
  name: 'financial_transactions',
  useStats: true,
  isSelectable: true,
  showRepeatingCharges: true,
}

export default Transactions
