import ICONS from './icons'
import { Feature } from '../../declarations/modules/Feature'

const FEATURES: any = {
  default: {
    icon: ICONS.sober_living_app,
    title: 'ERROR! CHECK FEATURES.JS',
    tooltip: 'ERROR! CHECK FEATURES.JS',
  },
  behave: {
    icon: ICONS.behave_health,
    title: 'Behave',
  },
  access_lists: {
    icon: ICONS.permissions,
    title: 'Staff Access',
  },
  attendance: {
    icon: ICONS.community,
    title: 'Attendance',
  },
  ip_restrictions: {
    icon: ICONS.permissions,
    title: 'IP Restrictions',
  },
  staff_ip_restrictions: {
    icon: ICONS.permissions,
    title: 'Staff IP Restrictions',
  },
  active_plans: {
    icon: ICONS.certificate,
    title: 'Active Plans',
    tooltip: 'Active Plans',
  },
  alumni: {
    icon: ICONS.alumni_relations,
    title: 'Past Clients',
    tooltip: 'Past Clients',
  },
  admissions: {
    icon: ICONS.admissions,
    title: 'Admissions',
    tooltip: 'Manage Admissions 2',
  },
  address_book: {
    icon: ICONS.gps_check_in_out,
    title: 'Address Book',
    tooltip: 'Manage Payer Address Book',
  },
  phone_book: {
    icon: ICONS.phone,
    title: 'Phone Book',
    tooltip: 'Manage Payer Phone Book',
  },
  agreements: {
    icon: ICONS.legal_agreement_alt,
    title: 'Agreements',
  },
  client_agreements: {
    icon: ICONS.legal_agreement_alt,
    title: 'Client Agreements',
  },
  staff_agreements: {
    icon: ICONS.legal_agreement_alt,
    title: 'Staff Agreements',
  },
  organization_agreements: {
    icon: ICONS.legal_agreement_alt,
    title: 'Organization Agreements',
  },
  applications: {
    icon: ICONS.checklist,
    title: 'Applications',
    tooltip: 'Manage Applications',
  },
  custom_application_form: {
    icon: ICONS.checklist,
    title: 'Applications',
  },
  applicants: {
    icon: ICONS.applications,
    title: 'Applicants',
    tooltip: 'View all applicants',
  },
  allergies: {
    icon: ICONS.treatment_data,
    title: 'Allergies',
  },
  authorizations: {
    icon: ICONS.authorizations,
    title: 'Authorizations',
    tooltip: 'New client authorizations',
  },
  insurance_authorizations: {
    icon: ICONS.authorizations,
    title: 'Insurance Authorizations',
    tooltip: 'Insurance Authorizations',
  },
  bed_management: {
    icon: ICONS.bed_management,
    title: 'Bed Management',
    tooltip: 'Bed Management',
  },
  billed_by_behave: {
    icon: ICONS.behave_health,
    title: 'Billed by Behave',
    tooltip: 'Billed by BEHAVE | HEALTH',
  },
  billed_clients: {
    icon: ICONS.financials,
    title: 'Billed Clients',
    tooltip: '',
  },
  bridge: {
    icon: ICONS.bridge,
    title: 'Bridge',
    tooltip: 'Manage your BEHAVE | BRIDGE profile',
  },
  calendar: {
    icon: ICONS.schedule,
    title: 'Calendar',
    tooltip: 'BEHAVE | HEALTH Calendar',
  },
  calendar_templates: {
    icon: ICONS.schedule,
    title: 'Calendar Templates',
  },
  client_cash: {
    icon: ICONS.online_payment_alt,
    title: 'Money Account',
    tooltip: 'BEHAVE | HEALTH Money Account',
  },
  clients: {
    icon: ICONS.clients,
    title: 'Current Clients',
    tooltip: 'View all current clients',
  },
  clinical_assessments: {
    icon: ICONS.clinical_assessments,
    title: 'Clinical Assessments',
    tooltip: 'BEHAVE | HEALTH Clinical Assessments',
  },
  clinical_notes: {
    icon: ICONS.clinical_notes,
    title: 'Clinical Notes',
    tooltip: 'BEHAVE | HEALTH Clinical Notes',
  },
  clinical_measurements: {
    icon: ICONS.patient_progress_measure,
    title: 'Clinical Measures',
    tooltip: 'BEHAVE | HEALTH Clinical Measures',
  },
  clinical_measures: {
    icon: ICONS.patient_progress_measure,
    title: 'Clinical Measures',
  },
  progress_notes: {
    icon: ICONS.progress_reviews,
    title: 'Progress Notes',
  },
  communications: {
    icon: ICONS.communications,
    title: 'Communication Logs',
    tooltip: 'BEHAVE | HEALTH Communications',
  },
  company_settings: {
    icon: ICONS.organizations_providers,
    title: 'Company Settings',
    tooltip: 'Company Settings',
  },
  connections: {
    icon: ICONS.connections,
    title: 'Care Team',
    tooltip: 'BEHAVE | HEALTH Care Team',
  },
  care_team: {
    icon: ICONS.connections,
    title: 'Care Team',
  },
  contacts: {
    icon: ICONS.contacts,
    title: 'Contacts',
    tooltip: 'View all your contacts',
  },
  clients_chat: {
    icon: ICONS.clients_chat,
    title: 'Clients Chat',
    tooltip: 'Clients Chat',
  },
  staff_chat: {
    icon: ICONS.company_chat,
    title: 'Staff Chat',
    tooltip: 'Staff Chat',
  },
  company_chat: {
    icon: ICONS.company_chat,
    title: 'Staff Chat',
    tooltip: 'Staff Chat',
  },
  custom_notes: {
    icon: ICONS.clinical_assessments,
    title: 'Custom Notes',
  },
  client_custom_notes: {
    icon: ICONS.clinical_assessments,
    title: 'Client Custom Notes',
  },
  staff_custom_notes: {
    icon: ICONS.clinical_assessments,
    title: 'Staff Custom Notes',
  },
  program_custom_notes: {
    icon: ICONS.clinical_assessments,
    title: 'Program Custom Notes',
  },
  office_custom_notes: {
    icon: ICONS.clinical_assessments,
    title: 'Office Custom Notes',
  },
  property_custom_notes: {
    icon: ICONS.clinical_assessments,
    title: 'Location Custom Notes',
  },
  organization_custom_notes: {
    icon: ICONS.clinical_assessments,
    title: 'Organization Custom Notes',
  },
  custom_note_templates: {
    icon: ICONS.clinical_assessments,
    title: 'Custom Note Templates',
  },
  onboarding_checklist: {
    icon: ICONS.startup,
    title: 'Your Onboarding Checklist',
    tooltip: 'Onboarding Checklist',
  },
  referrals: {
    icon: ICONS.referrals,
    title: 'Referrals',
    tooltip: 'Referrals',
  },
  product_tour: {
    icon: ICONS.startup,
    title: 'Product Tour',
    tooltip: 'Product Tour',
  },
  agreement_templates: {
    icon: ICONS.legal_agreement_alt,
    title: 'Agreement Templates',
    tooltip: 'Agreement Templates',
  },
  client_agreement_templates: {
    icon: ICONS.legal_agreement_alt,
    title: 'Client Agreement Templates',
  },
  staff_agreement_templates: {
    icon: ICONS.legal_agreement_alt,
    title: 'Staff Agreement Templates',
  },
  organization_agreement_templates: {
    icon: ICONS.legal_agreement_alt,
    title: 'Organization Agreement Templates',
  },
  console: {
    icon: ICONS.optimization,
    title: 'Console',
    tooltip: 'Overview of your account',
  },
  dashboard: {
    icon: ICONS.dashboard,
    title: 'Dashboard',
    tooltip: 'Overview of your account',
  },
  diagnoses: {
    icon: ICONS.diagnosis,
    title: 'Diagnoses',
    tooltip: 'Diagnoses',
  },
  discharges: {
    icon: ICONS.discharge_forms,
    title: 'Discharge Summaries',
    tooltip: 'Discharge Summaries',
  },
  employees: {
    icon: ICONS.employees,
    title: 'Staff',
    tooltip: 'Staff',
  },
  user_invites: {
    icon: ICONS.communications,
    title: 'User Invites',
    tooltip: 'User Invites',
  },
  files: {
    icon: ICONS.files,
    title: 'Files',
    tooltip: 'Manage your files',
  },
  financial_hub: {
    icon: ICONS.insurance,
    title: 'Finance Hub',
  },
  payer_authorizations: {
    icon: ICONS.checklist,
    title: 'Authorizations',
    tooltip: 'Payer authorizations',
  },
  payer_customer_files: {
    icon: ICONS.files,
    title: 'Files',
    tooltip: 'Manage customer files',
  },
  payer_files: {
    icon: ICONS.files,
    title: 'Payer Files',
    tooltip: 'Manage payer files',
  },
  insurance_reports_files: {
    icon: ICONS.insurance,
    title: 'Reports & Files',
    tooltip: 'Revenue Cycle Management',
  },
  payer_faxes_and_voicemails: {
    icon: ICONS.files,
    title: 'Faxes & Voicemails',
    tooltip: 'Manage payer faxes and voicemails',
  },
  payer_benefits: {
    icon: ICONS.financials,
    title: 'Benefits',
    tooltip: 'Manage payer benefits',
  },
  payer_clients: {
    icon: ICONS.clients,
    title: 'Clients',
    tooltip: 'Manage payer clients',
  },
  payer_rules: {
    icon: ICONS.licence,
    title: 'Rules',
    tooltip: 'Manage payer rules',
  },
  financials: {
    icon: ICONS.financials,
    title: 'Financials',
    tooltip: 'BEHAVE | HEALTH Financials',
  },
  general_info: {
    icon: ICONS.general_info,
    title: 'General Info',
    tooltip: 'Your demographic information',
  },
  housing_notes: {
    icon: ICONS.housing_notes,
    title: 'Shift Notes',
    tooltip: 'BEHAVE | HEALTH Shift Notes',
  },
  shift_notes: {
    icon: ICONS.housing_notes,
    title: 'Shift Notes',
  },
  insurance: {
    icon: ICONS.insurance,
    title: 'Insurance',
    tooltip: 'BEHAVE | HEALTH Insurance',
  },
  insurance_policies: {
    icon: ICONS.sell,
    title: 'Insurance',
    tooltip: 'Insurance Policies',
  },
  intake_forms: {
    icon: ICONS.intake_forms,
    title: 'Intake Forms',
    tooltip: 'BEHAVE | HEALTH Intake Forms',
  },
  episodes_of_care: {
    icon: ICONS.intake_forms,
    title: 'Episodes of Care',
  },
  ledger: {
    icon: ICONS.financials,
    title: 'Ledger',
    tooltip: 'View all payments made and received',
  },
  financial_plans: {
    icon: ICONS.financials,
    title: 'Financials',
  },
  legal_agreement: {
    icon: ICONS.legal_agreement_alt,
    title: 'Legal Agreement',
    tooltip: 'Legal Agreement',
  },
  lists: {
    icon: ICONS.lists,
    title: 'Lists',
    tooltip: 'Lists help you organize clients and/or staff together and create events or tasks quickly with all participants',
  },
  location_checkin: {
    icon: ICONS.recovery_navigation,
    title: 'Location Check-In',
    tooltip: "View all location check-in's and check-out's",
    type: {
      event: {
        icon: ICONS.schedule,
      },
      gps: {
        icon: ICONS.recovery_navigation,
      },
      organization: {
        icon: ICONS.organizations,
      },
      property: {
        icon: ICONS.properties,
      },
    },
  },
  location_gps_check_in: {
    icon: ICONS.recovery_navigation,
    title: 'Location Check-In',
  },
  log_out: {
    icon: ICONS.log_out,
    title: 'Log Out',
    tooltip: 'Log in to your account',
  },
  login: {
    icon: ICONS.access,
    title: 'Log In',
    tooltip: 'Log in to your account',
  },
  medical_assessments: {
    icon: ICONS.medical_assessments,
    title: 'Medical Assessments',
    tooltip: 'BEHAVE | HEALTH Medical Assessments',
  },
  medications: {
    icon: ICONS.medication,
    title: 'Medications',
    tooltip: 'Your active medications list',
  },
  medication_logs: {
    icon: ICONS.med_pass,
    title: 'Medication Logs',
  },
  medication_incidents: {
    icon: ICONS.medication_incidents,
    title: 'Medication Incidents',
  },
  med_pass: {
    icon: ICONS.med_pass,
    title: 'Meds Pass',
  },
  nursing: {
    icon: ICONS.nursing,
    title: 'Nursing',
    tooltip: 'BEHAVE | HEALTH Nursing',
  },
  notifications: {
    icon: ICONS.care_level_4,
    title: 'Notifications',
  },
  offices: {
    icon: ICONS.offices,
    title: 'Offices',
    tooltip: 'Facility offices',
  },
  messages: {
    icon: ICONS.care_level_4,
    title: 'Message Board',
  },
  online_payments: {
    icon: ICONS.online_payment_alt,
    title: 'Online Payments',
    tooltip: 'Online Payments',
  },
  online_payments_collection_with_stripe: {
    icon: ICONS.online_payment_alt,
    title: 'Online Payments',
  },
  organizations: {
    icon: ICONS.organizations,
    title: 'Organizations',
    tooltip: 'Facility organizations',
  },
  payers: {
    icon: ICONS.insurance,
    title: 'Payers',
    tooltip: 'Payers',
  },
  insurance_era_reports: {
    icon: ICONS.insurance,
    title: 'Insurance ERA Reports',
    tooltip: 'Insurance ERA Reports',
  },
  insurance_payers: {
    icon: ICONS.bank_building,
    title: 'Payers',
    tooltip: 'Payers',
  },
  payment_methods: {
    icon: ICONS.financials,
    title: 'Payment Methods',
  },
  permissions: {
    icon: ICONS.permissions,
    title: 'Staff Permissions',
    tooltip: 'Permissions',
  },
  staff_permissions: {
    icon: ICONS.permissions,
    title: 'Staff Permissions',
    tooltip: 'Permissions',
  },
  client_portal_permissions: {
    icon: ICONS.permissions,
    title: 'Clients Permissions',
    tooltip: 'Permissions',
  },
  staff_clients_access: {
    icon: ICONS.permissions,
    title: 'Staff Clients Access',
  },
  physician_orders: {
    icon: ICONS.physician_orders,
    title: 'Physician Orders',
    tooltip: 'BEHAVE | HEALTH Physician Orders',
  },
  playground: {
    icon: ICONS.behave_health,
    title: 'Playground',
    tooltip: 'React Components Playground for development',
  },
  progress_reviews: {
    icon: ICONS.progress_reviews,
    title: 'Progress Reviews',
    tooltip: 'BEHAVE | HEALTH Progress Reviews',
  },
  programs: {
    icon: ICONS.checklist,
    title: 'Programs',
  },
  program_lists: {
    icon: ICONS.housing_shifts,
    title: 'Program Lists',
  },
  places: {
    icon: ICONS.gps_check_in_out,
    title: 'Places',
    tooltip: 'Places',
  },
  properties: {
    icon: ICONS.locations,
    title: 'Locations',
    tooltip: 'Facility locations',
  },
  rcm: {
    icon: ICONS.insurance,
    title: 'Reports & Files',
    tooltip: 'Revenue Cycle Management',
  },
  recovery_coaching: {
    icon: ICONS.recovery_coaching,
    title: 'Recovery Notes',
    tooltip: 'BEHAVE | HEALTH Recovery Notes',
  },
  recovery_tracker: {
    icon: ICONS.recovery_coaching,
    title: 'Recovery Tracker',
    tooltip: 'BEHAVE | HEALTH Recovery Tracker',
  },
  register: {
    icon: ICONS.behave_health,
    title: 'Sign Up Today',
    tooltip: '',
  },
  rooms: {
    icon: ICONS.rooms,
    title: 'Rooms',
    tooltip: 'Facility Rooms',
  },
  roster: {
    icon: ICONS.roster,
    title: 'Roster',
  },
  reports: {
    icon: ICONS.reports,
    title: 'Reports',
  },
  live_reports: {
    icon: ICONS.reports,
    title: 'Reports',
  },
  schedule: {
    icon: ICONS.schedule,
    title: 'Schedule',
  },
  services_book: {
    icon: ICONS.financials,
    title: 'Services Book',
  },
  service_episodes: {
    icon: ICONS.treatment_data,
    title: 'Service Episodes',
    tooltip: 'New client service episodes',
  },
  settings: {
    icon: ICONS.settings,
    title: 'Settings',
  },
  super_admin: {
    icon: ICONS.access,
    title: 'Super Admin',
    tooltip: '',
  },
  tasks: {
    icon: ICONS.tasks,
    title: 'To-Do',
    tooltip: 'View and manage your current tasks',
  },
  todos: {
    icon: ICONS.tasks,
    title: "To-Do's",
  },
  todo_templates: {
    icon: ICONS.tasks,
    title: 'To-Do Templates',
  },
  test_results: {
    icon: ICONS.test_results,
    title: 'Test Results',
    tooltip: 'Your latest test results',
  },
  timetable: {
    icon: ICONS.housing_shifts,
    title: 'Timetable',
  },
  treatment_episodes: {
    icon: ICONS.treatment_data,
    title: 'Treatment Episodes',
    tooltip: 'Client Treatment Episodes',
  },
  treatment_plan: {
    icon: ICONS.treatment_plans,
    title: 'Treatment Plan',
    tooltip: 'BEHAVE | HEALTH Treatment Plans',
  },
  treatment_plans: {
    icon: ICONS.treatment_plans,
    title: 'Treatment Plans',
    tooltip: 'BEHAVE | HEALTH Treatment Plans',
  },
  treatment_plan_templates: {
    icon: ICONS.treatment_plans,
    title: 'Treatment Plan Templates',
  },
  vob: {
    icon: ICONS.insurance,
    title: 'VOB',
    tooltip: 'BEHAVE | HEALTH VOB',
  },
  full_vobs: {
    icon: ICONS.insurance,
    title: 'VOBs',
  },
  your_settings: {
    icon: ICONS.add_patient,
    title: 'Your Settings',
    tooltip: 'Your Settings',
  },
  search: {
    icon: ICONS.search,
    title: 'Search Clients',
    tooltip: 'Search Clients',
  },
  outcome_measures: {
    icon: ICONS.outcome_measures,
    title: 'Outcome Measures',
    tooltip: 'Outcome Measures',
  },
  client_outcome_measures: {
    icon: ICONS.outcome_measures,
    title: 'Client Portal Outcome Measures',
    tooltip: 'Client Portal Outcome Measures',
  },
  staff_credentials: {
    icon: ICONS.staff_credentials,
    title: 'Staff Credentials',
    tooltip: 'Staff Credentials',
  },
  company_credentials: {
    icon: ICONS.staff_credentials,
    title: 'Company Credentials',
    tooltip: 'Company Credentials',
  },
  billing_rosters: {
    icon: ICONS.roster,
    title: 'Billing Roster',
    tooltip: 'Billing Roster',
  },
  templates: {
    icon: ICONS.templates,
    title: 'Templates',
    tooltip: 'Templates',
  },
  bookmarks_manager: {
    icon: ICONS.bookmarks,
    title: 'Bookmarks Manager',
    tooltip: 'Bookmarks Manager',
  },
  marketing: {
    icon: ICONS.community,
    title: 'Marketing',
  },
  insurance_codes: {
    icon: ICONS.insurance,
    title: 'Insurance Codes',
  },
  fee_schedules: {
    icon: ICONS.insurance,
    title: 'Fee Schedules',
  },
  account_notes: {
    icon: ICONS.management_department,
    title: 'Account Notes',
  },
  team_notes: {
    icon: ICONS.community,
    title: 'Team Notes',
  },
  internal_notes: {
    icon: ICONS.behave_health,
    title: 'Internal Notes',
  },
  activity_timeline: {
    icon: ICONS.lists,
    title: 'Activity Timeline',
  },
  widgets: {
    icon: ICONS.web_form,
    title: 'Embeds',
  },
  telehealth: {
    icon: ICONS.video_call,
    title: 'Telehealth',
  },
  permission_templates: {
    icon: ICONS.permissions,
    title: 'Permission Templates',
  },
  legal_agreement_templates: {
    icon: ICONS.legal_agreement,
    title: 'Legal Agreement Templates',
  },
  insurance_evobs: {
    icon: ICONS.lightning_bolt,
    title: 'Insurance eVOBs',
    tooltip: 'Insurance eVOBs',
  },
  insurance_vobs: {
    icon: ICONS.call_transfer,
    title: 'Insurance VOBs',
    tooltip: 'Insurance VOBs',
  },
  legal_agreements: {
    icon: ICONS.legal_agreement,
    title: 'Legal Agreements',
  },
  bed_dashboard: {
    icon: ICONS.bed_management,
    title: 'Bed Dashboard',
  },
  custom_pages: {
    icon: ICONS.lists,
    title: 'Custom Pages',
  },
  data_exports: {
    icon: ICONS.reports,
    title: 'Data Exports',
  },
  tags: {
    icon: ICONS.tags,
    title: 'Tags',
  },
  quick_text: {
    icon: ICONS.quick_text,
    title: 'Quick Text',
  },
  forms_builder: {
    icon: ICONS.web_form,
    title: 'Forms Builder',
  },
  forms_builder_pdf_export: {
    icon: ICONS.web_form,
    title: 'Forms Builder PDF Export',
  },
  form_submissions: {
    icon: ICONS.web_form,
    title: 'Form Submissions',
  },
  form_submissions_pdf_export: {
    icon: ICONS.web_form,
    title: 'Form Submissions PDF Export',
  },
  form_templates: {
    icon: ICONS.web_form,
    title: 'Form Templates',
  },
  tag_templates: {
    icon: ICONS.tags,
    title: 'Tag Templates',
  },
  occupancies: {
    icon: ICONS.occupancies,
    title: 'Bed Assignments',
  },
  clients_journey: {
    icon: ICONS.clients_journey,
    title: 'Clients Journey',
  },
  client_journey: {
    icon: ICONS.clients_journey,
    title: 'Client Journey',
  },
  staff_journey: {
    icon: ICONS.staff_journey,
    title: 'Staff Journey',
  },
  community: {
    icon: ICONS.community,
    title: 'Community',
  },
  partners: {
    icon: ICONS.partners,
    title: 'Partners',
  },
  smart_flags: {
    icon: ICONS.smart_flags,
    title: 'Smart Flags',
  },
  ai_actions: {
    icon: ICONS.ai_prompts,
    title: 'AI Actions',
  },
  accounting: {
    icon: ICONS.accounting,
    title: 'Accounting',
  },
  personal_inventory: {
    icon: ICONS.personal_inventory,
    title: 'Personal Inventory',
  },
  personal_journal: {
    icon: ICONS.personal_journal,
    title: 'Personal Journal',
  },
  gratitude_journal: {
    icon: ICONS.gratitude_journal,
    title: 'Gratitude Journal',
  },
  claims: {
    icon: ICONS.outbox,
    title: 'Claims',
  },
  posting: {
    icon: ICONS.deposit,
    title: 'Posting',
  },
  invoices: {
    icon: ICONS.online_card_payment,
    title: 'Invoices',
  },
  client_statements: {
    icon: ICONS.online_card_payment,
    title: 'Client Statements',
  },
  clearinghouse: {
    icon: ICONS.exchange,
    title: 'Invoices',
  },
  client_workflows: {
    icon: ICONS.client_workflows,
    title: 'Client Workflows',
  },
  client_workflows_message_templates: {
    icon: ICONS.client_workflows,
    title: 'Client Workflows Message Templates',
  },
  peer_notes: {
    icon: ICONS.peer_notes,
    title: 'Peer Notes',
  },
  daily_notes: {
    icon: ICONS.day_view,
    title: 'Daily Notes',
  },
  admin_hr_notes: {
    icon: ICONS.admin_hr_notes,
    title: 'Admin HR Notes',
  },
  short_links: {
    icon: ICONS.short_links,
    title: 'Short Links',
  },
  grievance_notes: {
    icon: ICONS.grievance_notes,
    title: 'Grievance Notes',
  },
  variables: {
    icon: ICONS.variables,
    title: 'Form Variables',
  },
  rounds: {
    icon: ICONS.rounds,
    title: 'Rounds',
  },
  safety_checks: {
    icon: ICONS.safety_checks,
    title: 'Safety Checks',
  },
  company_integrations: {
    icon: ICONS.company_integrations,
    title: 'Company Integrations',
  },
  assessments_builder: {
    icon: ICONS.assessments_builder,
    title: 'Assessments Builder',
  },
  discharges_builder: {
    icon: ICONS.discharge_forms,
    title: 'Discharges Builder',
  },
  measure_settings: {
    icon: ICONS.measure_settings,
    title: 'Measure Settings',
  },
  embeds: {
    icon: ICONS.embeds,
    title: 'Embeds',
  },
  client_tracks: {
    icon: ICONS.client_tracks,
    title: 'Client Tracks',
  },
  organization_tracks: {
    icon: ICONS.organization_tracks,
    title: 'Organization Tracks',
  },
  organization_workflows: {
    icon: ICONS.organization_workflows,
    title: 'Organization Workflows',
  },
  authority_forms: {
    icon: ICONS.authority_forms,
    title: 'Authority Forms',
  },
  lead_contact_forms: {
    icon: ICONS.lead_contact_forms,
    title: 'Lead Contact Forms',
  },
  certifications_inspections: {
    icon: ICONS.certifications_inspections,
    title: 'Certifications Inspections',
  },
  grievance_incident_forms: {
    icon: ICONS.grievance_incident_forms,
    title: 'Grievance Incident Forms',
  },
  studies_surveys: {
    icon: ICONS.studies_surveys,
    title: 'Studies Surveys',
  },
}

export interface FindFeature {
  (feature: keyof typeof FEATURES): Feature
}

const findFeature: FindFeature = (feature) => {
  return FEATURES[feature] || FEATURES.default
}

export { FEATURES, findFeature }
