import React from 'react'

import Button from '../../components/Button'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'

import Input from '../../components/Forms/Input'
import EmailInput from '../../components/Forms/EmailInput'
import PhoneInput from '../../components/Forms/PhoneInput'
import AddressSelectorInput from '../../components/Forms/AddressSelectorInput'

import { useUpdate } from '../../hooks/useNewAPI'

export const AuthorityOrganizationLocationOverlay = ({ location, onClose }: any) => {
  const form = React.useRef()
  const [isValid, setIsValid] = React.useState(false)

  const { mutateAsync: updateLocation, isLoading: isUpdating } = useUpdate({
    name: ['community-locations-update'],
    url: `/community/locations/${location?.id}`,
    invalidateKeys: ['locations'],
  })

  const onSave = async () => {
    const formValue = form.current.getFormValue()

    try {
      await updateLocation(formValue)

      onClose()
    } catch (error) {
      console.debug(error)
    }
  }

  return (
    <Overlay showBackdrop onClose={onClose}>
      <Overlay.Header title="Location" icon="locations" />

      <Overlay.Content>
        <Form isEditable getForm={form} onValidationUpdate={setIsValid} initialModel={location}>
          <Section>
            <FormSection maxWidth="100%">
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please add a name',
                  },
                }}
              />

              <EmailInput label="Email" model="email" />
              <PhoneInput label="Phone No" model="phone_no" />

              <AddressSelectorInput label="Address" model="address" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Save"
          glyph="check"
          type="primary"
          color="green"
          isLoading={isUpdating}
          onClick={onSave}
          isDisabled={!isValid}
          flex="100 1 auto"
        />
      </Overlay.Footer>
    </Overlay>
  )
}
