import React from 'react'
import size from 'lodash/size'
import { useRouteMatch, useParams } from 'react-router-dom'

import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Button, Page, Card, Grid } from '@behavehealth/components'
import LocationsTable from '@behavehealth/components/Tables/LocationsTable'

import withMarketing from '@behavehealth/hocs/withMarketing'
import { NavLink } from 'react-router-dom-v5-compat'

const pageConfig = {
  feature: 'properties',
  title: 'Locations',
}

type Props = {
  canCreate: boolean
}

export const Locations: React.FC<Props> = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { data, isLoading }: any = useGet({
    name: ['organization', resource_id, 'locations'],
    url: `/organizations/${resource_id}/houses`,
  })

  const { mutateAsync: deleteLocations }: any = useDelete({
    name: ['organization', resource_id, 'locations'],
    url: `/houses`,
    invalidate: ['locations'],
  })

  const isEmpty = size(data) === 0

  return (
    <Page actions={<Button as={NavLink} label="Add New Location" type="primary" glyph="add" link="new" />} {...pageConfig}>
      <Grid>
        <Card>
          <LocationsTable
            useAutoPageSize
            data={data}
            isLoading={isEmpty && isLoading}
            localStorageKey="organizations_locations"
            to={(id: any) => ({
              pathname: `/locations/${id}`,
              parent: match,
            })}
            batchActionsConfig={[
              {
                glyph: 'delete',
                color: 'red',
                label: (length: number) => `Remove (${length})`,
                permission: 'properties.actions.delete',
                action: async ({ ids }: any) => {
                  await deleteLocations(ids.join(','))
                },
              },
            ]}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(Locations, pageConfig))
