import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { getVersionSlug } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Card, Chotomate, HelpTagIframe, Page } from '@behavehealth/components'
import { NURSING_SLUGS } from '@behavehealth/utils/constants'

import { NursingDataTable } from '@behavehealth/constructs/Nursing/NursingDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { StaffDataFormsAlert } from '../components/StaffDataFormsAlert'

const pageConfig = {
  feature: 'nursing',
  help: <HelpTagIframe id="nursing" />,
  marketingID: 'nursing',
}

type Props = {
  canCreate: boolean
}

const Nursing: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const resourceID = match.params?.resource_id

  const tableProps = useDataTable({
    name: ['employee', resourceID, 'data_forms'],
    endpoint: `/employees/${resourceID}/data_forms`,
    params: { category: 'nursing' },
    updateDeleteEndpoint: '/data_forms',
    localStorageKey: 'employee_nursing_forms_v1',
  })

  const to = React.useMemo(
    () => (record: any) => {
      const { version, subcategory, id } = record

      const formattedVersion = !version || version === '1.0' ? '' : `-${getVersionSlug(version)}`

      return {
        pathname: `${match.url}/${NURSING_SLUGS[subcategory]}/${id}${formattedVersion}`,
        parent: match,
      }
    },
    [match.url],
  )

  return (
    <Page {...pageConfig}>
      <Chotomate name="nursing" ready={!tableProps.isLoading} />
      <div className="grid gap-4 grid-cols-[100%]">
        <StaffDataFormsAlert name="Nursing Forms" />

        <Card>
          <NursingDataTable
            {...tableProps}
            to={to}
            testKey="nursing_data_table"
            headerLinksConfig={[{ type: 'report', to: '/reports/nursing' }]}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'nursing.delete',
                action: async ({ ids }: any) => {
                  await tableProps.deleteRecords(ids.join(','))
                },
              },
            ]}
          />
        </Card>
      </div>
    </Page>
  )
}

export default withPageError(withMarketing(Nursing, pageConfig))
