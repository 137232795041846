import React from 'react'
import { keyframes } from '@emotion/react'
import { Manager, Reference } from 'react-popper'
import { transparentize } from 'polished'
import size from 'lodash/size'

import { persist } from 'zustand/middleware'
import create from 'zustand'

import { COLORS, SHADOW } from '../../theme'
import { mapToArray } from '../../utils/functions'
import { useGet, useUpdate } from '../../hooks/useNewAPI'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Checkbox from '../../components/Forms/Checkbox'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import Divider from '../../components/Divider'
import Glyph from '../../components/Glyph'
import Icon from '../../components/Icon'
import Loader from '../../components/Loader'
import Permission from '../../components/Permission'
import SmartPortal from '../../components/SmartPortal'
import State from '../../components/State'
import Status from '../../components/Status'

import { ORGANIZATION_TRACKS, ORGANIZATION_WORKFLOWS } from './temp'

const useConfirmSettings = create(
  persist(
    (set: any, get: any) => ({
      didDismiss: false,
      setDidDismiss: (value: boolean) => set({ didDismiss: value }),
    }),
    { name: 'bh.settings-organization-track-confirm' },
  ),
)

export const AuthorityOrganizationTrackSelector: React.FC<any> = (props) => {
  const { organizationId, activeTrack, isEditable = true, buttonLabel = 'Organization Track', buttonClassName } = props

  const [isOpen, setIsOpen] = React.useState(false)
  const [menuRef, setMenuRef] = React.useState(null)
  const [updatingId, setUpdatingId] = React.useState(null)
  const [showDialog, setShowDialog] = React.useState(false)
  const [dismissChecked, setDismissChecked] = React.useState(false)

  const didDismiss = useConfirmSettings((store) => store.didDismiss)
  const setDidDismiss = useConfirmSettings((store) => store.setDidDismiss)

  const data = ORGANIZATION_TRACKS
  const isLoading = false

  // const { data, isLoading }: any = useGet({
  //   name: ['organization_tracks'],
  //   url: `/organization_tracks`,
  //   options: { enabled: !!isEditable },
  // })

  const { mutateAsync: updateOrganization, isLoading: isUpdating }: any = useUpdate({
    name: ['organization', organizationId],
    url: `/organizations/${organizationId}`,
    invalidate: ['organizations'],
  })

  const isEmpty = size(data) === 0

  const toggle = (event: any) => {
    event.stopPropagation()

    setIsOpen((c) => !c)
  }

  const close = () => setIsOpen(false)

  React.useEffect(() => {
    if (!menuRef) return

    const handleOutsideClick = (event: any) => {
      if (menuRef?.contains?.(event.target)) return

      close()
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [menuRef])

  return (
    <>
      <ConfirmDialog
        portal="z120"
        message={null}
        setOpen={showDialog}
        onClose={() => setShowDialog(false)}
        yesLabel="Update Organization Track"
        onYes={() => {
          if (!updatingId) return

          updateOrganization({ organization_track_id: updatingId })
          setDidDismiss(!!dismissChecked)
        }}
        content={
          <div className="grid gap-4 text-text">
            <Alert contrast glyph="warning" type="warning" className="text-text">
              <b>Please note,</b> changing the Organization Track will update the following workflows:
            </Alert>

            <Card className="px-4 py-3 grid gap-3 leading-normal">
              {ORGANIZATION_WORKFLOWS.map((workflow) => (
                <div className="flex items-center flex-nowrap font-[500]">
                  <Glyph glyph={workflow.glyph} size={16} className="mr-1.5" />
                  <span>{workflow.name} Workflow</span>
                </div>
              ))}
            </Card>

            <Checkbox
              isCompact
              label="Don't show again"
              onUpdate={({ value }) => {
                setDismissChecked(value)
              }}
            />
          </div>
        }
      />

      <Manager>
        {isEditable && (
          <Permission
          // permission="organization_tracks.view"
          // featureFlagV2="organization_tracks"
          >
            <Reference>
              {({ ref }) => (
                <div data-test="organization_track_button" ref={ref} onClick={toggle} css={styles.trigger} className={buttonClassName}>
                  {isUpdating ? <Loader size={12} className="mr-1.5" /> : <Icon icon="organization_tracks" size={16} className="mr-1.5" />}

                  {buttonLabel && <span>{buttonLabel}: </span>}

                  {activeTrack ? (
                    <span className="ml-0.5 font-[700] text-text">{activeTrack?.name}</span>
                  ) : (
                    <span className="text-text-muted opacity-70">N/A</span>
                  )}

                  <Glyph glyph="triangle_down" size={10} className="ml-1" />
                </div>
              )}
            </Reference>
          </Permission>
        )}

        {isOpen && (
          <>
            <SmartPortal portal="tooltip" position="bottom">
              <div ref={setMenuRef} css={styles.menu}>
                <Card testKey="tags_selector" css={styles.card}>
                  {isEmpty || isLoading ? (
                    <State
                      title="Organization Tracks"
                      minHeight={150}
                      emptyDescription="No Organization Tracks have been created yet"
                      emptyActions={
                        <Button label="Manage Organization Tracks →" size={200} glyph="settings" link="/settings/organization-tracks" />
                      }
                      glyph="tag"
                      isEmpty={isEmpty}
                      isLoading={isLoading}
                    />
                  ) : (
                    <>
                      <div css={styles.content} className={isUpdating ? 'user-select-none opacity-70' : ''}>
                        <div className="p-2">
                          <Alert small glyph="info">
                            Customize this Organization's workflow with Organization Tracks
                          </Alert>
                        </div>

                        <div className="p-2 pt-0">
                          {mapToArray(data).map((track: any) => {
                            const isActive = !!activeTrack?.id && track.id === activeTrack.id

                            return (
                              <div
                                key={track.id}
                                css={styles.row}
                                className={isActive && !isUpdating ? 'is-active !bg-green-100' : ''}
                                onClick={(event) => {
                                  event.stopPropagation()
                                  event.nativeEvent.stopImmediatePropagation()

                                  setUpdatingId(track.id)

                                  if (didDismiss) {
                                    updateOrganization({ organization_track_id: track.id })
                                  } else {
                                    setShowDialog(true)
                                  }
                                }}
                              >
                                <div className="flex items-center flex-nowrap w-full font-[600]">
                                  <div>
                                    <div className="flex items-center flex-nowrap">
                                      <Icon icon="organization_tracks" size={16} className="mr-1.5" />
                                      <div>{track.name}</div>
                                      {track.is_default && <Status small label="Default" color="blue" className="ml-1.5" />}
                                    </div>

                                    {track.description && (
                                      <div className="whitespace-pre text-text-muted opacity-90 text-[0.9rem] pl-[20px] font-[400]">
                                        {track.description}
                                      </div>
                                    )}
                                  </div>

                                  {isActive && (
                                    <Glyph glyph="tick_circle" size={14} color={COLORS.green} className="ml-auto flex-[0_0_auto]" />
                                  )}

                                  {isUpdating && updatingId === track.id && <Loader size={14} className="ml-auto flex-[0_0_auto]" />}
                                </div>
                              </div>
                            )
                          })}
                        </div>

                        <Divider className="!my-0" />

                        <Button
                          label="Manage Organization Tracks →"
                          size={300}
                          type="link"
                          glyph="settings"
                          link="/settings/organization-tracks"
                        />
                      </div>
                    </>
                  )}
                </Card>
              </div>
            </SmartPortal>
          </>
        )}
      </Manager>
    </>
  )
}

const animation = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(0.98, 0.98, 0.98) translateY(-12px);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1) translateY(0);
  }
`

const styles = {
  trigger: {
    display: 'inline-flex',
    borderRadius: 100,
    border: `1px dashed ${transparentize(0.5, COLORS.textMuted)}`,
    padding: '0.1rem 0.5rem',
    height: '1.6rem',
    alignItems: 'center',
    background: COLORS.white,
    boxShadow: SHADOW(1),

    fontWeight: 600,
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    lineHeight: 'normal',
    cursor: 'pointer',

    '&:hover': {
      background: COLORS.hover,
    },

    svg: {
      marginRight: '0.25rem',
    },
  },

  tag: {
    position: 'relative',
    display: 'inline-flex',
    borderRadius: 100,
    border: `1px solid ${COLORS.divider}`,
    background: COLORS.white,
    boxShadow: SHADOW(1),
    minHeight: '1.5rem',
    paddingLeft: '0.3rem',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '0.8rem',
    lineHeight: 'normal',
    overflow: 'hidden',

    '& .close-button': {
      display: 'none',
      pointerEvents: 'none',

      borderLeft: `1px solid ${COLORS.divider}`,
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center',
      width: '1.75rem',
      alignSelf: 'stretch',

      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      background: COLORS.white,

      '&:hover': {
        svg: { fill: COLORS.red },
      },
    },

    '&:hover .close-button': {
      display: 'flex',
    },
  },

  tagName: {
    paddingRight: '0.4rem',
  },

  menu: {
    padding: '0.5rem',
    animation: `${animation} 100ms cubic-bezier(0.39, 0.575, 0.565, 1) forwards`,
  },

  card: {
    minWidth: '15rem',
    maxWidth: '20rem',
    maxHeight: 400,
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    overflow: 'auto',
  },

  content: {
    overflowY: 'auto',
  },

  groupHeader: {
    padding: '0.3rem 0.75rem',
    fontWeight: 600,
    fontSize: '0.85rem',
    textTransform: 'uppercase',
    lineHeight: 'normal',
    letterSpacing: 0.75,
    marginTop: '0.5rem',

    '&:first-of-type': {
      marginTop: '0.25rem',
    },
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.35rem 0.75rem',
    cursor: 'pointer',
    borderRadius: 5,

    '&:hover': {
      background: COLORS.hover,
    },

    '&.is-active': {
      fontWeight: 500,

      '&:hover .close-button svg': {
        transform: 'scale3d(1.25, 1.25, 1.25)',
        fill: COLORS.red,
      },
    },
  },

  glyph: {
    marginRight: '0.25rem',
  },

  search: {
    input: {
      borderRadius: 0,
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
      boxShadow: 'none !important',
      borderColor: 'var(--input-border-color) !important',
    },
  },
}
