import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'
import { produce } from 'immer'
import { useRouteMatch } from 'react-router-dom'
import compact from 'lodash/compact'
import size from 'lodash/size'

import { countWord } from '../../utils/functions'
import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks'
import { useUpdate } from '../../hooks/useNewAPI'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import Overlay from '../../components/Overlay'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'
import Tooltip from '../../components/Tooltip'

import { FormSubmissionClientPortalPermissionStatus, CLIENT_PORTAL_PERMISSION_STATUSES } from './FormSubmissionClientPortalPermissionStatus'
import { FormSubmissionShareOverlay } from './FormSubmissionShareOverlay'
import { FormSubmissionShareStatus } from './FormSubmissionShareStatus'
import { FormSubmissionStatus } from './FormSubmissionStatus'

import { DataTable } from '../../components/DataTable/DataTable'
import { LinkCell } from '../../components/DataTable/cells/LinkCell'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { processSigneesToSign } from './functions'

export const FormSubmissionsDataTable = (props: any) => {
  const match = props.useV6Router ? {} : useRouteMatch()
  const { tenant, isEHRApp } = useSettings()

  const { showClientPortalColumn = false } = props
  const [selected, setSelected] = React.useState(null)
  const [clientPortalOverlayOpen, setClientPortalOverlayOpen] = React.useState(false)

  const [shareOverlayData, setShareOverlayData]: any = React.useState(null)

  const showSignatureColumn = isEHRApp
  const showAddendumsColumn = isEHRApp

  const handleShareClick = (row: any) => {
    if (!row) return
    setShareOverlayData(row)
  }

  const columns = React.useMemo(
    () =>
      compact([
        {
          width: 260,
          id: 'name',
          model: 'name',
          title: 'Submission Name',
          formatValue: ({ value, data }: any) => (
            <MainCell
              as={props.useV6Router ? NavLink : undefined}
              to={props.useV6Router ? data.id : `${match.url}/${data.id}`}
              value={value}
            />
          ),
        },
        {
          width: 200,
          model: 'status',
          title: 'Status',
          formatValue: ({ value }: any) => <FormSubmissionStatus status={value} />,
        },
        {
          title: 'Tags',
          model: 'tags',
          type: 'tags',
          featureFlag: 'tags',
          editPermission: 'form_submissions.edit',
          disableSort: true,
        },
        {
          title: 'Assignee',
          model: 'assignee',
          type: 'profile',
        },
        showClientPortalColumn && {
          title: 'Client Portal Permission',
          model: 'portal_permissions_settings',
          disableSort: true,
          formatValue: ({ value, data }: any) => (
            <ClientPortalPermissionCell
              value={value}
              formSubmission={data}
              onEdit={() => {
                setSelected(data)
                setClientPortalOverlayOpen(true)
              }}
            />
          ),
        },
        showSignatureColumn && {
          title: 'Signatures',
          model: 'form_submission_signees',
          disableSort: true,
          formatValue: ({ value: signees, data }: any) => {
            if (size(signees) === 0) return null

            const signeesToSign = processSigneesToSign({ data, signees })

            const signeesCount = size(signees)
            const signeesToSignCount = size(signeesToSign)
            const hasSigneesToSign = signeesToSignCount > 0

            if (!hasSigneesToSign) return null

            return (
              <div className="flex items-center flex-[1_1_auto]">
                <div className="mr-auto flex-[1_1_auto]">
                  {signeesCount - signeesToSignCount}/{signeesCount} Signed
                </div>

                <Tooltip
                  content={
                    <div>
                      <div>The following signees are yet to sign this Form Submission before it can be signed-off:</div>
                      <div>
                        <b>{signeesToSign?.map((o) => o.reference?.name)?.join(', ')}</b>
                      </div>
                    </div>
                  }
                />
              </div>
            )
          },
        },
        showAddendumsColumn && {
          title: 'Addendums',
          model: 'addendums',
          disableSort: true,
          formatValue: ({ value, data }: any) => {
            const addendumsCount = size(value)

            if (!addendumsCount) return null

            return countWord('Addendums', addendumsCount)
          },
        },
        {
          width: 150,
          model: 'use_public_sharing',
          title: 'Share Status',
          formatValue: ({ value, data }: any) => {
            return (
              <Flex centerY flex="1 1 auto" justifyContent="space-between">
                <FormSubmissionShareStatus status={value === true ? 'public' : 'private'} />
                <Button hideLabel size={100} type="minimal" glyph="settings" onClick={() => handleShareClick?.(data)} />
              </Flex>
            )
          },
        },
        {
          title: 'Share Link',
          model: 'external_id',
          width: 180,
          formatValue: ({ data, value: externalID }: any) => {
            const isPublic = data.use_public_sharing
            const submissionURL = externalID && tenant && `${process.env.BH_APP_FORM_URL}/${tenant.subdomain}/submission/${externalID}`

            if (!isPublic || !submissionURL) return null

            return <LinkCell isExternal href={submissionURL} value="Open Public Link" />
          },
        },
        {
          title: 'Filed Under',
          model: 'reference',
          type: 'profile',
          disableSort: true,
        },
        {
          title: 'Date Submitted',
          model: 'created_at',
          type: 'date_time',
        },
        {
          title: 'Last Edited',
          model: 'updated_at',
          type: 'date_time',
        },
      ]),
    [!props.useV6Router && match, showClientPortalColumn],
  )

  const filtersConfig = React.useMemo(() => {
    return produce(FILTERS.form_submissions, (draft) => {
      if (showClientPortalColumn) {
        draft['portal_permissions_settings'] = {
          label: 'Client Portal Permission',
          type: 'multi_select',
          options: Object.keys(CLIENT_PORTAL_PERMISSION_STATUSES).map((key) => ({
            value: key,
            label: CLIENT_PORTAL_PERMISSION_STATUSES[key].label,
          })),
        }
      }
    })
  }, [showClientPortalColumn])

  return (
    <>
      <DataTable
        testKey="form_submissions_data_table"
        title="Form Submissions"
        icon="web_form"
        columns={columns}
        filtersConfig={filtersConfig}
        {...props}
      />

      <SummonOverlay
        isOpen={!!shareOverlayData}
        onClose={() => setShareOverlayData(null)}
        overlay={<FormSubmissionShareOverlay dataID={shareOverlayData?.id} data={shareOverlayData} />}
      />

      <SummonOverlay
        isOpen={clientPortalOverlayOpen}
        onClose={() => {
          setSelected(null)
          setClientPortalOverlayOpen(false)
        }}
        overlay={<ClientPortalPermissionOverlay formSubmission={selected} onClose={() => setClientPortalOverlayOpen(false)} />}
      />
    </>
  )
}

const ClientPortalPermissionOverlay = ({ onClose, formSubmission }: any) => {
  const form = React.useRef()

  const client = formSubmission?.reference

  const { mutateAsync, isLoading }: any = useUpdate({
    name: ['form_submissions', formSubmission?.id],
    url: `/form_submissions/${formSubmission?.id}`,
    invalidateKeys: ['form_submissions'],
  })

  const [formData, setFormData] = React.useState(null)

  const handleSave = async () => {
    if (!formData) return

    const saveData = produce(formData, (draft: any) => {
      if (draft.portal_permissions_settings === 'no_access') {
        draft.use_portal_permissions = false
      } else {
        draft.use_portal_permissions = true
      }
    })

    await mutateAsync(saveData)

    if (onClose) onClose()
  }

  if (!formSubmission || !client) return null

  const currentSetting = formSubmission?.portal_permissions_settings || 'no_access'
  const newSetting = formData?.portal_permissions_settings
  const didChange = newSetting && newSetting !== currentSetting

  return (
    <Overlay showBackdrop position="center" onClose={onClose}>
      <Overlay.Header glyph="portal" title="Edit Client Portal Permission" />

      <Overlay.Content>
        <Section>
          <Form getForm={form} onUpdate={setFormData} className="grid gap-4">
            {didChange && (
              <Alert small contrast glyph="info">
                <div>
                  {newSetting === 'no_access' && (
                    <>
                      Upon save, {client.name} will <b>not be able to view</b> this Form Submission in their Client Portal app
                    </>
                  )}

                  {newSetting === 'can_view' && (
                    <>
                      Upon save, {client.name} will be able to <b>view</b> this Form Submission in their Client Portal app, but not edit it
                    </>
                  )}

                  {newSetting === 'can_view_edit' && (
                    <>
                      Upon save, {client.name} will be able to <b>view and edit</b> this Form Submission in their Client Portal app
                    </>
                  )}
                </div>
              </Alert>
            )}

            {!didChange && (
              <Alert small contrast glyph="info">
                Please select a new setting to enable the save button
              </Alert>
            )}

            <RadioGroup
              label={`When Form Submissions are "Filed-Under" a Client:`}
              trueIcon="check"
              falseIcon="cross"
              falseStyle="faded"
              layout="vertical-dense"
              model="portal_permissions_settings"
            >
              <Radio
                label={`Client CANNOT view this Form Submission`}
                value="no_access"
                isDisabled={currentSetting === 'no_access'}
                description={currentSetting === 'no_access' && <Status small color="blue" label="Current Setting" />}
              />
              <Radio
                label={`Client can view this Form Submissions`}
                value="can_view"
                isDisabled={currentSetting === 'can_view'}
                description={currentSetting === 'can_view' && <Status small color="blue" label="Current Setting" />}
              />
              <Radio
                label={`Client can view, and edit this Form Submission`}
                value="can_view_edit"
                isDisabled={currentSetting === 'can_view_edit'}
                description={currentSetting === 'can_view_edit' && <Status small color="blue" label="Current Setting" />}
              />
            </RadioGroup>
          </Form>
        </Section>
      </Overlay.Content>

      {didChange && (
        <Overlay.Footer>
          <Button type="primary" glyph="check" onClick={handleSave} isLoading={isLoading} {...buttonConfig[newSetting]} />
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

const buttonConfig = {
  no_access: {
    label: 'Revoke Access',
    color: 'red',
    glyph: 'decline',
    type: 'default',
  },
  can_view: {
    label: 'Enable View Access',
    color: 'green',
    glyph: 'check',
  },
  can_view_edit: {
    label: 'Enable View & Edit Access',
    color: 'green',
    glyph: 'check',
  },
}

const ClientPortalPermissionCell = ({ value, onEdit }: any) => {
  return (
    <div className={editCellClasses}>
      <div className={editValueClasses}>
        <FormSubmissionClientPortalPermissionStatus status={value || 'no_access'} />
      </div>

      <Button
        hideLabel
        glyph="edit"
        size={100}
        type="minimal"
        className={editButtonClasses}
        permission="form_submissions.edit"
        onClick={onEdit}
      />
    </div>
  )
}

const editCellClasses = 'flex flex-nowrap items-center w-full'
const editValueClasses = 'flex-1 overflow-hidden whitespace-nowrap overflow-ellipsis'
const editButtonClasses = 'svg:mr-0'
