import React from 'react'
import { Link, Route } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { OrganizationApplicationFormOverlay } from '@behavehealth/constructs/AuthorityOrganizations/OrganizationApplicationFormOverlay'
import { OrganizationApplicationFormsDataTable } from '@behavehealth/constructs/AuthorityOrganizations/OrganizationApplicationFormsDataTable'
import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'

import Button from '@behavehealth/components/Button'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'

const RootOrganizationApplicationForms = () => {
  const tableProps = useDataTable({
    name: ['TODO'],
    endpoint: `/TODO`,
    localStorageKey: 'TODO_v1',
  })

  const record = useSelector((state: any) => state.me?.tenant)

  return (
    <>
      <Page
        title="Lead Contact Forms"
        feature="lead_contact_forms"
        actions={<Button as={Link} label="Add Lead Contact Form" glyph="add" type="primary" link="new" />}
      >
        <Grid gap="1rem">
          <OrganizationApplicationFormsDataTable {...tableProps} />
        </Grid>
      </Page>

      <AnimatedRoutes>
        <Route path=":id" element={<OrganizationApplicationFormOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

export const OrganizationApplicationForms = withPageError(RootOrganizationApplicationForms)
