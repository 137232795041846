import React from 'react'

import AddressSelectorInput from '../../../components/Forms/AddressSelectorInput'
import Attachments from '../../../components/Forms/Attachments'
import ContextShow from '../../../components/Forms/ContextShow'
import DateInput from '../../../components/Forms/DateInput'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import EmailInput from '../../../components/Forms/EmailInput'
import Input from '../../../components/Forms/Input'
import Option from '../../../components/Forms/Option'
import PhoneInput from '../../../components/Forms/PhoneInput'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import Select from '../../../components/Forms/Select'
import SignatureInput from '../../../components/Forms/SignatureInput'
import SmartTextarea from '../../../components/Forms/SmartTextarea'
import YesNoRadioGroup from '../../../components/Forms/elements/YesNoRadioGroup'
import YesNoRadioGroupWithTextarea from '../../../components/Forms/elements/YesNoRadioGroupWithTextarea'

export const GRIEVANCE_FORM = [
  {
    title: 'Personal Information',
    model: 'personal_information',
    fields: [
      {
        label: 'Name',
        model: 'name',
        component: ({ isRequired }) => (
          <Input
            label="Name"
            model="personal_information.name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Email',
        model: 'email',
        component: ({ isRequired }) => (
          <EmailInput
            label="Email"
            model="personal_information.email"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Phone Number',
        model: 'phone_number',
        component: ({ isRequired }) => (
          <PhoneInput
            label="Phone Number"
            model="personal_information.phone_number"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Address',
        model: 'address',
        component: ({ isRequired }) => (
          <AddressSelectorInput
            label="Address"
            model="personal_information.address"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Date',
        model: 'date',
        component: ({ isRequired }) => (
          <DateInput
            defaultToNow
            label="Date"
            model="personal_information.dated_at"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Incident Details',
    model: 'incident_details',
    fields: [
      {
        label: 'Date/Time of Incident',
        model: 'incident_datetime',
        component: ({ isRequired }) => (
          <DateTimeInput
            label="Date/Time of Incident"
            model="incident_details.incident_datetime"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Organization Name',
        model: 'organization_name',
        component: ({ isRequired }) => (
          <Input
            label="Organization Name"
            model="incident_details.organization_name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Organization Address',
        model: 'organization_address',
        component: ({ isRequired }) => (
          <AddressSelectorInput
            label="Organization Address"
            model="incident_details.organization_address"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Organization Contact Name',
        model: 'organization_contact_name',
        component: ({ isRequired }) => (
          <Input
            label="Organization Contact Name"
            model="incident_details.organization_contact_name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Organization Contact Phone',
        model: 'organization_contact_phone',
        component: ({ isRequired }) => (
          <PhoneInput
            label="Organization Contact Phone"
            model="incident_details.organization_contact_phone"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Name of Residence in Question',
        model: 'residence_name',
        component: ({ isRequired }) => (
          <Input
            label="Name of Residence in Question"
            model="incident_details.residence_name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Recovery Home Address',
        model: 'recovery_home_address',
        component: ({ isRequired }) => (
          <AddressSelectorInput
            label="Recovery Home Address"
            model="incident_details.recovery_home_address"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Grievance Information',
    model: 'grievance_information',
    fields: [
      {
        label: 'Standard(s) in question',
        model: 'standards_in_question',
        component: ({ isRequired }) => (
          <Select
            allowEmpty
            label="Standard(s) in question"
            model="grievance_information.standards_in_question"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          >
            <Option label="Residence Operates with Integrity" value="residence_operates_with_integrity" />
            <Option label="Residence Upholds Resident Rights" value="residence_upholds_resident_rights" />
            <Option label="Residence is Recovery-Oriented" value="residence_is_recovery_oriented" />
            <Option label="Residence is Peer Staffed and Governed" value="residence_is_peer_staffed_and_governed" />
            <Option label="Residence Promotes Health and Safety" value="residence_promotes_health_and_safety" />
            <Option label="Residence Provides a Home-Like Environment" value="residence_provides_a_home_like_environment" />
            <Option label="Residence Inspires Purpose in the Residents" value="residence_inspires_purpose_in_the_residents" />
            <Option label="Residence Cultivates a Community Around Recovery" value="residence_cultivates_a_community_around_recovery" />
            <Option label="Residence Promotes Recovery" value="residence_promotes_recovery" />
            <Option label="Residence is a Good Neighbor in the Community" value="residence_is_a_good_neighbor_in_the_community" />
          </Select>
        ),
      },
      {
        label: 'Grievance details',
        model: 'grievance_details',
        component: ({ isRequired }) => (
          <SmartTextarea
            useQuickText={false}
            useDictation
            label="Grievance details"
            model="grievance_information.grievance_details"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label:
          "Description of what happened when you used the house's grievance process or a description as to why you did not use the house's grievance process",
        model: 'grievance_process_description',
        component: ({ isRequired }) => (
          <SmartTextarea
            useQuickText={false}
            useDictation
            label="Description of what happened when you used the house's grievance process or a description as to why you did not use the house's grievance process"
            model="grievance_information.grievance_process_description"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'What would you like to see happen as a result of the complaint?',
        model: 'desired_outcome',
        component: ({ isRequired }) => (
          <SmartTextarea
            useQuickText={false}
            useDictation
            label="What would you like to see happen as a result of the complaint?"
            model="grievance_information.desired_outcome"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Resident Information',
    model: 'resident_information',
    fields: [
      {
        label: 'Resident Move in Date',
        model: 'move_in_date',
        component: ({ isRequired }) => (
          <DateInput
            label="Resident Move in Date"
            model="resident_information.move_in_date"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Resident move out date (if applicable)',
        model: 'move_out_date',
        component: ({ isRequired }) => (
          <DateInput
            label="Resident move out date (if applicable)"
            model="resident_information.move_out_date"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Grievant relationship to provider',
        model: 'relationship_to_provider',
        component: ({ isRequired }) => (
          <Input
            label="Grievant relationship to provider"
            model="resident_information.relationship_to_provider"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Additional Information',
    model: 'additional_information',
    fields: [
      {
        label: 'I claim direct, first-person knowledge related to this incident',
        model: 'direct_knowledge',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="I claim direct, first-person knowledge related to this incident"
            model="additional_information.direct_knowledge"
            showTextareaWhen={false}
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Supporting Document Upload',
        model: 'supporting_documents',
        component: ({ isRequired }) => (
          <Attachments
            label="Supporting Document Upload"
            model="additional_information.supporting_documents"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Signature/Agreement/Confirmation',
        model: 'signature',
        component: ({ isRequired }) => (
          <SignatureInput
            label="Signature/Agreement/Confirmation"
            model="additional_information.signature"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Have you submitted a grievance form for this matter prior to this one?',
        model: 'prior_grievance_submitted',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Have you submitted a grievance form for this matter prior to this one?"
            model="additional_information.prior_grievance_submitted"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Are you the person directly affected by the violation?',
        model: 'directly_affected',
        component: ({ isRequired }) => (
          <>
            <YesNoRadioGroupWithTextarea
              label="Are you the person directly affected by the violation?"
              model="additional_information.directly_affected"
              showTextareaWhen={false}
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />

            <ContextShow when="additional_information.directly_affected" is={false}>
              <Input
                label="If you are not the person directly affected by the violation, what is your relationship to the party that was?"
                model="additional_information.relationship_to_affected_party"
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Is there a specific staff or volunteer who is the subject of this grievance?',
        model: 'specific_subject',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Is there a specific staff or volunteer who is the subject of this grievance?"
            model="additional_information.specific_subject"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Address of Concern',
        model: 'address_of_concern',
        component: ({ isRequired }) => (
          <AddressSelectorInput
            label="Address of Concern"
            model="additional_information.address_of_concern"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Is Complaint House only or Organization wide?',
        model: 'complaint_scope',
        component: ({ isRequired }) => (
          <RadioGroup
            layout="horizontal-dense"
            label="Is Complaint House only or Organization wide?"
            model="additional_information.complaint_scope"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          >
            <Radio value="house" label="House only" />
            <Radio value="organization" label="Organization wide" />
          </RadioGroup>
        ),
      },
      {
        label: 'Consent to information being shared with state agency',
        model: 'consent_to_share',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Consent to information being shared with state agency"
            model="additional_information.consent_to_share"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Submit grievance anonymously',
        model: 'submit_anonymously',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Submit grievance anonymously"
            model="additional_information.submit_anonymously"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
]
