import React from 'react'
import { NavLink, Navigate, Route, Routes, useParams } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { PartnershipInviteFromStatus } from '@behavehealth/constructs/Partnerships/PartnershipInviteFromStatus'
import { PartnershipInviteToStatus } from '@behavehealth/constructs/Partnerships/PartnershipInviteToStatus'
import { titleCase } from '@behavehealth/utils/functions'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

import Button from '@behavehealth/components/Button'
import SummonOverlay from '@behavehealth/components/SummonOverlay'
import Tabs from '@behavehealth/components/Tabs'
import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants'
import { GlyphCell } from '@behavehealth/components/DataTable/cells/GlyphCell'
import { ProfileCell, ProfileCellInner } from '@behavehealth/components/DataTable/cells/ProfileCell'
import { NewOrganizationOverlay } from '@behavehealth/constructs/Organizations/NewOrganizationOverlay'

export const CommunityLeads = () => {
  return (
    <>
      <Tabs>
        <Tabs.List className="px-0 -mt-3 mb-4">
          {/* <Tabs.Item as={NavLink} label="All Leads" to={`all`} /> */}
          <Tabs.Item as={NavLink} label="New" to={`new`} />
          <Tabs.Item as={NavLink} label="Archived" to={`archived`} />
          {/* <Tabs.Item as={NavLink} label="Approved" to={`approved`} />
          <Tabs.Item as={NavLink} label="Denied" to={`denied`} />
          <Tabs.Item as={NavLink} label="Banned" to={`banned`} /> */}
        </Tabs.List>
      </Tabs>

      <Routes>
        <Route index element={<Navigate to="new" replace />} />
        <Route path=":status" element={<CommunityLeadsDataTable />} />
      </Routes>
    </>
  )
}

export const CommunityLeadsDataTable = () => {
  const { status }: any = useParams()

  const tableProps = useDataTable({
    name: ['partnership-leads'],
    endpoint: '/partnership_leads',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        // disableSort: true,
        disableSelect: true,
        formatValue: ({ data }: any) => <MainCell testKey="main_cell" id={data.id} value={data.name} avatar={data.avatar} to={data.id} />,
      },
      {
        title: 'Actions',
        id: 'actions',
        width: 180,
        disableSort: true,
        hoverExpand: false,
        formatValue: ({ data }: any) => {
          if (data.id === 1) return null
          return (
            <SummonOverlay overlay={<NewOrganizationOverlay dataID="new" initialData={{ name: data.name, category: 'provider' }} />}>
              <Button label="Create Organization" size={100} glyph="add" display="flex" />
            </SummonOverlay>
          )
        },
      },
      {
        title: 'Organization',
        id: 'organization',
        width: 240,
        disableSort: true,
        hoverExpand: false,
        formatValue: ({ data }: any) => {
          if (data.id === 1) return <ProfileCellInner icon="organizations" value={{ name: 'Org 1' }} />
          return null
        },
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'tags.edit',
        tagSmartCategories: 'organizations',
      },
      {
        title: 'Category',
        id: 'category_type',
        model: 'subcategory.name',
        type: 'title',
        width: 160,
      },
      {
        title: 'Phone',
        model: 'phone_no',
        type: 'phone',
      },
      {
        title: 'Email',
        model: 'email',
        type: 'email',
      },
      {
        title: 'Website',
        model: 'website',
        type: 'external_url',
        disableSelect: true,
      },
      {
        title: 'Address',
        model: 'address',
        type: 'address',
        disableSort: true,
        width: 200,
      },
    ],
    [],
  )

  return (
    <>
      <DataTable
        {...tableProps}
        data={[
          { id: 1, name: 'Org 1', subcategory: { name: 'Treatment Centers' } },
          { id: 2, name: 'Org 2', subcategory: { name: 'Sober Living Homes' } },
          { id: 3, name: 'Org 3', subcategory: { name: 'Clinic' } },
        ]}
        meta={{ count: 3 }}
        asCard
        title={`${titleCase(status)} Community Leads`}
        icon="community_invites"
        columns={columns}
      />
    </>
  )
}
