import React from 'react'
import { keyframes } from '@emotion/react'
import { Redirect, Switch, Route, useParams, useRouteMatch } from 'react-router-dom'
import { Routes, Route as RouteV6 } from 'react-router-dom-v5-compat'

import { address } from '@behavehealth/utils/functions'
import { COLORS, TOKENS, HARD_SHADOW } from '@behavehealth/theme'
import { useUpdate } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withBackListener from '@behavehealth/hocs/withBackListener'

import AnimatedSwitch from '@behavehealth/components/AnimatedSwitch'
import Button from '@behavehealth/components/Button'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'
import Flex from '@behavehealth/components/Flex'
import Nav from '@behavehealth/components/Nav'
import PageGrid from '@behavehealth/components/PageGrid'
import PageProfile from '@behavehealth/components/PageProfile'
import Portal from '@behavehealth/components/Portal'
import SummonOverlay from '@behavehealth/components/SummonOverlay'
import TagsSelector from '@behavehealth/components/TagsSelector'

import { AuthorityOrganizationContactOverlay } from '@behavehealth/constructs/AuthorityOrganizations/AuthorityOrganizationContactOverlay'
import { AuthorityOrganizationLocationOverlay } from '@behavehealth/constructs/AuthorityOrganizations/AuthorityOrganizationLocationOverlay'
import { AuthorityOrganizationTrackSelector } from '@behavehealth/constructs/AuthorityOrganizations/AuthorityOrganizationTrackSelector'
import { AuthorityOrganizationWorkflowOverlay } from '@behavehealth/constructs/AuthorityOrganizations/AuthorityOrganizationWorkflowOverlay'
import { CommunityOrganizationAuthorityNavItems } from '@behavehealth/components/Navs/CommunityOrganizationAuthorityNavItems'
import { EHROrganizationStatus } from '@behavehealth/constructs/Organizations/EHROrganizationStatus'
import { Heading } from '@behavehealth/components/Typography'
import { OrganizationFileOverlay } from '@behavehealth/constructs/Organizations/OrganizationFileOverlay'
import { OrganizationStatusGroupStatus } from '@behavehealth/constructs/Organizations/OrganizationStatusGroupStatus'

// AUTHORITY PAGES
import { AgreementForms } from './authority/agreement_forms'
import { CertificationForms } from './authority/certification_forms'
import { Contacts } from './authority/contacts'
import { GeneralInfo } from './authority/general_info'
import { GrievanceForms } from './authority/grievance_forms'
import { IncidentReporting } from './authority/incident_reporting'
import { InspectionForms } from './authority/inspection_forms'
import { LeadApplicationForms } from './authority/lead_application_forms'
import { Locations } from './authority/locations'
import { Studies } from './authority/studies'
import { Surveys } from './authority/surveys'

// INTERNAL PAGES
import Calendar from './internal/calendar'
import Financials from './internal/financials/financials'
import FormSubmissions from './internal/form_submissions'
import InternalFiles from './internal/files'
import Todos from './internal/todos/todos'
import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'

const WORKFLOWS = [
  {
    name: 'Pre-Approve',
    glyph: 'intake_application',
    glyphColor: 'blue',
  },
  {
    name: 'Inspect',
    glyph: 'search',
    glyphColor: 'purple',
  },
  {
    name: 'Approve',
    glyph: 'tick_circle',
    glyphColor: 'green',
  },
  {
    name: 'Re-Approve',
    glyph: 'reset',
    glyphColor: 'green',
  },
  {
    name: 'Deny',
    glyph: 'circle_error',
    glyphColor: 'red',
  },
  {
    name: 'Ban',
    glyph: 'decline',
    glyphColor: 'red',
  },
]

const RootAuthorityOrganization = ({ organization }: any) => {
  const match = useRouteMatch()
  const params: any = useParams()

  const { resource_id: id } = params

  const { mutateAsync: updatePartnership }: any = useUpdate({
    name: ['update-organization'],
    url: `/organizations/${id}`,
    invalidate: ['organization', id],
    invalidateKeys: ['organizations'],
  })

  const [workflow, setWorkflow] = React.useState(null)

  if (!organization) return null

  const isPartner = organization?.status === 'partner' && organization?.partnership_status === 'partnership_active'

  return (
    <>
      <SummonOverlay
        isOpen={!!workflow}
        onClose={() => {
          setWorkflow(null)
        }}
        overlay={<AuthorityOrganizationWorkflowOverlay organization={organization} title={workflow?.name} glyph={workflow?.glyph} />}
      />

      <div css={styles}>
        <header className={`flex items-center max-h-[50px]`} css={headerStyles}>
          <div className="flex items-stretch mq600:absolute h-full left-0 z-[2]">
            <Button
              link={'/community/organizations'}
              type="list"
              glyph="long_arrow_left"
              color="gray"
              borderRadius="0"
              flex="0 0 56px"
              css={backStyles}
            />

            <Heading is="h1" size={300} className="ml-4 hidden self-center mq900:block">
              Organizations
            </Heading>
          </div>

          <Flex gap="0.5rem" className="my-4 !mx-auto">
            <Button label="Authority" icon="rcm_verifications" type="primary" color="green" />
          </Flex>
        </header>

        <PageGrid breakpoint={3} columns="285px 1fr">
          <Nav
            showBack={false}
            breakpoint={3}
            headingSize={400}
            record={organization}
            title={organization?.name}
            avatar={organization?.avatar}
            description={address(organization.address, '')}
            back="/community/organizations"
            desktopProps={{
              icon: 'organizations',
              title: 'Organization',
              headingSize: 300,
              avatar: undefined,
              description: undefined,
            }}
            profile={
              <>
                <PageProfile
                  more
                  record={organization}
                  title={organization.name}
                  description={address(organization.address, '')}
                  beforeActions={
                    <div className="grid gap-2 w-full">
                      <Dropdown label="Actions" buttonType="primary">
                        {WORKFLOWS.map((workflow) => (
                          <DropdownItem
                            key={workflow.name}
                            label={workflow.name}
                            glyph={workflow.glyph}
                            glyphColor={workflow.glyphColor}
                            color={workflow.glyphColor}
                            onClick={() => setWorkflow(workflow)}
                          />
                        ))}
                      </Dropdown>

                      <Button
                        label="Organization Workflows →"
                        type="default"
                        glyph="settings"
                        color="text"
                        link="/settings/organization-workflows"
                        size={200}
                      />
                    </div>
                  }
                  underActions={
                    <div className="grid gap-3 w-full">
                      <TagsSelector
                        tags={organization?.tags}
                        onSave={(tagIDs: any) => updatePartnership({ tag_ids: tagIDs })}
                        editPermission="tags.edit"
                      />
                    </div>
                  }
                  status={
                    <>
                      <AuthorityOrganizationTrackSelector organizationId={organization.id} />
                      <OrganizationStatusGroupStatus status={organization.status_group} />
                      {organization.status_group !== 'is_archived' && <EHROrganizationStatus status={organization.internal_status} />}
                    </>
                  }
                />
              </>
            }
          >
            <CommunityOrganizationAuthorityNavItems organization={organization} isPartner={isPartner} />
          </Nav>

          <Routes>
            <RouteV6 path={`${match.path}/authority/general-info/*`} element={<GeneralInfo />} />
            <RouteV6 path={`${match.path}/authority/grievance-forms/*`} element={<GrievanceForms />} />
            <RouteV6 path={`${match.path}/authority/incident-reporting/*`} element={<IncidentReporting />} />
            <RouteV6 path={`${match.path}/authority/studies/*`} element={<Studies />} />
            <RouteV6 path={`${match.path}/authority/surveys/*`} element={<Surveys />} />
            <RouteV6 path={`${match.path}/authority/lead-contact-forms/*`} element={<LeadApplicationForms />} />
            <RouteV6 path={`${match.path}/authority/agreement-forms/*`} element={<AgreementForms />} />
            <RouteV6 path={`${match.path}/authority/inspection-forms/*`} element={<InspectionForms />} />
            <RouteV6 path={`${match.path}/authority/certification-forms/*`} element={<CertificationForms />} />
            <RouteV6 path={`${match.path}/authority/contacts/*`} element={<Contacts />} />
            <RouteV6 path={`${match.path}/authority/locations/*`} element={<Locations />} />
          </Routes>

          <Switch>
            <Route path={`${match.path}/authority/files`} component={InternalFiles} />
            <Route path={`${match.path}/authority/todo`} component={Todos} />
            <Route path={`${match.path}/authority/calendar`} component={Calendar} />
            <Route path={`${match.path}/authority/financials`} component={Financials} />
            <Route path={`${match.path}/authority/form-submissions`} component={FormSubmissions} />
          </Switch>

          <Redirect exact from={match.url} to={`${match.url}/authority/general-info`} />
        </PageGrid>
      </div>

      <Portal type="z90">
        <AnimatedRoutes className="overlays" animation="animation" timeout={{ enter: 400, exit: 400 }}>
          <RouteV6 path={`${match.path}/authority/files/:id`} element={<OrganizationFileOverlay useV6Router />} />
          <RouteV6 path={`${match.path}/authority/contacts/:id`} element={<AuthorityOrganizationContactOverlay useV6Router />} />
          <RouteV6 path={`${match.path}/authority/locations/:id`} element={<AuthorityOrganizationLocationOverlay useV6Router />} />
        </AnimatedRoutes>
      </Portal>
    </>
  )
}

const styles = {
  display: 'grid',
  background: COLORS.lightBackground,
  gridTemplateRows: 'auto 1fr',
  overflow: 'hidden',
}

const animation = keyframes`
  0% {
    opacity: 0.5;
    transform: translateY(-3px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const headerStyles = {
  background: 'white',
  minHeight: TOKENS.headerHeight,
  animation: `${animation} 320ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards`,
  borderBottom: `3px solid ${COLORS.green}`,

  boxShadow: HARD_SHADOW(2),
  zIndex: 2,

  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: '100%',
    left: 0,
    zIndex: 0,

    height: 2,
    bottom: 0,
    borderRadius: '0 3px 3px 0',

    transition: `
        transform 200ms cubic-bezier(0.39, 0.575, 0.565, 1),
        opacity 1400ms cubic-bezier(0.39, 0.575, 0.565, 1)
      `,
    transformOrigin: 'center left',
  },

  '&.is-sticky': {
    position: 'sticky',
    zIndex: 2,
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(2),
    background: 'white',
  },

  '&.is-dropdown': {
    cursor: 'pointer',

    '&:hover': {
      background: 'white',

      'h1, h2, h3': {
        color: COLORS.link,
      },
    },
  },
}

const backStyles = {
  boxShadow: 'none',
  position: 'relative',
  margin: 0,

  '&::after': {
    content: '""',
    width: 1,
    height: '100%',
    position: 'absolute',
    top: 0,
    right: -1,
    background: `linear-gradient(${COLORS.transparent}, ${COLORS.divider}, ${COLORS.transparent})`,
  },

  svg: {
    transition: 'transform 100ms cubic-bezier(0.39, 0.575, 0.565, 1)',
  },

  '&:hover': {
    cursor: 'pointer',
    background: 'white',
    boxShadow: `
      2px 0  6px ${COLORS.shadow},
      4px 0  12px ${COLORS.shadow},
      8px 0  24px ${COLORS.shadow}
    `,

    svg: {
      fill: COLORS.blue,
      transform: 'translateX(-1px) scale3d(1.1, 1.1, 1.1)',
    },
  },
}

export const AuthorityOrganization = withBackListener(withPageError(RootAuthorityOrganization))
