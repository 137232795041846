const STEPS = {
  organization_details: {
    label: 'Organization Details',
    isRequired: true,
  },
  certifications: {
    label: 'Certifications',
  },
  inspections: {
    label: 'Inspections',
  },
  studies: {
    label: 'Studies',
  },
  surveys: {
    label: 'Surveys',
  },
}

export const ORGANIZATION_WORKFLOWS = [
  {
    name: 'Pre-Approve',
    glyph: 'intake_application',
    steps: STEPS,
  },
  {
    name: 'Inspect',
    glyph: 'search',
    steps: STEPS,
  },
  {
    name: 'Approve',
    glyph: 'tick_circle',
    steps: STEPS,
  },
  {
    name: 'Re-Approve',
    glyph: 'reset',
    steps: STEPS,
  },
  {
    name: 'Deny',
    glyph: 'circle_error',
    steps: STEPS,
  },
  {
    name: 'Ban',
    glyph: 'decline',
    steps: STEPS,
  },
]

export const ORGANIZATION_TRACKS = [
  {
    id: '1',
    name: 'Track 1',
    description: 'Track 1 Description',
  },
  {
    id: '2',
    name: 'Track 2',
    description: 'Track 2 Description',
  },
  {
    id: '3',
    name: 'Track 3',
    description: 'Track 3 Description',
  },
]
