import React from 'react'
import { useMedia } from 'use-media'
import clsx from 'clsx'
import debounce from 'lodash/debounce'
import kebabCase from 'lodash/kebabCase'

import { COLORS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { useUpdate, invalidateQueries } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Select from '../../components/Forms/Select'
import URLInput from '../../components/Forms/URLInput'

import { OrganizationApplicationFormExample } from './OrganizationApplicationFormExample'
import { PlainSwitch } from '../../components/Forms/Switch'

import { APP_URLS } from '../MyAccount/utils'
import { P, Ol, Li } from '../../components/Typography'
import { OrganizationApplicationFormSettings } from './OrganizationApplicationFormSettings'

const RootOrganizationApplicationFormOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'TODO',
    endpoint: '/TODO',
    invalidate: 'TODO',
    options: props,
  })

  const { tenant, timezone } = useSettings()

  const isDesktop = useMedia({ minWidth: 900 })

  const [formSettings, setFormSettings] = React.useState(null)
  const [isPreviewVisible, setIsPreviewVisible] = React.useState(false)
  const [isValid, setIsValid] = React.useState(false)

  const debouncedSetFormSettings = React.useMemo(() => {
    return debounce((value) => {
      setFormSettings?.(value)
    }, 300)
  }, [])

  const togglePreview = () => {
    setIsPreviewVisible((c) => !c)
  }

  const { mutateAsync: updateDefaultAsync, isLoading: isMakingDefault } = useUpdate({
    url: `/application_forms/${id}/make_default`,
    invalidate: 'application_forms',
  })

  const isDefaultForm = data?.is_default
  const isDefaultSlug = data?.slug === 'full-application'

  React.useEffect(() => {
    if (!!formSettings || isEditable) return

    setFormSettings(data)
  }, [data, isEditable, formSettings])

  React.useEffect(() => {
    if (isPreviewVisible && !isDesktop) {
      setIsPreviewVisible(false)
    }
  }, [isDesktop, isPreviewVisible])

  const setAsDefault = async () => {
    await updateDefaultAsync({})
    invalidateQueries('application_forms')
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={60} />
  }

  return (
    <Overlay onClose={close} showBackdrop={isEditable} position="right" maxWidth={isPreviewVisible ? 100 : 60}>
      <Overlay.Header icon="lead_contact_forms" title="Lead Contact Form" />

      {isDesktop && (
        <Overlay.SubHeader className="flex items-center justify-end flex-nowrap px-2 !py-1.5">
          <Label isCompact label="Show Preview" onClick={togglePreview} className="!flex-[0_0_auto] cursor-pointer" />
          <PlainSwitch size={200} isChecked={isPreviewVisible} onCheckedChange={setIsPreviewVisible} />
        </Overlay.SubHeader>
      )}

      <div className={clsx('grid grid-rows-[100%] overflow-hidden', isPreviewVisible ? 'grid-cols-2' : 'grid-cols-1')}>
        <Overlay.Content className={isPreviewVisible ? 'border-r border-0 border-solid border-divider' : ''}>
          <div className="grid gap-4 px-5 py-4">
            {isEditable && (
              <Alert small contrast glyph="info">
                <P>
                  <strong>How to customize your application:</strong>
                </P>
                <Ol>
                  <Li>Check the boxes for the questions you want in your application.</Li>
                  <Li>If you want the question required select the required check-box.</Li>
                  <Li>
                    Click the preview button to see what your form looks like. Please note that some questions have related follow-up
                    questions included in the preview.
                  </Li>
                </Ol>
              </Alert>
            )}

            {!isEditable && isDefaultForm && !isDefaultSlug && (
              <Alert small contrast glyph="intake_application">
                This is the default application form and can be accessed at{' '}
                <a href={`${APP_URLS.ehr}/${tenant.id}/authority-forms/full-application`} target="_blank">
                  {APP_URLS.ehr}/{tenant.id}/authority-forms/full-application
                </a>
                , as well as the public link below.
              </Alert>
            )}

            <OrganizationApplicationFormSettings
              form={form}
              timezone={tenant?.timezone}
              isEditable={isEditable}
              companyName={tenant?.name}
              initialModel={initialModel}
              onValidationUpdate={setIsValid}
              onUpdate={debouncedSetFormSettings}
              before={
                <>
                  <Flex stretchChildrenX gap="1rem">
                    <Input
                      label="Form Name"
                      model="name"
                      defaultValue="Application Form"
                      validations={{
                        presence: {
                          message: 'Please enter a form name',
                        },
                      }}
                    />

                    {isEditable ? (
                      <Input
                        label="Public Link"
                        model="slug"
                        prefix={
                          <span className="flex items-center">
                            <Glyph glyph="website" size={16} className="mr-1.5" color={COLORS.text} />
                            <span className="text-[0.85rem]">
                              {APP_URLS.ehr}/{tenant.id}/authority-forms/
                            </span>
                          </span>
                        }
                        processValueBeforeChange={(value: any) => kebabCase(value)}
                        validations={{
                          presence: {
                            message: 'Please enter a public link',
                          },
                        }}
                      />
                    ) : (
                      <URLInput label="Public Link" value={`${APP_URLS.ehr}/${tenant.id}/authority-forms/${data?.slug}`} />
                    )}

                    <Select
                      allowEmpty
                      label="Expiry Time"
                      model="expires_in_hours"
                      tooltip="This expiration time is used to secure the application form when partially completed. If applicant visits application form link after expiration, they will be asked to re-verify their email or sms number to get access again. The partially completed application form will still be available after re-verification."
                      defaultValue={2}
                      validations={{
                        presence: {
                          message: 'Please select an expiry time',
                        },
                      }}
                    >
                      <Option label="1 hour" value={1} />
                      <Option label="2 hours" value={2} />
                      <Option label="3 hours" value={3} />
                      <Option label="6 hours" value={6} />
                      <Option label="12 hours" value={12} />
                      <Option label="24 hours" value={24} />

                      <Option disabled label="––––" />

                      <Option label="2 days" value={48} />
                      <Option label="5 days" value={120} />
                      <Option label="7 days" value={192} />
                      <Option label="10 days" value={240} />
                      <Option label="14 days" value={336} />
                      <Option label="21 days" value={504} />
                      <Option label="30 days" value={720} />
                    </Select>
                  </Flex>
                </>
              }
            />
          </div>
        </Overlay.Content>

        {isPreviewVisible && formSettings?.schema && (
          <Overlay.Content>
            <OrganizationApplicationFormExample
              schema={formSettings.schema}
              thankYouMessage={formSettings.thank_you_message}
              welcomeMessage={formSettings?.welcome_message}
              roiDisclaimerMessage={formSettings?.roi_disclaimer_message}
              companyName={tenant?.name || ''}
            />
          </Overlay.Content>
        )}
      </div>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={!isValid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button glyph="edit" label="Edit Application Form" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />

            {!isDefaultForm && (
              <>
                <ConfirmDialog
                  title="Set as Default Application Form?"
                  message={`The default application form will be made available at ${APP_URLS.ehr}/${tenant.id}/forms/full-application`}
                  yesLabel="Set as Default"
                  onYes={setAsDefault}
                >
                  <Button
                    glyph="intake_application"
                    label="Set as Default"
                    type="default"
                    color="blue"
                    isLoading={isMakingDefault}
                    fullWidth
                  />
                </ConfirmDialog>

                <DeleteDialog
                  title="Delete Application Form?"
                  message="Are you sure you want to delete this Application Form? This action cannot be undone."
                  onYes={deleteRecord}
                >
                  <Button glyph="delete" label="Delete" type="default" color="red" isLoading={isDeleting} fullWidth />
                </DeleteDialog>
              </>
            )}
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const OrganizationApplicationFormOverlay = withOverlayError(RootOrganizationApplicationFormOverlay)
