import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { DataTable } from '../../components/DataTable/DataTable'
import { getClientLink } from '@behavehealth/utils/functions'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import DataFormStatus from '../../components/Statuses/DataFormStatus'

import { FILTERS } from '../Filters/config'

export const ProgressNotesDataTable = (props: any) => {
  const { to } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell as={NavLink} id={data.id} value={data.name} to={to?.(data)} />,
      },
      {
        title: 'Client',
        model: 'client',
        formatValue: ({ value }) => {
          if (!value) return null

          return <MainCell value={value?.name} avatar={value?.avatar || ''} to={`${getClientLink(value)}/progress-notes`} />
        },
      },
      {
        width: 140,
        id: 'status',
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <DataFormStatus status={value} />,
      },
      {
        width: 200,
        id: 'created_at',
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      // {
      //   width: 180,
      //   id: 'supervisor',
      //   model: 'supervisor',
      //   title: 'Supervisor',
      //   type: 'profile',
      // },
      {
        width: 200,
        id: 'updated_at',
        model: 'updated_at',
        title: 'Last Edited',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'author',
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Progress Notes"
      icon="progress_reviews"
      testKey="progress_notes_data_table"
      columns={columns}
      filtersConfig={FILTERS.progress_notes}
      headerLinksConfig={[
        {
          type: 'report',
          to: '/reports/progress-notes',
        },
      ]}
      {...props}
    />
  )
}
