import React from 'react'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { OrganizationTrackOverlay } from '@behavehealth/constructs/AuthorityOrganizations/OrganizationTrackOverlay'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Alert from '@behavehealth/components/Alert'
import Button from '@behavehealth/components/Button'
import Page from '@behavehealth/components/Page'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import Status from '@behavehealth/components/Status'

const OrganizationTracks = () => {
  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        disableSort: true,
        formatValue: ({ data, value }: any) => {
          return (
            <MainCell
              as={NavLink}
              id={data.id}
              to={data.id}
              value={
                <span className="flex items-center">
                  {value}
                  {data?.is_default && <Status small label="Default" color="blue" className="ml-1" />}
                </span>
              }
            />
          )
        },
      },
      {
        title: 'Description',
        model: 'description',
        width: 300,
        disableSort: true,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
        disableSort: true,
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
        disableSort: true,
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [],
  )

  return (
    <>
      <Page
        breakpoint={3}
        title="Organization Tracks"
        feature="organization_tracks"
        actions={<Button as={Link} label="Add Organization Track" type="primary" glyph="add" link="new" />}
      >
        <div className="grid gap-3">
          <Alert small contrast glyph="info">
            <div>
              <b>Organization Tracks</b>
            </div>

            <ul className="pl-5">
              <li>Each organization track has a corresponding workflow</li>
              <li>Configure the steps and instructions for each track</li>
              <li>Create multiple tracks and assign them to organizations as needed</li>
            </ul>
          </Alert>

          <DataTable title="Organization Tracks" columns={columns} data={DATA} meta={{ count: DATA.length }} />
        </div>
      </Page>

      <AnimatedRoutes>
        <Route path=":id" element={<OrganizationTrackOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

export const DATA = [
  {
    id: '1',
    name: 'Track 1',
    description: 'Track 1 Description',
    organization_track: {
      id: 'a',
      name: 'Track 1',
      is_default: true,
    },
  },
  {
    id: '2',
    name: 'Track 2',
    description: 'Track 2 Description',
    organization_track: {
      id: 'b',
      name: 'Track 2',
      is_default: false,
    },
  },
  {
    id: '3',
    name: 'Track 3',
    description: 'Track 3 Description',
    organization_track: {
      id: 'c',
      name: 'Track 3',
      is_default: false,
    },
  },
]

export default withPageError(OrganizationTracks)
