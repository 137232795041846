import React from 'react'
import clsx from 'clsx'
import debounce from 'lodash/debounce'
import Zoom from 'react-medium-image-zoom'

import 'react-medium-image-zoom/dist/styles.css'

import { FEATURES } from '../../../theme'
import { useSettings } from '../../../hooks/useSettings'
import useCopyToClipboard from '../../../hooks/useCopyToClipboard'

import Accordions from '../../../components/Accordions'
import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import ContextHide from '../../../components/Forms/ContextHide'
import ContextShow from '../../../components/Forms/ContextShow'
import Flex from '../../../components/Flex'
import Form from '../../../components/Forms/Form'
import Input from '../../../components/Forms/Input'
import Label from '../../../components/Label'
import MiniRichTextEditor from '../../../components/Forms/MiniRichTextEditor'
import MultiOverlaySelector from '../../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import Option from '../../../components/Forms/Option'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import RichTextEditor from '../../../components/Forms/RichTextEditor'
import Select from '../../../components/Forms/Select'
import Textarea from '../../../components/Forms/Textarea'
import TreeItem from '../../../components/TreeItem'

import { FILING_UNDER_OPTIONS } from '../utils/constants'

import form_builder_pdf_export from '../../../assets/images/form_builder_pdf_export.png'
import form_submissions_addendums from '../../../assets/images/form_submissions_addendums.png'
import form_submissions_client_portal_1 from '../../../assets/images/form_submissions_client_portal_1.png'
import form_submissions_client_portal_2 from '../../../assets/images/form_submissions_client_portal_2.png'
import form_submissions_file_under_1 from '../../../assets/images/form_submissions_file_under_1.png'
import form_submissions_file_under_2 from '../../../assets/images/form_submissions_file_under_2.png'
import form_submissions_pdf_export from '../../../assets/images/form_submissions_pdf_export.png'
import form_submissions_review_signoff_actions from '../../../assets/images/form_submissions_review_signoff_actions.png'
import form_submissions_review_statuses from '../../../assets/images/form_submissions_review_statuses.png'
import form_submissions_signatures from '../../../assets/images/form_submissions_signatures.png'

import { useFormBuilder } from '../useFormBuilder'
import { ClientTracksMultiObjectSelector } from '../../../components/Forms/elements/ClientTracksMultiObjectSelector'

export const FormSettingsMain = (props: any) => {
  const { isNew, modelPrefix } = props

  const { isBehave, tenant, isEHRApp, timezone } = useSettings()

  const form = React.useRef(null)
  const apiData: any = useFormBuilder((state: any) => state.apiData)
  const formSettings: any = useFormBuilder((state: any) => state.formSettings)
  const isEditable: any = useFormBuilder((state: any) => state.isEditable)
  const setFormSettings: any = useFormBuilder((state: any) => state.setFormSettings)

  const debouncedSetFormSettings = React.useMemo(() => {
    return debounce((value) => setFormSettings?.(value), 300)
  }, [])

  const link = tenant && process.env.BH_APP_FORM_URL && `${process.env.BH_APP_FORM_URL}/${tenant.subdomain}/${apiData?.external_id}`

  const { copy, didCopy: linkCopied } = useCopyToClipboard({
    text: link,
    notification: 'Link copied!',
  })

  const canFileUnderClients = React.useMemo(() => {
    if (!formSettings?.use_reference) return false

    const reference_settings = formSettings?.reference_settings

    if (!reference_settings) return false

    return reference_settings.admissions || reference_settings.current_clients || reference_settings.past_clients
  }, [formSettings])

  const formatModel = (model: string) => {
    if (modelPrefix) return `${modelPrefix}.${model}`
    return model
  }

  return (
    <Form
      isCompact
      getForm={form}
      timezone={timezone}
      isEditable={isEditable}
      initialModel={{
        ...formSettings,
        client_tracks: apiData?.client_tracks,
      }}
      onUpdate={(values) => {
        if (!isEditable) return

        debouncedSetFormSettings(values)
      }}
    >
      <div className="-mt-1.5">
        <Accordions>
          <div className="grid gap-1 grid-cols-[100%] mt-1.5">
            <SettingsSection title="Details" glyph="info">
              <div className="grid gap-4 grid-cols-[100%]">
                <Input
                  label="Category"
                  model="category"
                  description="Forms with the same Category will be displayed together in menus and the rest under the 'Other' category"
                />

                {isBehave && (
                  <Select allowEmpty fullWidth label="Status" model={formatModel('status')}>
                    <Option label="Draft" value="draft" />
                    <Option label="To Review" value="to_review" />
                    <Option label="Published" value="published" />
                  </Select>
                )}

                <ClientTracksMultiObjectSelector description="Please make sure to select one of the Clients categories from Filing Options below when using Client Tracks" />

                <RichTextEditor
                  actions={['inline', 'color']}
                  label="Success Message"
                  model={formatModel('success_message')}
                  description="Message that people will see when after they submit the form"
                />

                <Textarea label="Internal Notes" model={formatModel('internal_notes')} />
                <Textarea label="Shared Links" model={formatModel('shared_links')} />
              </div>
            </SettingsSection>

            <SettingsSection
              title="Notifications"
              glyph="notification"
              isApplicable={formSettings.use_notifications}
              applicableModel={formatModel('use_notifications')}
              notApplicableContent={
                <Alert small contrast glyph="info">
                  No notifications are enabled for this form
                </Alert>
              }
              applicableContent={
                <CheckboxGroup
                  isCompact
                  withHover={false}
                  label="Send Notification When:"
                  trueIcon="check"
                  falseIcon="cross"
                  falseStyle="faded"
                  layout="vertical"
                >
                  <Checkbox
                    label="Public Form Link Opened"
                    model={formatModel('notification_settings.notify_on_public_form_view')}
                    tooltip="When checked, the emails and phone numbers below will be notified when the public link of this form is opened"
                  />
                  {formSettings?.notification_settings?.notify_on_public_form_view && (
                    <div className="grid gap-3 grid-cols-[100%] pl-6 pt-1 pb-3">
                      <Input
                        alwaysShowDescription
                        model={formatModel('notification_settings.notify_on_public_form_view_contacts.email')}
                        type="email"
                        label="Email Addresses"
                        description="Emails support multiple recipients, separated by a comma (,)"
                        defaultValue={isNew ? tenant?.contacts?.form_submissions?.email || '' : ''}
                      />

                      <Input
                        alwaysShowDescription
                        model={formatModel('notification_settings.notify_on_public_form_view_contacts.phone_no')}
                        type="phone"
                        label="Phone Number"
                        description="SMS supports only a single recipient"
                        defaultValue={isNew ? tenant?.contacts?.form_submissions?.phone_no || '' : ''}
                      />
                    </div>
                  )}

                  <Checkbox
                    label="Form Submission Created"
                    model={formatModel('notification_settings.notify_on_create')}
                    tooltip="When checked, the emails and phone numbers below will be notified when a new form submission is created"
                  />
                  {formSettings?.notification_settings?.notify_on_create && (
                    <div className="grid gap-3 grid-cols-[100%] pl-6 pt-1 pb-3">
                      <Input
                        alwaysShowDescription
                        model={formatModel('notification_settings.notify_on_create_contacts.email')}
                        type="email"
                        label="Email Addresses"
                        description="Emails support multiple recipients, separated by a comma (,)"
                        defaultValue={isNew ? tenant?.contacts?.form_submissions?.email || '' : ''}
                      />

                      <Input
                        alwaysShowDescription
                        model={formatModel('notification_settings.notify_on_create_contacts.phone_no')}
                        type="phone"
                        label="Phone Number"
                        description="SMS supports only a single recipient"
                        defaultValue={isNew ? tenant?.contacts?.form_submissions?.phone_no || '' : ''}
                      />
                    </div>
                  )}

                  <Checkbox
                    label="Form Submission Updated"
                    model={formatModel('notification_settings.notify_on_update')}
                    tooltip="When checked, the emails and phone numbers below will be notified when a form submission is updated"
                  />
                  {formSettings?.notification_settings?.notify_on_update && (
                    <div className="grid gap-3 grid-cols-[100%] pl-6 pt-1 pb-3">
                      <Input
                        alwaysShowDescription
                        model={formatModel('notification_settings.notify_on_update_contacts.email')}
                        type="email"
                        label="Email Addresses"
                        description="Emails support multiple recipients, separated by a comma (,)"
                        defaultValue={isNew ? tenant?.contacts?.form_submissions?.email || '' : ''}
                      />

                      <Input
                        alwaysShowDescription
                        model={formatModel('notification_settings.notify_on_update_contacts.phone_no')}
                        type="phone"
                        label="Phone Number"
                        description="SMS supports only a single recipient"
                        defaultValue={isNew ? tenant?.contacts?.form_submissions?.phone_no || '' : ''}
                      />
                    </div>
                  )}

                  <Checkbox
                    label="Form Submission Signature Added"
                    model={formatModel('notification_settings.notify_on_add_signature')}
                    tooltip="When checked, the emails and phone numbers below will be notified when a new signature is added to a form submission"
                  />
                  {formSettings?.notification_settings?.notify_on_add_signature && (
                    <div className="grid gap-3 grid-cols-[100%] pl-6 pt-1 pb-3">
                      <Input
                        alwaysShowDescription
                        model={formatModel('notification_settings.notify_on_add_signature_contacts.email')}
                        type="email"
                        label="Email Addresses"
                        description="Emails support multiple recipients, separated by a comma (,)"
                        defaultValue={isNew ? tenant?.contacts?.form_submissions?.email || '' : ''}
                      />

                      <Input
                        alwaysShowDescription
                        model={formatModel('notification_settings.notify_on_add_signature_contacts.phone_no')}
                        type="phone"
                        label="Phone Number"
                        description="SMS supports only a single recipient"
                        defaultValue={isNew ? tenant?.contacts?.form_submissions?.phone_no || '' : ''}
                      />
                    </div>
                  )}

                  <Checkbox
                    label="Form Submission Closed / Signed-Off"
                    model={formatModel('notification_settings.notify_on_final_status')}
                    tooltip="When checked, the emails and phone numbers below will be notified when a form submission is closed or signed-off"
                  />

                  {formSettings?.notification_settings?.notify_on_final_status && (
                    <div className="grid gap-3 grid-cols-[100%] pl-6 pt-1 pb-3">
                      <Input
                        alwaysShowDescription
                        model={formatModel('notification_settings.notify_on_final_status_contacts.email')}
                        type="email"
                        label="Email Addresses"
                        description="Emails support multiple recipients, separated by a comma (,)"
                        defaultValue={isNew ? tenant?.contacts?.form_submissions?.email || '' : ''}
                      />

                      <Input
                        alwaysShowDescription
                        model={formatModel('notification_settings.notify_on_final_status_contacts.phone_no')}
                        type="phone"
                        label="Phone Number"
                        description="SMS supports only a single recipient"
                        defaultValue={isNew ? tenant?.contacts?.form_submissions?.phone_no || '' : ''}
                      />
                    </div>
                  )}
                </CheckboxGroup>
              }
            />

            <SettingsSection
              title="Secure Public Sharing"
              glyph="website"
              isApplicable={formSettings.use_public_sharing}
              applicableModel={formatModel('use_public_sharing')}
              notApplicableContent={
                <Alert small contrast glyph="info">
                  This form is private and can only be accessed in-app by authorized staff members
                </Alert>
              }
              applicableContent={
                <>
                  {isEHRApp && (
                    <Alert small contrast glyph="info">
                      {isNew
                        ? 'After saving this form, a link will be generated and displayed below that you can share with anyone.'
                        : 'This form is public and anyone with the link below can access it and submit responses'}
                    </Alert>
                  )}

                  {!isNew && isEHRApp && (
                    <div>
                      <div className="mt-3 mb-3">
                        <Label isCompact label="Public Form Link:" />
                        <a
                          href={link}
                          target="_blank"
                          rel="noreferrer"
                          css={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            fontSize: '0.88rem',
                            svg: { marginLeft: '0.25em' },
                            '&:hover': { textDecoration: 'underline' },
                            minWidth: 0,
                            whiteSpace: 'break-all',
                          }}
                        >
                          {link}
                        </a>
                      </div>

                      <Flex gap="0.5rem">
                        <Button
                          glyph={linkCopied ? 'check' : 'copy'}
                          label={linkCopied ? 'Link Copied!' : 'Copy Link'}
                          onClick={copy}
                          type={linkCopied ? 'default' : 'primary'}
                          size={100}
                          display="inline-flex"
                        />

                        <Button
                          useGlyphForTarget
                          label="Open in New Tab"
                          href={link}
                          type="default"
                          size={100}
                          display="inline-flex"
                          target="_blank"
                        />
                      </Flex>
                    </div>
                  )}

                  <CheckboxGroup
                    isCompact
                    label="Public Form Submission Options:"
                    trueIcon="check"
                    falseIcon="cross"
                    falseStyle="faded"
                    layout="vertical-dense"
                    className="mt-4"
                  >
                    <Checkbox
                      label="Enable Table of Contents"
                      model={formatModel('public_sharing_settings.table_of_contents')}
                      tooltip="People will see a pages and headings table of contents on the form to navigate between sections"
                    />
                    <Checkbox
                      label="Enable Preview Page"
                      model={formatModel('public_sharing_settings.preview_page')}
                      tooltip="People will see a preview page with all their answers before submitting the form"
                    />
                    <Checkbox
                      label="Enable Save for Later"
                      model={formatModel('public_sharing_settings.save_for_later')}
                      tooltip="People can save the form at any point and get a secure public URL to complete the form later"
                    />
                    <Checkbox
                      label="Show company logo in form header"
                      model={formatModel('public_sharing_settings.header')}
                      tooltip="Display page header with company logo on publicly-shared forms"
                    />
                    <Checkbox
                      label="Request contact details from submitter"
                      model={formatModel('public_sharing_settings.require_submitter_info')}
                    />
                    {isBehave && (
                      <>
                        <Checkbox label="Show Behave Health badge" model={formatModel('public_sharing_settings.show_behave_badge')} />
                        {formSettings?.public_sharing_settings?.show_behave_badge && (
                          <Checkbox
                            label="Enable Behave Health badge link"
                            model={formatModel('public_sharing_settings.use_behave_badge_link')}
                          />
                        )}
                      </>
                    )}
                  </CheckboxGroup>

                  {formSettings?.public_sharing_settings?.require_submitter_info && (
                    <>
                      <CheckboxGroup
                        isCompact
                        label="Public Submitter Info Required:"
                        trueIcon="check"
                        falseIcon="cross"
                        falseStyle="faded"
                        layout="vertical-dense"
                        className="mt-4"
                      >
                        <Checkbox
                          defaultChecked
                          label="Title"
                          model={formatModel('public_sharing_settings.submitter_info.require_title')}
                        />
                        <Checkbox
                          defaultChecked
                          label="Full Name"
                          model={formatModel('public_sharing_settings.submitter_info.require_name')}
                        />
                        <Checkbox label="Email" model={formatModel('public_sharing_settings.submitter_info.require_email')} />
                        <Checkbox label="Phone Number" model={formatModel('public_sharing_settings.submitter_info.require_phone_no')} />
                        <Checkbox label="Notes" model={formatModel('public_sharing_settings.submitter_info.require_notes')} />
                        <Checkbox
                          label="Organization Name"
                          model={formatModel('public_sharing_settings.submitter_info.require_organization_name')}
                        />
                        <Checkbox
                          label="Organization Address"
                          model={formatModel('public_sharing_settings.submitter_info.require_organization_address')}
                        />
                      </CheckboxGroup>

                      <div className="mt-4">
                        <MiniRichTextEditor
                          label={`Public Submission Message`}
                          model={formatModel('public_sharing_settings.submitter_info.public_submission_message')}
                          description="Enter a custom message to display to the submitter when they are required to enter their information"
                        />
                      </div>
                    </>
                  )}
                </>
              }
            />

            <SettingsSection
              glyph="attachment"
              title="Filing Options"
              isApplicable={formSettings.use_reference}
              applicableModel={formatModel('use_reference')}
              notApplicableContent={
                <Alert small contrast glyph="info">
                  Filing Options are not enabled for this Form
                </Alert>
              }
              applicableContent={
                formSettings.use_reference && (
                  <div className="grid gap-5">
                    <Alert small contrast glyph="info">
                      {isEditable
                        ? 'Select where Form Submissions created using this Form can be filed under'
                        : 'Form Submissions created using this Form can be filed under the selected categories below'}
                    </Alert>

                    {FILING_UNDER_OPTIONS.map((group) => (
                      <CheckboxGroup
                        key={group.label}
                        label={group.label}
                        trueIcon="check"
                        falseIcon="cross"
                        falseStyle="faded"
                        layout="vertical-dense"
                      >
                        {group.resources?.map?.((resource) => {
                          const icon = FEATURES[resource.feature]?.icon

                          return (
                            <Checkbox
                              key={resource.type}
                              icon={icon}
                              label={resource.label}
                              model={formatModel(`reference_settings.${resource.type}`)}
                            />
                          )
                        })}
                      </CheckboxGroup>
                    ))}
                  </div>
                )
              }
              moreInfo={
                <div className="grid text-[0.9rem] text-text [&_img]:mb-6 [&_p]:mb-2 [&_p]:mt-0">
                  <ImageSection src={form_submissions_file_under_1}>
                    <p>
                      When <b>"Filing Options"</b> are enabled and any of the options checked, you will see the <b>"Filed Under"</b>{' '}
                      selector in Form Submissions created using this Form
                    </p>
                  </ImageSection>

                  <ImageSection src={form_submissions_file_under_2}>
                    <p>The categories you select will determine which entities you can file the form submission under in this overlay</p>
                  </ImageSection>
                </div>
              }
            />

            <SettingsSection
              glyph="portal"
              title="Client Portal Permissions"
              isApplicable={formSettings.use_portal_permissions}
              applicableModel={formatModel('use_portal_permissions')}
              notApplicableContent={
                <>
                  <Alert small contrast glyph="info">
                    When filed under a client, Form Submissions created using this Form will <b>not be visible</b> in the Client Portal app
                  </Alert>
                </>
              }
              applicableContent={
                <div className="grid gap-4 grid-cols-[100%]">
                  {!canFileUnderClients && (
                    <Alert small contrast glyph="warning" type="warning">
                      <div>
                        <b>Enable "Filing Options"</b> and select at least one client category to view and configure Client Portal
                        permissions.
                      </div>

                      <div className="italic mt-2">
                        <b>Please note:</b> only Form Submissions filed under a client can be shared via the Client Portal app
                      </div>
                    </Alert>
                  )}

                  {canFileUnderClients && (
                    <>
                      <Alert small contrast glyph="info">
                        <div>
                          {formSettings.portal_permissions_settings === 'no_access' && (
                            <>
                              Form Submissions created using this Form will <b>not be visible</b> to clients in their Client Portal app.
                            </>
                          )}

                          {formSettings.portal_permissions_settings === 'can_view' && (
                            <>
                              Clients filed under Form Submissions created using this Form will <b>be able to view</b> them in the Client
                              Portal app, but they will <b>not be able to edit or self-add</b>
                            </>
                          )}

                          {formSettings.portal_permissions_settings === 'can_view_edit' && (
                            <>
                              Clients filed under Form Submissions created using this Form will <b>be able to view and edit</b> them in the
                              Client Portal app, but they will <b>not be able to self-add</b>
                            </>
                          )}

                          {formSettings.portal_permissions_settings === 'can_view_edit_add' && (
                            <>
                              Clients filed under Form Submissions created using this Form will <b>be able to view, edit, and self-add</b>{' '}
                              them in the Client Portal app
                            </>
                          )}
                        </div>
                      </Alert>

                      <RadioGroup
                        label={`When Form Submissions are "Filed-Under" a Client:`}
                        defaultValue="no_access"
                        trueIcon="check"
                        falseIcon="cross"
                        falseStyle="faded"
                        layout="vertical-dense"
                        model={formatModel('portal_permissions_settings')}
                      >
                        <Radio label={`Client cannot view any Form Submissions`} value="no_access" />
                        <Radio label={`Client can only view the Form Submissions`} value="can_view" />
                        <Radio label={`Client can view, and edit Form Submissions`} value="can_view_edit" />
                        <Radio label={`Client can view, edit, and self-add Form Submissions`} value="can_view_edit_add" />
                      </RadioGroup>
                    </>
                  )}
                </div>
              }
              moreInfo={
                <div className="grid text-[0.9rem] text-text [&_img]:mb-6 [&_p]:mb-2 [&_p]:mt-0">
                  <ImageSection src={form_submissions_client_portal_1}>
                    <p>
                      <b>"Clients can only view the Form Submissions"</b> option will enable the Form Inbox page the the client it's filed
                      under.
                    </p>
                  </ImageSection>

                  <ImageSection src={form_submissions_client_portal_2}>
                    <p>
                      <b>"Clients can view, edit, and self-add Form Submissions"</b> option will also allow the client to self-add new Form
                      Submission using this form.
                    </p>
                  </ImageSection>
                </div>
              }
            />

            <SettingsSection
              glyph="merge"
              title="Review & Sign-Off Workflows"
              isApplicable={formSettings.use_review_and_signoff_workflow}
              applicableModel={formatModel('use_review_and_signoff_workflow')}
              notApplicableContent={
                <Alert small contrast glyph="info">
                  Review & Sign-Off Workflows are <b>not enabled</b> for Form Submissions created using this Form
                </Alert>
              }
              applicableContent={
                <div className="grid gap-4 grid-cols-[100%]">
                  {isEditable && (
                    <Alert small contrast glyph="info">
                      Select the workflows you'd like to enable for Form Submissions created using this Form
                    </Alert>
                  )}

                  <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="faded" layout="vertical-dense">
                    <Checkbox
                      label="Enable Supervisor Review Workflow"
                      model={formatModel('review_and_signoff_workflow_settings.enable_supervisor_review')}
                      tooltip="Submissions using this form can be sent to a supervisor for review. The supervisor can then mark the form submission as 'Approved', 'Rejected', or 'Updates Required'"
                    />

                    <Checkbox
                      label="Enable Sign-Off Workflow"
                      model={formatModel('review_and_signoff_workflow_settings.enable_signoff')}
                      tooltip="When checked, submissions to this form can be marked as signed-off."
                    />
                  </CheckboxGroup>

                  <ContextShow when={formatModel('review_and_signoff_workflow_settings.enable_signoff')} is={true}>
                    <CheckboxGroup
                      label="Sign-Off Requirements:"
                      trueIcon="check"
                      falseIcon="cross"
                      falseStyle="faded"
                      layout="vertical-dense"
                    >
                      <Checkbox
                        label="All Staff Members must sign"
                        model={formatModel('review_and_signoff_workflow_settings.signoff_settings.staff_must_sign')}
                        tooltip="All staff members added to the form submissions will be required to sign before the form can be signed-off."
                      />
                      <Checkbox
                        label="All Supervisors must sign"
                        model={formatModel('review_and_signoff_workflow_settings.signoff_settings.supervisors_must_sign')}
                        tooltip="All supervisors added to the form submissions will be required to sign before the form can be signed-off."
                      />
                      <ContextShow when={formatModel('review_and_signoff_workflow_settings.enable_supervisor_review')} is={true}>
                        <Checkbox
                          label="A Supervisor must approve the form"
                          model={formatModel('review_and_signoff_workflow_settings.signoff_settings.supervisor_must_approve')}
                          tooltip="Any supervisor added to the form submissions will be required to approve the form before it can be signed-off."
                        />
                      </ContextShow>
                    </CheckboxGroup>

                    {/* <ContextShow when={formatModel('review_and_signoff_workflow_settings.signoff_settings.staff_must_sign')} is={true}>
                          <MultiOverlaySelector
                            label="Default Staff Members"
                            model={formatModel('default_staff')}
                            type="employees.active"
                            icon="employees"
                            selectTitle={(data: any) => data.name}
                          />
                        </ContextShow> */}

                    {/* <ContextShow
                          when={formatModel('review_and_signoff_workflow_settings.signoff_settings.supervisors_must_sign')}
                          is={true}
                        >
                          <MultiOverlaySelector
                            label="Default Supervisors"
                            model={formatModel('default_supervisors')}
                            type="employees.active"
                            icon="employees"
                            selectTitle={(data: any) => data.name}
                          />
                        </ContextShow> */}

                    {/* <CheckboxGroup
                          label="After Sign-Off:"
                          trueIcon="check"
                          falseIcon="cross"
                          falseStyle="faded"
                          layout="vertical-dense"
                        >
                          <Checkbox
                            label="Allow addendums to be added"
                            model={formatModel('review_and_signoff_workflow_settings.after_signoff.allow_addendums')}
                            tooltip="Any updates to the form after sign-off can be added as addendums."
                          />
                          <Checkbox
                            label="Lock the form from further updates"
                            model={formatModel('review_and_signoff_workflow_settings.after_signoff.lock_form')}
                            tooltip="The form will be locked from further updates after it's been signed-off."
                          />
                        </CheckboxGroup> */}

                    <ContextHide when={formatModel('review_and_signoff_workflow_settings.after_signoff.lock_form')} is={true}>
                      <CheckboxGroup
                        label="On Updates After Sign-Off Requirements:"
                        trueIcon="check"
                        falseIcon="cross"
                        falseStyle="faded"
                        layout="vertical-dense"
                      >
                        <Checkbox
                          label="All Staff Members must sign again"
                          model={formatModel('review_and_signoff_workflow_settings.updates_after_signoff.staff_must_sign')}
                          tooltip="All staff members added to the form submissions will be required to add their signature again after a signed-off form has been updated"
                        />
                        <Checkbox
                          label="All Supervisors must sign again"
                          model={formatModel('review_and_signoff_workflow_settings.updates_after_signoff.supervisors_must_sign')}
                          tooltip="All supervisors added to the form submissions will be required to add their signature again after a signed-off form has been updated"
                        />
                        <ContextShow when={formatModel('review_and_signoff_workflow_settings.enable_supervisor_review')} is={true}>
                          <Checkbox
                            label="A Supervisor must approve the form again"
                            model={formatModel('review_and_signoff_workflow_settings.updates_after_signoff.supervisor_must_approve')}
                            tooltip="Any supervisor added to the form submissions will be required to approve the form again after a signed-off form has been updated."
                          />
                        </ContextShow>
                      </CheckboxGroup>
                    </ContextHide>

                    <ContextShow
                      orMode
                      when={[
                        formatModel('review_and_signoff_workflow_settings.signoff_settings.reference_must_sign'),
                        formatModel('review_and_signoff_workflow_settings.signoff_settings.staff_must_sign'),
                        formatModel('review_and_signoff_workflow_settings.signoff_settings.supervisors_must_sign'),
                      ]}
                      is={true}
                    >
                      <TreeItem isOpen withHover={false} title="Signatures Legal Copy">
                        <div className="grid gap-3 pt-3 pr-3 pb-4">
                          {isEditable && (
                            <Alert small contrast glyph="info">
                              Customize the legal copy for each signature type below.
                            </Alert>
                          )}

                          <ContextShow
                            when={formatModel('review_and_signoff_workflow_settings.signoff_settings.reference_must_sign')}
                            is={true}
                          >
                            <MiniRichTextEditor
                              useQuickText
                              label="Filed-Under Entity Signature Legal Copy"
                              model={formatModel('review_and_signoff_workflow_settings.signatures_legal_text.reference')}
                            />
                          </ContextShow>

                          <ContextShow
                            when={formatModel('review_and_signoff_workflow_settings.signoff_settings.staff_must_sign')}
                            is={true}
                          >
                            <MiniRichTextEditor
                              useQuickText
                              label="Staff Member Signatures Legal Copy"
                              model={formatModel('review_and_signoff_workflow_settings.signatures_legal_text.staff')}
                            />
                          </ContextShow>

                          <ContextShow
                            when={formatModel('review_and_signoff_workflow_settings.signoff_settings.supervisors_must_sign')}
                            is={true}
                          >
                            <MiniRichTextEditor
                              useQuickText
                              label="Supervisors Signatures Legal Copy"
                              model={formatModel('review_and_signoff_workflow_settings.signatures_legal_text.supervisors')}
                            />
                          </ContextShow>
                        </div>
                      </TreeItem>
                    </ContextShow>
                  </ContextShow>
                </div>
              }
              moreInfo={
                <div className="grid text-[0.9rem] text-text [&_img]:mb-6 [&_p]:mb-2 [&_p]:mt-0">
                  <ImageSection src={form_submissions_review_signoff_actions}>
                    <p>
                      <b>"Enable Sign-Off Workflow"</b> and <b>"Enable Supervisor Review Workflow"</b> options will enable the corresponding
                      actions below on the Form Submission overlay.
                    </p>
                  </ImageSection>

                  <ImageSection src={form_submissions_review_statuses}>
                    <p>
                      Additionally, the <b>"Enable Supervisor Review Workflow"</b> option will enable the <b>"Review Status"</b> selector on
                      the Form Submission overlay.
                    </p>
                  </ImageSection>

                  <ImageSection src={form_submissions_signatures}>
                    <p>
                      <b>"Sign-Off Requirements"</b> signatures options will enable the signatures page where the filed-under entity, staff
                      members, and supervisors can add their signatures if required.
                    </p>
                  </ImageSection>

                  <ImageSection src={form_submissions_addendums}>
                    <p>
                      <b>"Allow addendums to be added"</b> option will enable the Addendums page on the Form Submission overlay. Addendums
                      can be added after the Form Submission is signed-off.
                    </p>
                  </ImageSection>
                </div>
              }
            />

            <SettingsSection
              title="PDF Export Settings"
              glyph="file_pdf"
              isApplicable={formSettings.use_pdf_export}
              applicableModel={formatModel('use_pdf_export')}
              notApplicableContent={
                <Alert small contrast glyph="info">
                  PDF Exports are <b>not enabled</b> for this Form and Form Submissions
                </Alert>
              }
              applicableContent={
                <div className="grid gap-6">
                  {isEditable && (
                    <Alert small contrast glyph="info">
                      Select the PDF export options you'd like to enable for this Form and Form Submissions
                    </Alert>
                  )}

                  <CheckboxGroup label="Form Builder" trueIcon="check" falseIcon="cross" falseStyle="faded" layout="vertical-dense">
                    <Checkbox
                      label="Enable Editable Form PDF Export"
                      model={formatModel('pdf_export_settings.readonly_pdf_export')}
                      tooltip="When checked, anyone who has access to this form will be able to download an editable PDF version."
                    />
                    <Checkbox
                      label="Enable Read-Only Form PDF Export"
                      model={formatModel('pdf_export_settings.editable_pdf_export')}
                      tooltip="When checked, anyone who has access to this form will be able to download a readonly PDF version."
                    />
                  </CheckboxGroup>

                  <CheckboxGroup label="Form Submissions" trueIcon="check" falseIcon="cross" falseStyle="faded" layout="vertical-dense">
                    <Checkbox
                      label="Enable Read-Only Form Submissions PDF Export"
                      model={formatModel('pdf_export_settings.form_submission_pdf_export')}
                      tooltip="When checked, anyone who has access to the submissions made using this form will be able to download a readonly PDF version."
                    />
                  </CheckboxGroup>
                </div>
              }
              moreInfo={
                <div className="grid text-[0.9rem] text-text [&_img]:mb-6 [&_p]:mb-2 [&_p]:mt-0">
                  <ImageSection src={form_builder_pdf_export}>
                    <p>
                      <b>"Enable Editable Form PDF Export"</b> and <b>"Enable Read-Only Form PDF Export"</b> options will enable the
                      corresponding actions below on the Form Builder overlay.
                    </p>
                  </ImageSection>

                  <ImageSection src={form_submissions_pdf_export}>
                    <p>
                      <b>"Enable Read-Only Form Submissions PDF Export"</b> will enable PDF export action on the Form Submissions overlay.
                    </p>
                  </ImageSection>
                </div>
              }
            />

            {/* <SettingsSection title="Excel/CSV Export Settings" glyph="excel">
                <div className="grid gap-6">
                  <RadioGroup
                    trueIcon="check"
                    falseIcon="cross"
                    falseStyle="faded"
                    layout="vertical-dense"
                    model={formatModel('excel_csv_export_category')}
                    defaultValue="all"
                  >
                    <Radio
                      label="Export All Columns"
                      value="all"
                      tooltip="When checked, anyone who has access to the submissions made using this form will be able to download an Excel/CSV version of the columns configured below."
                    />
                    <Radio
                      label="Export Custom Columns"
                      value="custom"
                      tooltip="When checked, anyone who has access to the submissions made using this form will be able to download an Excel/CSV version of the columns configured below."
                    />
                  </RadioGroup>

                  <ContextShow when={formatModel('excel_csv_export_category')} is="custom">
                    <div className="px-1">
                      <ExcelExportColumns isPreview />
                      <SummonOverlay overlay={<ExcelExportColumnsOverlay />}>
                        <Button label="Edit Export Columns" glyph="excel" size={200} className="mt-2" />
                      </SummonOverlay>
                    </div>
                  </ContextShow>
                </div>
              </SettingsSection> */}
          </div>
        </Accordions>
      </div>
    </Form>
  )
}

const SettingsSection = (props: any) => {
  const { applicableContent, applicableModel, children, glyph, isApplicable, moreInfo, notApplicableContent, title } = props

  const isEditable: any = useFormBuilder((state: any) => state.isEditable)

  return (
    <Accordions.Item isOpen minimal withBorder size={200} glyph={glyph} title={title}>
      <div className="p-4 grid grid-cols-[100%] overflow-hidden">
        {applicableModel && (
          <>
            <div className={isEditable ? 'mb-4' : 'hidden'}>
              <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="faded">
                <Checkbox label={`Enable ${title}`} size={100} model={applicableModel} value={isApplicable} />
              </CheckboxGroup>
            </div>

            {/* {!isEditable && isApplicable && (
              <Alert small contrast glyph="tick_circle" type="positive" className="mb-3">
                {title} Enabled
              </Alert>
            )} */}
          </>
        )}

        {isApplicable && applicableContent && <div className="pb-2">{applicableContent}</div>}
        {!isApplicable && notApplicableContent && <div className="pb-2">{notApplicableContent}</div>}

        {children}
      </div>

      <div>
        {isEditable && moreInfo && (
          <div className="border-t border-0 border-solid border-divider">
            <TreeItem withHover={false} title={<span className="text-text-muted text-[0.95rem]">More Info</span>}>
              <div className="pb-3 pr-3">{moreInfo}</div>
            </TreeItem>
          </div>
        )}
      </div>
    </Accordions.Item>
  )
}

const ImageContext = React.createContext({})

const ImageSection = ({ src, children }: any) => {
  return (
    <ImageContext.Provider value={{ children }}>
      {children}

      <Zoom ZoomContent={CustomZoomContent} zoomMargin={20}>
        <img src={src} width={580} className="block w-full h-auto shadow-soft-2 rounded-md overflow-hidden" />
      </Zoom>
    </ImageContext.Provider>
  )
}

const CustomZoomContent = ({ buttonUnzoom, modalState, img }: any) => {
  const { children }: any = React.useContext(ImageContext)

  return (
    <>
      {buttonUnzoom}

      {img}

      {children && (
        <div
          className={clsx(
            'absolute bottom-12 left-0 w-full px-5 py-4 flex justify-center opacity-0',
            modalState === 'LOADED'
              ? 'opacity-100 animate-slideInFromBottom'
              : modalState === 'UNLOADING'
              ? 'animate-slideOutToBottom'
              : '',
          )}
        >
          <Card className="px-3 py-2 w-full max-w-[600px] [&_p]:m-0">{children}</Card>
        </div>
      )}
    </>
  )
}
