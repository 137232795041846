import React from 'react'

import { useSettings } from '../../hooks/useSettings'

import PermissionSection from './PermissionSection'
import PermissionSubRow from './PermissionSubRow'

const StaffPermissionsList = ({ model, isManagement }: any) => {
  const _model = model ? model + '.' : ''

  const { isBehave, isSelfServiceBilling } = useSettings()

  return (
    <>
      <PermissionSection
        title="Applications"
        icon="intake_forms"
        featureFlagV2="admissions"
        models={{
          view: `${_model}applications.view`,
          create: `${_model}applications.create`,
          edit: `${_model}applications.edit`,
          delete: `${_model}applications.delete`,
        }}
      />

      <PermissionSection
        title="Activity Timeline"
        icon="intake_forms"
        featureFlagV2="activity_timeline"
        models={{
          view: `${_model}activity_timeline.view`,
        }}
      />

      <PermissionSection
        title="Client Agreements"
        icon="legal_agreement_alt"
        featureFlagV2="client_agreements"
        models={{
          view: `${_model}agreements.view`,
          create: `${_model}agreements.create`,
          edit: `${_model}agreements.edit`,
          delete: `${_model}agreements.delete`,
        }}
      />

      <PermissionSection
        title="Employee Agreements"
        icon="legal_agreement_alt"
        featureFlagV2="staff_agreements"
        models={{
          view: `${_model}employee_agreements.view`,
          create: `${_model}employee_agreements.create`,
          edit: `${_model}employee_agreements.edit`,
          delete: `${_model}employee_agreements.delete`,
        }}
      />

      <PermissionSection
        title="Organization Agreements"
        icon="legal_agreement_alt"
        featureFlagV2="organization_agreements"
        models={{
          view: `${_model}organization_agreements.view`,
          create: `${_model}organization_agreements.create`,
          edit: `${_model}organization_agreements.edit`,
          delete: `${_model}organization_agreements.delete`,
        }}
      />
      
      <PermissionSection
        title="Bed Management"
        icon="bed_management"
        featureFlagV2="bed_management"
        warningTooltip="Enabling this permission for Staff with restricted access to Clients will allow them to view all Clients' bed assignments."
        models={{
          view: `${_model}bed_management.view`,
          create: `${_model}bed_management.create`,
          edit: `${_model}bed_management.edit`,
          delete: `${_model}bed_management.delete`,
        }}
      >
        <PermissionSubRow
          title="Actions"
          actions={[
            {
              label: 'Move Clients',
              model: `${_model}bed_management.actions.move_client`,
            },
          ]}
        />
      </PermissionSection>

      <PermissionSection
        title="Clients"
        icon="clients"
        featureFlagV2="clients"
        models={{
          view: `${_model}clients.view`,
        }}
      >
        <PermissionSubRow
          title="Actions"
          actions={[
            {
              label: 'Add Clients',
              model: `${_model}clients.actions.create`,
            },
            {
              label: 'Export Client Data',
              model: `${_model}clients.actions.export`,
            },
            {
              label: 'Invite Clients to Client Portal',
              model: `${_model}clients.actions.invite_to_client_portal`,
            },
            {
              label: 'Update Client Portal Password',
              model: `${_model}clients.actions.update_client_portal_password`,
            },
          ]}
        />
        <PermissionSubRow
          title="Personal Details"
          models={{
            view: `${_model}clients.general_info.personal_details.view`,
            edit: `${_model}clients.general_info.personal_details.edit`,
          }}
        />
        <PermissionSubRow
          title="Contact Details"
          models={{
            view: `${_model}clients.general_info.contact_details.view`,
            edit: `${_model}clients.general_info.contact_details.edit`,
          }}
        />
        <PermissionSubRow
          title="Recovery"
          models={{
            view: `${_model}clients.general_info.recovery_information.view`,
            edit: `${_model}clients.general_info.recovery_information.edit`,
          }}
        />
        <PermissionSubRow
          title="Referral Details"
          models={{
            view: `${_model}clients.general_info.referral_details.view`,
            edit: `${_model}clients.general_info.referral_details.edit`,
          }}
        />
        <PermissionSubRow
          title="Sensitive Information"
          models={{
            view: `${_model}clients.general_info.sensitive_data.view`,
            edit: `${_model}clients.general_info.sensitive_data.edit`,
          }}
        />
        <PermissionSubRow
          title="Courts & Criminal Justice"
          models={{
            view: `${_model}clients.general_info.courts_criminal_justice.view`,
            edit: `${_model}clients.general_info.courts_criminal_justice.edit`,
          }}
        />
        <PermissionSubRow
          title="Home Address"
          models={{
            view: `${_model}clients.general_info.home_address.view`,
            edit: `${_model}clients.general_info.home_address.edit`,
          }}
        />
        <PermissionSubRow
          title="Vehicle Information"
          models={{
            view: `${_model}clients.general_info.vehicle_information.view`,
            edit: `${_model}clients.general_info.vehicle_information.edit`,
          }}
        />
        <PermissionSubRow
          title="Accounting HIPAA Compliance"
          models={{
            view: `${_model}clients.general_info.accounting_hipaa_compliance.view`,
            edit: `${_model}clients.general_info.accounting_hipaa_compliance.edit`,
          }}
        />
        <PermissionSubRow
          title="Additional Information"
          models={{
            view: `${_model}clients.general_info.other_information.view`,
            edit: `${_model}clients.general_info.other_information.edit`,
          }}
        />
        <PermissionSubRow
          title="Files"
          models={{
            view: `${_model}clients.files.view`,
            create: `${_model}clients.files.create`,
            edit: `${_model}clients.files.edit`,
            delete: `${_model}clients.files.delete`,
          }}
        />
      </PermissionSection>

      <PermissionSection
        title="Client Tracks"
        icon="client_tracks"
        featureFlagV2="client_tracks"
        models={{
          view: `${_model}client_tracks.view`,
          create: `${_model}client_tracks.create`,
          edit: `${_model}client_tracks.edit`,
          delete: `${_model}client_tracks.delete`,
        }}
      />

      <PermissionSection
        title="Client Workflows"
        icon="clients_journey"
        featureFlagV2="client_journey"
        models={{
          view: `${_model}client_journey.view`,
        }}
      >
        <PermissionSubRow
          title="Actions"
          actions={[
            {
              label: 'Accept Client',
              model: `${_model}client_journey.actions.accept`,
            },
            {
              label: 'Admit Client',
              model: `${_model}client_journey.actions.admit`,
            },
            {
              label: 'Decline Client',
              model: `${_model}client_journey.actions.decline`,
            },
            {
              label: 'Discharge Client',
              model: `${_model}client_journey.actions.discharge`,
            },
            {
              label: 'Re-admit Alumni',
              model: `${_model}client_journey.actions.readmit`,
            },
            {
              label: 'Move Alumni to Admissions',
              model: `${_model}client_journey.actions.move_to_admissions`,
            },
            {
              label: 'Delete Alumni',
              model: `${_model}client_journey.actions.delete`,
            },
          ]}
        />
      </PermissionSection>

      <PermissionSection
        title="Clinical Assessments"
        icon="clinical_assessments"
        featureFlagV2="clinical_assessments"
        models={{
          view: `${_model}clinical_assessments.view`,
          create: `${_model}clinical_assessments.create`,
          edit: `${_model}clinical_assessments.edit`,
          delete: `${_model}clinical_assessments.delete`,
        }}
      />

      <PermissionSection
        title="Clinical Notes"
        icon="clinical_notes"
        featureFlagV2="clinical_notes"
        models={{
          view: `${_model}clinical_notes.view`,
          create: `${_model}clinical_notes.create`,
          edit: `${_model}clinical_notes.edit`,
          delete: `${_model}clinical_notes.delete`,
        }}
      />

      <PermissionSection
        title="Clinical Measures"
        icon="clinical_measurements"
        featureFlagV2="clinical_measures"
        models={{
          view: `${_model}clinical_measurements.view`,
          create: `${_model}clinical_measurements.create`,
          edit: `${_model}clinical_measurements.edit`,
          delete: `${_model}clinical_measurements.delete`,
        }}
      />

      <PermissionSection
        title="Diagnoses"
        icon="diagnosis"
        featureFlagV2="diagnoses"
        models={{
          view: `${_model}diagnoses.view`,
          create: `${_model}diagnoses.create`,
          edit: `${_model}diagnoses.edit`,
          delete: `${_model}diagnoses.delete`,
        }}
      />
      <PermissionSection
        title="Outcome Measures"
        icon="outcome_measures"
        featureFlagV2="outcome_measures"
        models={{
          view: `${_model}outcome_measures.view`,
          create: `${_model}outcome_measures.create`,
          edit: `${_model}outcome_measures.edit`,
          delete: `${_model}outcome_measures.delete`,
        }}
      />
      <PermissionSection
        title="Communications"
        icon="communications"
        featureFlagV2="communication_logs"
        models={{
          view: `${_model}communications.view`,
          create: `${_model}communications.create`,
          edit: `${_model}communications.edit`,
          delete: `${_model}communications.delete`,
        }}
      />
      <PermissionSection
        title="Company Files"
        icon="files"
        featureFlagV2="files"
        models={{
          view: `${_model}companies.files.view`,
          create: `${_model}companies.files.create`,
          edit: `${_model}companies.files.edit`,
          delete: `${_model}companies.files.delete`,
        }}
      />
      <PermissionSection
        title="Care Team"
        icon="connections"
        featureFlagV2="care_team"
        models={{
          view: `${_model}connections.view`,
          create: `${_model}connections.create`,
          edit: `${_model}connections.edit`,
          delete: `${_model}connections.delete`,
        }}
      />
      <PermissionSection
        title="Contacts"
        icon="contacts"
        featureFlagV2="contacts"
        models={{
          view: `${_model}contacts.view`,
          create: `${_model}contacts.create`,
          edit: `${_model}contacts.edit`,
          delete: `${_model}contacts.delete`,
        }}
      />

      <PermissionSection
        title="Staff Credentials"
        icon="staff_credentials"
        featureFlagV2="staff_credentials"
        models={{
          view: `${_model}credentials.view`,
          create: `${_model}credentials.create`,
          edit: `${_model}credentials.edit`,
          delete: `${_model}credentials.delete`,
        }}
      />

      <PermissionSection
        title="Staff Chat"
        icon="company_chat"
        featureFlagV2="staff_chat"
        models={{
          view: `${_model}company_chat.view`,
          create: `${_model}company_chat.create`,
          edit: `${_model}company_chat.edit`,
        }}
      >
        <PermissionSubRow
          title="Message Blasts"
          models={{
            view: `${_model}company_chat_message_blasts.view`,
            create: `${_model}company_chat_message_blasts.create`,
          }}
        />
      </PermissionSection>

      <PermissionSection
        title="Clients Chat"
        icon="clients_chat"
        featureFlagV2="clients_chat"
        models={{
          view: `${_model}clients_chat.view`,
          create: `${_model}clients_chat.create`,
          edit: `${_model}clients_chat.edit`,
        }}
      >
        <PermissionSubRow
          title="Actions"
          actions={[
            {
              label: 'Edit Access List',
              model: `${_model}clients_chat.actions.access_list.edit`,
            },
          ]}
        />
        <PermissionSubRow
          title="Message Blasts"
          models={{
            view: `${_model}clients_chat_message_blasts.view`,
            create: `${_model}clients_chat_message_blasts.create`,
          }}
        />
      </PermissionSection>

      <PermissionSection title="Custom Notes" icon="clinical_assessments">
        <PermissionSubRow
          title="Client Custom Notes"
          featureFlagV2="client_custom_notes"
          models={{
            view: `${_model}client_custom_notes.view`,
            create: `${_model}client_custom_notes.create`,
            edit: `${_model}client_custom_notes.edit`,
            delete: `${_model}client_custom_notes.delete`,
          }}
        />
        <PermissionSubRow
          title="Staff Custom Notes"
          featureFlagV2="staff_custom_notes"
          models={{
            view: `${_model}staff_custom_notes.view`,
            create: `${_model}staff_custom_notes.create`,
            edit: `${_model}staff_custom_notes.edit`,
            delete: `${_model}staff_custom_notes.delete`,
          }}
        />
        <PermissionSubRow
          title="Location Custom Notes"
          featureFlagV2="property_custom_notes"
          models={{
            view: `${_model}property_custom_notes.view`,
            create: `${_model}property_custom_notes.create`,
            edit: `${_model}property_custom_notes.edit`,
            delete: `${_model}property_custom_notes.delete`,
          }}
        />
        <PermissionSubRow
          title="Organization Custom Notes"
          featureFlagV2="organization_custom_notes"
          models={{
            view: `${_model}organization_custom_notes.view`,
            create: `${_model}organization_custom_notes.create`,
            edit: `${_model}organization_custom_notes.edit`,
            delete: `${_model}organization_custom_notes.delete`,
          }}
        />
        <PermissionSubRow
          title="Program Custom Notes"
          featureFlagV2="program_custom_notes"
          models={{
            view: `${_model}program_custom_notes.view`,
            create: `${_model}program_custom_notes.create`,
            edit: `${_model}program_custom_notes.edit`,
            delete: `${_model}program_custom_notes.delete`,
          }}
        />
      </PermissionSection>

      <PermissionSection
        title="Discharges"
        icon="discharge_forms"
        featureFlagV2="discharges"
        models={{
          view: `${_model}discharges.view`,
          create: `${_model}discharges.create`,
          edit: `${_model}discharges.edit`,
          delete: `${_model}discharges.delete`,
        }}
      />

      <PermissionSection
        title="User Invites"
        icon="communications"
        models={{
          view: `${_model}user_invites.view`,
        }}
      >
        <PermissionSubRow
          title="Actions"
          actions={[
            {
              label: 'Re-send Invite',
              model: `${_model}user_invites.actions.resend_invite`,
            },
            {
              label: 'Revoke Invite',
              model: `${_model}user_invites.actions.revoke_invite`,
            },
            {
              label: 'Delete Invite',
              model: `${_model}user_invites.actions.delete_invite`,
            },
          ]}
        />
      </PermissionSection>

      <PermissionSection
        title="Staff"
        icon="employees"
        featureFlagV2="staff"
        models={{
          view: `${_model}employees.view`,
          // edit: `${_model}employees.edit`,
        }}
      >
        <PermissionSubRow
          title="Actions"
          warningTooltip={
            <span>
              Enabling <b>Invite New Staff</b> will allow Staff to Invite new Staff members to your Account. Please make sure you also check
              that the <b>Staff Permissions</b> permission below follows your intended use.
            </span>
          }
          actions={[
            {
              label: 'Invite New Staff',
              model: `${_model}employees.actions.invite`,
            },
            {
              label: 'Block / Unblock Staff',
              model: `${_model}employees.actions.block`,
            },
            {
              label: 'Archive / Activate Staff',
              model: `${_model}employees.actions.archive`,
            },
            {
              label: 'Delete Staff',
              model: `${_model}employees.actions.delete`,
            },
          ]}
        />
        <PermissionSubRow
          title="Staff Permissions"
          warningTooltip="Please note: enabling this permission for Staff will allow them to edit their own permissions as well as other Staff's permissions."
          models={{
            view: `${_model}employees.permissions.view`,
            edit: `${_model}employees.permissions.edit`,
          }}
        />
        <PermissionSubRow
          title="Personal Details"
          models={{
            view: `${_model}employees.general_info.personal_details.view`,
            edit: `${_model}employees.general_info.personal_details.edit`,
          }}
        />
        <PermissionSubRow
          title="Employment Information"
          models={{
            view: `${_model}employees.general_info.employment_information.view`,
            edit: `${_model}employees.general_info.employment_information.edit`,
          }}
        />
        <PermissionSubRow
          title="Contact Details"
          models={{
            view: `${_model}employees.general_info.contact_details.view`,
            edit: `${_model}employees.general_info.contact_details.edit`,
          }}
        />
        <PermissionSubRow
          title="Sensitive Information"
          models={{
            view: `${_model}employees.general_info.sensitive_data.view`,
            edit: `${_model}employees.general_info.sensitive_data.edit`,
          }}
        />
        <PermissionSubRow
          title="Home Address"
          models={{
            view: `${_model}employees.general_info.home_address.view`,
            edit: `${_model}employees.general_info.home_address.edit`,
          }}
        />
        <PermissionSubRow
          title="Client Portal Details"
          models={{
            view: `${_model}employees.general_info.client_portal_details.view`,
            edit: `${_model}employees.general_info.client_portal_details.edit`,
          }}
        />
        <PermissionSubRow
          title="Additional Information"
          models={{
            view: `${_model}employees.general_info.other_information.view`,
            edit: `${_model}employees.general_info.other_information.edit`,
          }}
        />
        <PermissionSubRow
          title="Files"
          models={{
            view: `${_model}employees.files.view`,
            create: `${_model}employees.files.create`,
            edit: `${_model}employees.files.edit`,
            delete: `${_model}employees.files.delete`,
          }}
        />
      </PermissionSection>

      <PermissionSection
        title="Ledger"
        icon="financials"
        featureFlagV2="financials"
        models={{
          view: `${_model}ledger.view`,
          create: `${_model}ledger.create`,
          edit: `${_model}ledger.edit`,
          delete: `${_model}ledger.delete`,
        }}
      >
        <PermissionSubRow
          title="Actions"
          actions={[
            {
              label: 'Make Payments',
              model: `${_model}ledger.actions.make_payments`,
            },
          ]}
        />
      </PermissionSection>

      <PermissionSection
        title="Payment Methods"
        icon="financials"
        featureFlagV2="financials"
        models={{
          view: `${_model}payment_methods.view`,
          create: `${_model}payment_methods.create`,
          edit: `${_model}payment_methods.edit`,
          delete: `${_model}payment_methods.delete`,
        }}
      >
        <PermissionSubRow
          title="Actions"
          actions={[
            {
              label: 'Collect a Payment',
              model: `${_model}payment_methods.actions.collect_a_payment`,
            },
          ]}
        />
      </PermissionSection>

      {/* <PermissionSection
                  title="Billing Rosters"
                  icon="roster"
                  featureFlag={false}
                  models={{
                    view: `${_model}rosters.view`,
                    create: `${_model}rosters.create`,
                    edit: `${_model}rosters.edit`,
                    delete: `${_model}rosters.delete`,
                  }}
                />

                <PermissionSection
                  title="Claims"
                  icon="invoices"
                  featureFlag={false}
                  models={{
                    view: `${_model}claims.view`,
                    create: `${_model}claims.create`,
                    edit: `${_model}claims.edit`,
                    delete: `${_model}claims.delete`,
                  }}
                />

                <PermissionSection
                  title="Insurance Charges"
                  icon="insurance"
                  featureFlag={false}
                  models={{
                    view: `${_model}charges.view`,
                    create: `${_model}charges.create`,
                    edit: `${_model}charges.edit`,
                    delete: `${_model}charges.delete`,
                  }}
                /> */}

      <PermissionSection
        title="Location Check-In"
        icon="recovery_navigation"
        featureFlagV2="location_gps_check_in"
        models={{
          view: `${_model}location_checkin.view`,
          create: `${_model}location_checkin.create`,
          edit: `${_model}location_checkin.edit`,
        }}
      />

      <PermissionSection
        title="Insurance Policies"
        icon="insurance"
        featureFlagV2="insurance_policies"
        models={{
          view: `${_model}insurance_policies.view`,
          create: `${_model}insurance_policies.create`,
          edit: `${_model}insurance_policies.edit`,
          delete: `${_model}insurance_policies.delete`,
        }}
      >
        <PermissionSubRow
          title="Billing"
          models={{
            view: `${_model}clients.general_info.billing.view`,
            edit: `${_model}clients.general_info.billing.edit`,
          }}
        />
      </PermissionSection>

      <PermissionSection
        title="Insurance Payers"
        icon="insurance"
        featureFlagV2="insurance_payers"
        models={{
          view: `${_model}insurance_local_payers.view`,
          create: `${_model}insurance_local_payers.create`,
        }}
      >
        <PermissionSubRow
          title="Actions"
          actions={[
            {
              label: 'Import Payer',
              model: `${_model}insurance_local_payers.actions.import`,
            },
          ]}
        />
        <PermissionSubRow
          title="Custom Details"
          models={{
            view: `${_model}insurance_local_payers.general_info.custom_details.view`,
            edit: `${_model}insurance_local_payers.general_info.custom_details.edit`,
          }}
        />
        <PermissionSubRow
          title="Payer Details"
          models={{
            view: `${_model}insurance_local_payers.general_info.payer_details.view`,
          }}
        />
        <PermissionSubRow
          title="Claim Submission Address"
          models={{
            view: `${_model}insurance_local_payers.general_info.claim_submission_address.view`,
            edit: `${_model}insurance_local_payers.general_info.claim_submission_address.edit`,
          }}
        />
        <PermissionSubRow
          title="Connected Clearing House Details"
          models={{
            view: `${_model}insurance_local_payers.general_info.connected_clearing_house_details.view`,
          }}
        />

        <PermissionSubRow
          title="Files"
          models={{
            view: `${_model}insurance_local_payers.files.view`,
            create: `${_model}insurance_local_payers.files.create`,
            edit: `${_model}insurance_local_payers.files.edit`,
            delete: `${_model}insurance_local_payers.files.delete`,
          }}
        />

        <PermissionSubRow
          title="Clients"
          models={{
            view: `${_model}insurance_local_payers.clients.view`,
          }}
        />
      </PermissionSection>

      <PermissionSection
        title="Insurance EVOBs"
        icon="insurance"
        featureFlagV2="evobs"
        models={{
          view: `${_model}insurance_evobs.view`,
          create: `${_model}insurance_evobs.create`,
          edit: `${_model}insurance_evobs.edit`,
          delete: `${_model}insurance_evobs.delete`,
        }}
      />

      <PermissionSection
        title="Insurance Roster"
        icon="insurance"
        featureFlagV2="insurance_claims"
        models={{
          view: `${_model}insurance_roster.view`,
          edit: `${_model}insurance_roster.edit`,
          delete: `${_model}insurance_roster.delete`,
        }}
      />

      <PermissionSection
        title="Insurance Claims"
        icon="insurance"
        featureFlagV2="insurance_claims"
        models={{
          view: `${_model}insurance_claims.view`,
          create: `${_model}insurance_claims.create`,
          edit: `${_model}insurance_claims.edit`,
          delete: `${_model}insurance_claims.delete`,
        }}
      />

      <PermissionSection
        title="Insurance Claim Templates"
        icon="accounting"
        featureFlagV2="insurance_claims"
        models={{
          view: `${_model}insurance_claim_templates.view`,
          create: `${_model}insurance_claim_templates.create`,
          edit: `${_model}insurance_claim_templates.edit`,
          delete: `${_model}insurance_claim_templates.delete`,
        }}
      />

      <PermissionSection
        title="Insurance Posting"
        icon="insurance"
        featureFlagV2="insurance_posting"
        models={{
          view: `${_model}insurance_posting.view`,
          create: `${_model}insurance_posting.create`,
          edit: `${_model}insurance_posting.edit`,
          delete: `${_model}insurance_posting.delete`,
        }}
      />

      <PermissionSection
        title="Insurance Invoicing"
        icon="insurance"
        featureFlagV2="insurance_invoicing"
        models={{
          view: `${_model}insurance_invoicing.view`,
          create: `${_model}insurance_invoicing.create`,
          edit: `${_model}insurance_invoicing.edit`,
          delete: `${_model}insurance_invoicing.delete`,
        }}
      />

      <PermissionSection
        title="Insurance Clearinghouse"
        icon="insurance"
        featureFlagV2="insurance_clearinghouse"
        models={{
          view: `${_model}insurance_clearinghouse.view`,
          create: `${_model}insurance_clearinghouse.create`,
          edit: `${_model}insurance_clearinghouse.edit`,
          delete: `${_model}insurance_clearinghouse.delete`,
        }}
      />

      {(isBehave || isSelfServiceBilling) && (
        <>
          <PermissionSection
            title="VOBs"
            icon="insurance"
            featureFlagV2={isBehave || 'full_vobs'}
            models={{
              view: `${_model}full_vobs.view`,
              create: `${_model}full_vobs.create`,
              edit: `${_model}full_vobs.edit`,
              delete: `${_model}full_vobs.delete`,
            }}
          />

          <PermissionSection
            title="Authorizations"
            icon="authorizations"
            featureFlagV2={isBehave || 'insurance_authorizations'}
            models={{
              view: `${_model}insurance_authorizations.view`,
              create: `${_model}insurance_authorizations.create`,
              edit: `${_model}insurance_authorizations.edit`,
              delete: `${_model}insurance_authorizations.delete`,
            }}
          />

          <PermissionSection
            title="Service Episodes"
            icon="service_episodes"
            featureFlagV2={isBehave || 'service_episodes'}
            models={{
              view: `${_model}service_episodes.view`,
              create: `${_model}service_episodes.create`,
              edit: `${_model}service_episodes.edit`,
              delete: `${_model}service_episodes.delete`,
            }}
          />

          <PermissionSection
            title="RCM"
            icon="financials"
            featureFlagV2={isBehave || 'rcm'}
            models={{
              view: `${_model}rcm.view`,
              create: `${_model}rcm.create`,
              edit: `${_model}rcm.edit`,
              delete: `${_model}rcm.delete`,
            }}
          />
        </>
      )}

      <PermissionSection
        title="Medical Assessments"
        icon="medical_assessments"
        featureFlagV2="medical_assessments"
        models={{
          view: `${_model}medical_assessments.view`,
          create: `${_model}medical_assessments.create`,
          edit: `${_model}medical_assessments.edit`,
          delete: `${_model}medical_assessments.delete`,
        }}
      />

      <PermissionSection
        title="Medications"
        icon="medications"
        featureFlagV2="medications"
        models={{
          view: `${_model}medications.view`,
          create: `${_model}medications.create`,
          edit: `${_model}medications.edit`,
          delete: `${_model}medications.delete`,
        }}
      />

      <PermissionSection
        title="Medication Logs"
        icon="med_pass"
        featureFlagV2="medication_logs"
        models={{
          view: `${_model}medication_logs.view`,
          create: `${_model}medication_logs.create`,
          edit: `${_model}medication_logs.edit`,
          delete: `${_model}medication_logs.delete`,
        }}
      />

      <PermissionSection
        title="Meds Pass"
        icon="med_pass"
        featureFlagV2="med_pass"
        warningTooltip="Enabling this permission for Staff with restricted access to Clients will allow them to view all Clients' medications and medication logs."
        models={{
          view: `${_model}med_pass.view`,
          create: `${_model}med_pass.create`,
        }}
      />

      <PermissionSection
        title="Medication Destruction Logs"
        icon="medication_inventory"
        featureFlagV2="medication_destruction_logs"
        models={{
          view: `${_model}medication_destruction_logs.view`,
          create: `${_model}medication_destruction_logs.create`,
          edit: `${_model}medication_destruction_logs.edit`,
          delete: `${_model}medication_destruction_logs.delete`,
        }}
      />

      <PermissionSection
        title="Medication Incident Logs"
        icon="medication_incidents"
        featureFlagV2="medication_incident_logs"
        models={{
          view: `${_model}medication_incident_logs.view`,
          create: `${_model}medication_incident_logs.create`,
          edit: `${_model}medication_incident_logs.edit`,
          delete: `${_model}medication_incident_logs.delete`,
        }}
      />

      <PermissionSection
        title="Message Boards"
        icon="checklist"
        featureFlagV2="message_board"
        models={{
          view: `${_model}messages.view`,
          create: `${_model}messages.create`,
          edit: `${_model}messages.edit`,
          delete: `${_model}messages.delete`,
        }}
      />

      <PermissionSection
        title="Nursing Forms"
        icon="nursing"
        featureFlagV2="nursing"
        models={{
          view: `${_model}nursing.view`,
          create: `${_model}nursing.create`,
          edit: `${_model}nursing.edit`,
          delete: `${_model}nursing.delete`,
        }}
      />

      <PermissionSection
        title="Organizations"
        icon="organizations"
        featureFlagV2="organizations"
        models={{
          view: `${_model}organizations.view`,
        }}
      >
        <PermissionSubRow
          title="Actions"
          actions={[
            {
              label: 'Add Organizations',
              model: `${_model}organizations.actions.create`,
            },
          ]}
        />
        <PermissionSubRow
          title="Organization Details"
          models={{
            view: `${_model}organizations.general_info.organization_details.view`,
            edit: `${_model}organizations.general_info.organization_details.edit`,
          }}
        />
        <PermissionSubRow
          title="Contact Details"
          models={{
            view: `${_model}organizations.general_info.contact_details.view`,
            edit: `${_model}organizations.general_info.contact_details.edit`,
          }}
        />
        <PermissionSubRow
          title="Organization Address"
          models={{
            view: `${_model}organizations.general_info.address.view`,
            edit: `${_model}organizations.general_info.address.edit`,
          }}
        />
        <PermissionSubRow
          title="Client Portal Details"
          models={{
            view: `${_model}organizations.general_info.client_portal_details.view`,
            edit: `${_model}organizations.general_info.client_portal_details.edit`,
          }}
        />
        <PermissionSubRow
          title="Notes"
          models={{
            view: `${_model}organizations.general_info.notes.view`,
            edit: `${_model}organizations.general_info.notes.edit`,
          }}
        />
        <PermissionSubRow
          title="Files"
          models={{
            view: `${_model}organizations.files.view`,
            create: `${_model}organizations.files.create`,
            edit: `${_model}organizations.files.edit`,
            delete: `${_model}organizations.files.delete`,
          }}
        />
      </PermissionSection>

      <PermissionSection
        title="Community Invites"
        icon="community_invites"
        featureFlagV2="organizations"
        models={{
          view: `${_model}community_invites.view`,
        }}
      />

      <PermissionSection
        title="Community Profiles"
        icon="community_profile"
        featureFlagV2="organizations"
        models={{
          view: `${_model}community_profiles.view`,
          edit: `${_model}community_profiles.edit`,
        }}
      />

      <PermissionSection
        title="Physician Orders"
        icon="physician_orders"
        featureFlagV2="physician_orders"
        models={{
          view: `${_model}physician_orders.view`,
          create: `${_model}physician_orders.create`,
          edit: `${_model}physician_orders.edit`,
          delete: `${_model}physician_orders.delete`,
        }}
      />

      <PermissionSection
        title="Progress Notes"
        icon="progress_reviews"
        featureFlagV2="progress_notes"
        models={{
          view: `${_model}progress_notes.view`,
          create: `${_model}progress_notes.create`,
          edit: `${_model}progress_notes.edit`,
          delete: `${_model}progress_notes.delete`,
        }}
      />

      <PermissionSection
        title="Programs"
        warningTooltip="Enabling this permission for Staff with restricted access to Clients will allow them to view all Clients who are enrolled in Programs."
        icon="checklist"
        models={{
          view: `${_model}programs.view`,
          create: `${_model}programs.create`,
          edit: `${_model}programs.edit`,
          delete: `${_model}programs.delete`,
        }}
      />

      <PermissionSection
        title="Program Assignment"
        icon="program_lists"
        featureFlagV2="programs"
        models={{
          view: `${_model}program_assignment.view`,
          create: `${_model}program_assignment.create`,
          edit: `${_model}program_assignment.edit`,
          delete: `${_model}program_assignment.delete`,
        }}
      />

      <PermissionSection
        title="Progress Reviews"
        icon="progress_reviews"
        featureFlagV2="progress_reviews"
        models={{
          view: `${_model}progress_reviews.view`,
          create: `${_model}progress_reviews.create`,
          edit: `${_model}progress_reviews.edit`,
          delete: `${_model}progress_reviews.delete`,
        }}
      />

      <PermissionSection
        title="Locations"
        icon="properties"
        featureFlagV2="properties"
        models={{
          view: `${_model}properties.view`,
        }}
      >
        <PermissionSubRow
          title="Actions"
          actions={[
            {
              label: 'Add Locations',
              model: `${_model}properties.actions.create`,
            },
            {
              label: 'Delete Locations',
              model: `${_model}properties.actions.delete`,
            },
          ]}
        />
        <PermissionSubRow
          title="Location Details"
          models={{
            view: `${_model}properties.general_info.property_details.view`,
            edit: `${_model}properties.general_info.property_details.edit`,
          }}
        />
        <PermissionSubRow
          title="Healthcare Details"
          models={{
            view: `${_model}properties.general_info.healthcare_details.view`,
            edit: `${_model}properties.general_info.healthcare_details.edit`,
          }}
        />
        <PermissionSubRow
          title="Contact Details"
          models={{
            view: `${_model}properties.general_info.contact_details.view`,
            edit: `${_model}properties.general_info.contact_details.edit`,
          }}
        />
        <PermissionSubRow
          title="Location Address"
          models={{
            view: `${_model}properties.general_info.address.view`,
            edit: `${_model}properties.general_info.address.edit`,
          }}
        />
        <PermissionSubRow
          title="Client Portal Details"
          models={{
            view: `${_model}properties.general_info.client_portal_details.view`,
            edit: `${_model}properties.general_info.client_portal_details.edit`,
          }}
        />
        <PermissionSubRow
          title="Additional Information"
          models={{
            view: `${_model}properties.general_info.other_information.view`,
            edit: `${_model}properties.general_info.other_information.edit`,
          }}
        />
        <PermissionSubRow
          title="Files"
          models={{
            view: `${_model}properties.files.view`,
            create: `${_model}properties.files.create`,
            edit: `${_model}properties.files.edit`,
            delete: `${_model}properties.files.delete`,
          }}
        />
      </PermissionSection>

      <PermissionSection
        title="RCM Reports"
        icon="patient_progress_measure"
        featureFlagV2="rcm"
        models={{
          view: `${_model}rcm_reports.view`,
        }}
      />

      <PermissionSection
        title="Recovery Notes"
        icon="recovery_coaching"
        featureFlagV2="recovery_coaching"
        models={{
          view: `${_model}recovery_coaching.view`,
          create: `${_model}recovery_coaching.create`,
          edit: `${_model}recovery_coaching.edit`,
          delete: `${_model}recovery_coaching.delete`,
        }}
      />

      <PermissionSection
        title="Live Reports"
        icon="patient_progress_measure"
        warningTooltip="Enabling this permission for Staff with restricted access to Clients will allow them to view all Clients' reports."
        featureFlagV2="live_reports"
        models={{
          view: `${_model}reports.view`,
        }}
      />

      <PermissionSection
        title="Generated Reports"
        warningTooltip="Enabling this permission for Staff with restricted access to Clients will allow them to view all Clients' reports."
        icon="patient_progress_measure"
        featureFlagV2="excel_reports"
        models={{
          view: `${_model}generated_reports.view`,
          create: `${_model}generated_reports.create`,
          edit: `${_model}generated_reports.edit`,
          delete: `${_model}generated_reports.delete`,
        }}
      />

      <PermissionSection title="Settings" icon="settings">
        <PermissionSubRow
          title="Company Credentials"
          featureFlagV2="company_credentials"
          models={{
            view: `${_model}settings.credentials.view`,
            create: `${_model}settings.credentials.create`,
            edit: `${_model}settings.credentials.edit`,
            delete: `${_model}settings.credentials.delete`,
          }}
        />

        <PermissionSubRow
          title="Application Forms"
          featureFlagV2="custom_application_form"
          models={{
            view: `${_model}settings.application_form.view`,
            edit: `${_model}settings.application_form.edit`,
          }}
        />

        <PermissionSubRow
          title="Embeds"
          featureFlagV2="admissions"
          models={{
            view: `${_model}settings.embeds.view`,
          }}
        />

        <PermissionSubRow
          title="Client Workflow Settings"
          featureFlagV2="client_workflows"
          models={{
            view: `${_model}settings.client_workflows.view`,
            edit: `${_model}settings.client_workflows.edit`,
          }}
        />

        <PermissionSubRow
          title="Client Workflows Message Templates"
          featureFlagV2="client_workflows_message_templates"
          models={{
            view: `${_model}settings.client_workflows_message_templates.view`,
            create: `${_model}settings.client_workflows_message_templates.create`,
            edit: `${_model}settings.client_workflows_message_templates.edit`,
            delete: `${_model}settings.client_workflows_message_templates.delete`,
          }}
        />

        <PermissionSubRow
          title="Measure Settings"
          featureFlagV2="outcome_measures"
          models={{
            view: `${_model}settings.form_settings.view`,
            edit: `${_model}settings.form_settings.edit`,
          }}
        />

        <PermissionSubRow
          title="Short Links"
          featureFlagV2="short_links"
          models={{
            view: `${_model}settings.short_links.view`,
            create: `${_model}settings.short_links.create`,
            edit: `${_model}settings.short_links.edit`,
            delete: `${_model}settings.short_links.delete`,
          }}
        />

        <PermissionSubRow
          title="Bookmarks Manager"
          featureFlagV2="bookmarks"
          models={{
            view: `${_model}settings.bookmarks_manager.view`,
            create: `${_model}settings.bookmarks_manager.create`,
            edit: `${_model}settings.bookmarks_manager.edit`,
            delete: `${_model}settings.bookmarks_manager.delete`,
          }}
        />

        <PermissionSubRow
          title="Quick Text"
          featureFlagV2="quick_text"
          models={{
            view: `${_model}settings.quick_text.view`,
            create: `${_model}settings.quick_text.create`,
            edit: `${_model}settings.quick_text.edit`,
            delete: `${_model}settings.quick_text.delete`,
          }}
        />

        <PermissionSubRow
          title="Client Agreement Templates"
          featureFlagV2="client_agreement_templates"
          models={{
            view: `${_model}settings.agreement_templates.view`,
            create: `${_model}settings.agreement_templates.create`,
            edit: `${_model}settings.agreement_templates.edit`,
            delete: `${_model}settings.agreement_templates.delete`,
          }}
        />

        <PermissionSubRow
          title="Employee Agreement Templates"
          featureFlagV2="staff_agreement_templates"
          models={{
            view: `${_model}settings.employee_agreement_templates.view`,
            create: `${_model}settings.employee_agreement_templates.create`,
            edit: `${_model}settings.employee_agreement_templates.edit`,
            delete: `${_model}settings.employee_agreement_templates.delete`,
          }}
        />

        <PermissionSubRow
          title="Organization Agreement Templates"
          featureFlagV2="organization_agreement_templates"
          models={{
            view: `${_model}settings.organization_agreement_templates.view`,
            create: `${_model}settings.organization_agreement_templates.create`,
            edit: `${_model}settings.organization_agreement_templates.edit`,
            delete: `${_model}settings.organization_agreement_templates.delete`,
          }}
        />

        <PermissionSubRow
          title="Client Custom Note Templates"
          featureFlagV2="client_custom_note_templates"
          models={{
            view: `${_model}settings.client_custom_note_templates.view`,
            create: `${_model}settings.client_custom_note_templates.create`,
            edit: `${_model}settings.client_custom_note_templates.edit`,
            delete: `${_model}settings.client_custom_note_templates.delete`,
          }}
        />

        <PermissionSubRow
          title="Staff Custom Note Templates"
          featureFlagV2="staff_custom_note_templates"
          models={{
            view: `${_model}settings.staff_custom_note_templates.view`,
            create: `${_model}settings.staff_custom_note_templates.create`,
            edit: `${_model}settings.staff_custom_note_templates.edit`,
            delete: `${_model}settings.staff_custom_note_templates.delete`,
          }}
        />

        <PermissionSubRow
          title="Location Custom Note Templates"
          featureFlagV2="property_custom_note_templates"
          models={{
            view: `${_model}settings.property_custom_note_templates.view`,
            create: `${_model}settings.property_custom_note_templates.create`,
            edit: `${_model}settings.property_custom_note_templates.edit`,
            delete: `${_model}settings.property_custom_note_templates.delete`,
          }}
        />

        <PermissionSubRow
          title="Organization Custom Note Templates"
          featureFlagV2="organization_custom_note_templates"
          models={{
            view: `${_model}settings.organization_custom_note_templates.view`,
            create: `${_model}settings.organization_custom_note_templates.create`,
            edit: `${_model}settings.organization_custom_note_templates.edit`,
            delete: `${_model}settings.organization_custom_note_templates.delete`,
          }}
        />

        <PermissionSubRow
          title="Program Custom Note Templates"
          featureFlagV2="program_custom_note_templates"
          models={{
            view: `${_model}settings.program_custom_note_templates.view`,
            create: `${_model}settings.program_custom_note_templates.create`,
            edit: `${_model}settings.program_custom_note_templates.edit`,
            delete: `${_model}settings.program_custom_note_templates.delete`,
          }}
        />

        <PermissionSubRow
          title="Grievance Custom Note Templates"
          featureFlagV2="grievance_note_templates"
          models={{
            view: `${_model}settings.grievance_custom_note_templates.view`,
            create: `${_model}settings.grievance_custom_note_templates.create`,
            edit: `${_model}settings.grievance_custom_note_templates.edit`,
            delete: `${_model}settings.grievance_custom_note_templates.delete`,
          }}
        />

        <PermissionSubRow
          title="Community Invite Templates"
          models={{
            view: `${_model}settings.community_invite_templates.view`,
            create: `${_model}settings.community_invite_templates.create`,
            edit: `${_model}settings.community_invite_templates.edit`,
            delete: `${_model}settings.community_invite_templates.delete`,
          }}
        />

        <PermissionSubRow
          title="Services Book"
          featureFlagV2="services_book"
          models={{
            view: `${_model}settings.services_book.view`,
            create: `${_model}settings.services_book.create`,
            edit: `${_model}settings.services_book.edit`,
          }}
        />

        <PermissionSubRow
          title="Treatment Plan Templates (Legacy)"
          featureFlagV2="treatment_plan_templates"
          models={{
            view: `${_model}settings.treatment_plan_templates.view`,
            edit: `${_model}settings.treatment_plan_templates.edit`,
          }}
        />

        <PermissionSubRow
          title="Company Providers"
          models={{
            view: `${_model}settings.company_providers.view`,
            create: `${_model}settings.company_providers.create`,
            edit: `${_model}settings.company_providers.edit`,
            delete: `${_model}settings.company_providers.delete`,
          }}
        />

        <PermissionSubRow
          title="Out of Network Services"
          models={{
            view: `${_model}settings.out_of_network_services.view`,
            create: `${_model}settings.out_of_network_services.create`,
            edit: `${_model}settings.out_of_network_services.edit`,
            delete: `${_model}settings.out_of_network_services.delete`,
          }}
        />

        <PermissionSubRow
          title="Insurance Providers"
          featureFlagV2="insurance_services"
          models={{
            view: `${_model}settings.insurance_providers.view`,
            create: `${_model}settings.insurance_providers.create`,
            edit: `${_model}settings.insurance_providers.edit`,
            delete: `${_model}settings.insurance_providers.delete`,
          }}
        />

        <PermissionSubRow
          title="Insurance Codes"
          featureFlagV2="insurance_services"
          models={{
            view: `${_model}settings.insurance_codes.view`,
            create: `${_model}settings.insurance_codes.create`,
            edit: `${_model}settings.insurance_codes.edit`,
            delete: `${_model}settings.insurance_codes.delete`,
          }}
        />

        {(isBehave || isSelfServiceBilling) && (
          <PermissionSubRow
            title="Fee Schedules"
            featureFlagV2="fee_schedules"
            models={{
              view: `${_model}settings.fee_schedules.view`,
              create: `${_model}settings.fee_schedules.create`,
              edit: `${_model}settings.fee_schedules.edit`,
            }}
          />
        )}
        <PermissionSubRow
          title="Permission Templates"
          featureFlagV2="permission_templates"
          models={{
            view: `${_model}settings.permissions.view`,
            create: `${_model}settings.permissions.create`,
            edit: `${_model}settings.permissions.edit`,
            delete: `${_model}settings.permissions.delete`,
          }}
        />
        <PermissionSubRow
          title="Client Portal Permissions"
          featureFlagV2="client_portal_permissions"
          models={{
            view: `${_model}settings.client_portal_permissions.view`,
            edit: `${_model}settings.client_portal_permissions.edit`,
          }}
        />
        <PermissionSubRow
          title="Online Payments"
          featureFlagV2="online_payments_collection_with_stripe"
          models={{
            view: `${_model}settings.online_payments.view`,
            edit: `${_model}settings.online_payments.edit`,
          }}
        />
        <PermissionSubRow
          title="Accounting"
          featureFlagV2="accounting"
          models={{
            view: `${_model}settings.accounting.view`,
            edit: `${_model}settings.accounting.edit`,
          }}
        />
        <PermissionSubRow
          title="Legal Agreement"
          models={{
            view: `${_model}settings.legal_agreement.view`,
          }}
        />
      </PermissionSection>

      <PermissionSection
        title="Company Settings"
        icon="organizations_providers"
        models={{
          view: `${_model}settings.company_profile.company_settings.view`,
        }}
      >
        <PermissionSubRow
          title="Company Details"
          models={{
            view: `${_model}settings.company_profile.company_details.view`,
            edit: `${_model}settings.company_profile.company_details.edit`,
          }}
        />
        <PermissionSubRow
          title="Notifications"
          models={{
            view: `${_model}settings.company_profile.notifications.view`,
            edit: `${_model}settings.company_profile.notifications.edit`,
          }}
        />
        <PermissionSubRow
          title="Billing Address"
          models={{
            view: `${_model}settings.company_profile.billing_address.view`,
            edit: `${_model}settings.company_profile.billing_address.edit`,
          }}
        />
        <PermissionSubRow
          title="Mailing Address"
          models={{
            view: `${_model}settings.company_profile.mailing_address.view`,
            edit: `${_model}settings.company_profile.mailing_address.edit`,
          }}
        />
        <PermissionSubRow
          title="Clinical Notes Type"
          models={{
            view: `${_model}settings.company_profile.clinical_notes_type.view`,
            edit: `${_model}settings.company_profile.clinical_notes_type.edit`,
          }}
        />
        <PermissionSubRow
          title="Bed Management"
          models={{
            view: `${_model}settings.company_profile.bed_management.view`,
            edit: `${_model}settings.company_profile.bed_management.edit`,
          }}
        />
        <PermissionSubRow
          title="Exports"
          models={{
            view: `${_model}settings.company_profile.exports.view`,
            edit: `${_model}settings.company_profile.exports.edit`,
          }}
        />
      </PermissionSection>

      <PermissionSection
        title="Notifications Settings"
        icon="notifications"
        models={{
          view: `${_model}settings.company_profile.notifications_settings.view`,
        }}
      >
        <PermissionSubRow
          title="Account Owner"
          models={{
            view: `${_model}settings.company_profile.account_owner.view`,
            edit: `${_model}settings.company_profile.account_owner.edit`,
          }}
        />
        <PermissionSubRow
          title="Roles & Permissions"
          models={{
            view: `${_model}settings.company_profile.roles_and_permissions.view`,
            edit: `${_model}settings.company_profile.roles_and_permissions.edit`,
          }}
        />
        <PermissionSubRow
          title="Admissions"
          models={{
            view: `${_model}settings.company_profile.admissions.view`,
            edit: `${_model}settings.company_profile.admissions.edit`,
          }}
        />
        <PermissionSubRow
          title="Billing"
          models={{
            view: `${_model}settings.company_profile.billing.view`,
            edit: `${_model}settings.company_profile.billing.edit`,
          }}
        />
        <PermissionSubRow
          title="SMS Auto-Reply"
          models={{
            view: `${_model}settings.company_profile.sms_auto_reply.view`,
            edit: `${_model}settings.company_profile.sms_auto_reply.edit`,
          }}
        />
        <PermissionSubRow
          title="Forms Submissions"
          models={{
            view: `${_model}settings.company_profile.forms_submissions.view`,
            edit: `${_model}settings.company_profile.forms_submissions.edit`,
          }}
        />
        <PermissionSubRow
          title="Community Profile Admin Notifications"
          models={{
            view: `${_model}settings.company_profile.community_profile_admin_notifications.view`,
            edit: `${_model}settings.company_profile.community_profile_admin_notifications.edit`,
          }}
        />
        <PermissionSubRow
          title="Notifications for Credentials"
          models={{
            view: `${_model}settings.company_profile.credential_notifications.view`,
            edit: `${_model}settings.company_profile.credential_notifications.edit`,
          }}
        />
      </PermissionSection>

      <PermissionSection
        title="Staff Access"
        icon="lists"
        featureFlagV2="staff_clients_access"
        models={{
          view: `${_model}access_controls.view`,
          delete: `${_model}access_controls.delete`,
        }}
      >
        <PermissionSubRow
          title="Actions"
          actions={[
            {
              label: 'Import Clients',
              model: `${_model}access_controls.actions.import`,
            },
          ]}
        />
      </PermissionSection>

      <PermissionSection
        title="Rounds"
        icon="rounds"
        featureFlagV2="rounds"
        models={{
          view: `${_model}rounds.view`,
          create: `${_model}rounds.create`,
          edit: `${_model}rounds.edit`,
          delete: `${_model}rounds.delete`,
        }}
      />

      <PermissionSection
        title="Rounds Templates"
        icon="rounds"
        featureFlagV2="rounds_templates"
        models={{
          view: `${_model}rounds_templates.view`,
          create: `${_model}rounds_templates.create`,
          edit: `${_model}rounds_templates.edit`,
          delete: `${_model}rounds_templates.delete`,
        }}
      />

      <PermissionSection
        title="Safety Checks"
        icon="safety_checks"
        featureFlagV2="safety_checks"
        models={{
          view: `${_model}safety_checks.view`,
          create: `${_model}safety_checks.create`,
          edit: `${_model}safety_checks.edit`,
          delete: `${_model}safety_checks.delete`,
        }}
      />

      <PermissionSection
        title="Shift Notes"
        icon="housing_notes"
        featureFlagV2="shift_notes"
        models={{
          view: `${_model}shift_notes.view`,
          create: `${_model}shift_notes.create`,
          edit: `${_model}shift_notes.edit`,
          delete: `${_model}shift_notes.delete`,
        }}
      />

      <PermissionSection
        title="Peer Notes"
        icon="peer_notes"
        featureFlagV2="peer_notes"
        models={{
          view: `${_model}peer_notes.view`,
          create: `${_model}peer_notes.create`,
          edit: `${_model}peer_notes.edit`,
          delete: `${_model}peer_notes.delete`,
        }}
      />

      <PermissionSection
        title="Daily Notes"
        icon="daily_notes"
        featureFlagV2="daily_notes"
        models={{
          view: `${_model}daily_notes.view`,
          create: `${_model}daily_notes.create`,
          edit: `${_model}daily_notes.edit`,
          delete: `${_model}daily_notes.delete`,
        }}
      />

      <PermissionSection
        title="To-Do's"
        icon="tasks"
        models={{
          view: `${_model}todos.view`,
          create: `${_model}todos.create`,
          edit: `${_model}todos.edit`,
          delete: `${_model}todos.delete`,
        }}
      >
        <PermissionSubRow
          title="Batch Edit View"
          models={{
            view: `${_model}todos.admin_view.view`,
          }}
        />
      </PermissionSection>

      <PermissionSection
        title="To-Do Templates"
        icon="tasks"
        featureFlagV2="todo_templates"
        models={{
          view: `${_model}todo_templates.view`,
          create: `${_model}todo_templates.create`,
          edit: `${_model}todo_templates.edit`,
          delete: `${_model}todo_templates.delete`,
        }}
      />

      <PermissionSection
        title="Calendar"
        icon="calendar"
        featureFlagV2="calendar"
        models={{
          view: `${_model}events.view`,
          create: `${_model}events.create`,
          edit: `${_model}events.edit`,
          delete: `${_model}events.delete`,
        }}
      >
        <PermissionSubRow
          title="Actions"
          actions={[
            {
              label: 'Activate Events',
              model: `${_model}events.actions.activate`,
            },
            {
              label: 'Cancel Events',
              model: `${_model}events.actions.cancel`,
            },
          ]}
        />
        <PermissionSubRow
          title="Batch Edit View"
          models={{
            view: `${_model}events.admin_view.view`,
          }}
        />
      </PermissionSection>

      <PermissionSection
        title="Calendar Templates"
        icon="calendar"
        featureFlagV2="calendar_templates"
        models={{
          view: `${_model}calendar_templates.view`,
          create: `${_model}calendar_templates.create`,
          edit: `${_model}calendar_templates.edit`,
          delete: `${_model}calendar_templates.delete`,
        }}
      />

      <PermissionSection title="Test Results" icon="test_results" featureFlagV2="test_results">
        <PermissionSubRow
          title="UA Tests"
          trueValue={true}
          falseValue={false}
          featureFlagV2="ua_tests"
          models={{
            view: `${_model}test_results.ua_tests.view`,
            create: `${_model}test_results.ua_tests.create`,
            edit: `${_model}test_results.ua_tests.edit`,
            delete: `${_model}test_results.ua_tests.delete`,
          }}
        />
        <PermissionSubRow
          title="Breathalyzer Tests"
          trueValue={true}
          falseValue={false}
          featureFlagV2="breathalyzer_tests"
          models={{
            view: `${_model}test_results.breathalyzer_tests.view`,
            create: `${_model}test_results.breathalyzer_tests.create`,
            edit: `${_model}test_results.breathalyzer_tests.edit`,
            delete: `${_model}test_results.breathalyzer_tests.delete`,
          }}
        />
        <PermissionSubRow
          title="Vital Signs"
          trueValue={true}
          falseValue={false}
          featureFlagV2="vital_signs"
          models={{
            view: `${_model}test_results.vital_signs.view`,
            create: `${_model}test_results.vital_signs.create`,
            edit: `${_model}test_results.vital_signs.edit`,
            delete: `${_model}test_results.vital_signs.delete`,
          }}
        />
        <PermissionSubRow
          title="Communicable Disease Tests"
          trueValue={true}
          falseValue={false}
          featureFlagV2="communicable_disease_tests"
          models={{
            view: `${_model}test_results.communicable_disease_tests.view`,
            create: `${_model}test_results.communicable_disease_tests.create`,
            edit: `${_model}test_results.communicable_disease_tests.edit`,
            delete: `${_model}test_results.communicable_disease_tests.delete`,
          }}
        />
        <PermissionSubRow
          title="Labs"
          trueValue={true}
          falseValue={false}
          featureFlagV2="labs"
          models={{
            view: `${_model}test_results.labs.view`,
            create: `${_model}test_results.labs.create`,
            edit: `${_model}test_results.labs.edit`,
            delete: `${_model}test_results.labs.delete`,
          }}
        />
      </PermissionSection>

      <PermissionSection
        title="Wiley Practice Planner"
        icon="treatment_plans"
        featureFlagV2="treatment_plans"
        models={{
          view: `${_model}wiley_treatment_plans.view`,
        }}
      />

      <PermissionSection
        title="Archived Treatment Plans"
        icon="treatment_plans"
        featureFlagV2="treatment_plans"
        models={{
          view: `${_model}treatment_plans.view`,
          create: `${_model}treatment_plans.create`,
          edit: `${_model}treatment_plans.edit`,
          delete: `${_model}treatment_plans.delete`,
        }}
      />

      <PermissionSection title="Treatment Plans" icon="treatment_plans" featureFlagV2="treatment_plans">
        <PermissionSubRow
          title="Clinical Treatment Plans"
          models={{
            view: `${_model}clinical_treatment_plans.view`,
            create: `${_model}clinical_treatment_plans.create`,
            edit: `${_model}clinical_treatment_plans.edit`,
            delete: `${_model}clinical_treatment_plans.delete`,
          }}
        />
        <PermissionSubRow
          title="Medical Treatment Plans"
          models={{
            view: `${_model}medical_treatment_plans.view`,
            create: `${_model}medical_treatment_plans.create`,
            edit: `${_model}medical_treatment_plans.edit`,
            delete: `${_model}medical_treatment_plans.delete`,
          }}
        />
        <PermissionSubRow
          title="Case Management Treatment Plans"
          models={{
            view: `${_model}case_management_treatment_plans.view`,
            create: `${_model}case_management_treatment_plans.create`,
            edit: `${_model}case_management_treatment_plans.edit`,
            delete: `${_model}case_management_treatment_plans.delete`,
          }}
        />
        <PermissionSubRow
          title="Peer Treatment Plans"
          models={{
            view: `${_model}peer_treatment_plans.view`,
            create: `${_model}peer_treatment_plans.create`,
            edit: `${_model}peer_treatment_plans.edit`,
            delete: `${_model}peer_treatment_plans.delete`,
          }}
        />
      </PermissionSection>

      <PermissionSection title="Treatment Plan Templates" icon="treatment_plans" featureFlagV2="treatment_plan_templates">
        <PermissionSubRow
          title="Clinical Treatment Plan Templates"
          models={{
            view: `${_model}clinical_treatment_plan_templates.view`,
            create: `${_model}clinical_treatment_plan_templates.create`,
            edit: `${_model}clinical_treatment_plan_templates.edit`,
            delete: `${_model}clinical_treatment_plan_templates.delete`,
          }}
        />
        <PermissionSubRow
          title="Medical Treatment Plan Templates"
          models={{
            view: `${_model}medical_treatment_plan_templates.view`,
            create: `${_model}medical_treatment_plan_templates.create`,
            edit: `${_model}medical_treatment_plan_templates.edit`,
            delete: `${_model}medical_treatment_plan_templates.delete`,
          }}
        />
        <PermissionSubRow
          title="Case Management Treatment Plan Templates"
          models={{
            view: `${_model}case_management_treatment_plan_templates.view`,
            create: `${_model}case_management_treatment_plan_templates.create`,
            edit: `${_model}case_management_treatment_plan_templates.edit`,
            delete: `${_model}case_management_treatment_plan_templates.delete`,
          }}
        />
        <PermissionSubRow
          title="Peer Treatment Plan Templates"
          models={{
            view: `${_model}peer_treatment_plan_templates.view`,
            create: `${_model}peer_treatment_plan_templates.create`,
            edit: `${_model}peer_treatment_plan_templates.edit`,
            delete: `${_model}peer_treatment_plan_templates.delete`,
          }}
        />
      </PermissionSection>

      <PermissionSection title="Treatment Book" icon="treatment_plans" featureFlagV2="treatment_book">
        <PermissionSubRow
          title="Clinical Treatment Book"
          models={{
            view: `${_model}clinical_treatment_book.view`,
            create: `${_model}clinical_treatment_book.create`,
            edit: `${_model}clinical_treatment_book.edit`,
            delete: `${_model}clinical_treatment_book.delete`,
          }}
        />
        <PermissionSubRow
          title="Medical Treatment Book"
          models={{
            view: `${_model}medical_treatment_book.view`,
            create: `${_model}medical_treatment_book.create`,
            edit: `${_model}medical_treatment_book.edit`,
            delete: `${_model}medical_treatment_book.delete`,
          }}
        />
        <PermissionSubRow
          title="Case Management Treatment Book"
          models={{
            view: `${_model}case_management_treatment_book.view`,
            create: `${_model}case_management_treatment_book.create`,
            edit: `${_model}case_management_treatment_book.edit`,
            delete: `${_model}case_management_treatment_book.delete`,
          }}
        />
        <PermissionSubRow
          title="Peer Treatment Book"
          models={{
            view: `${_model}peer_treatment_book.view`,
            create: `${_model}peer_treatment_book.create`,
            edit: `${_model}peer_treatment_book.edit`,
            delete: `${_model}peer_treatment_book.delete`,
          }}
        />
      </PermissionSection>

      <PermissionSection
        title="Telehealth"
        icon="telehealth"
        featureFlagV2="telehealth"
        models={{
          view: `${_model}telehealth.view`,
          create: `${_model}telehealth.create`,
        }}
      />

      <PermissionSection
        title="Treatment Episodes"
        icon="treatment_episodes"
        featureFlagV2="admissions"
        models={{
          view: `${_model}treatment_episodes.view`,
          create: `${_model}treatment_episodes.create`,
          edit: `${_model}treatment_episodes.edit`,
          delete: `${_model}treatment_episodes.delete`,
        }}
      />

      <PermissionSection
        title="Tag Groups"
        icon="tags"
        featureFlagV2="tags"
        models={{
          view: `${_model}tag_groups.view`,
          create: `${_model}tag_groups.create`,
          edit: `${_model}tag_groups.edit`,
          delete: `${_model}tag_groups.delete`,
        }}
      />

      <PermissionSection
        title="Tags"
        icon="tags"
        featureFlagV2="tags"
        models={{
          view: `${_model}tags.view`,
          create: `${_model}tags.create`,
          edit: `${_model}tags.edit`,
          delete: `${_model}tags.delete`,
        }}
      />

      <PermissionSection
        title="Forms Builder"
        icon="web_form"
        featureFlagV2="forms_builder"
        models={{
          view: `${_model}forms_builder.view`,
          create: `${_model}forms_builder.create`,
          edit: `${_model}forms_builder.edit`,
          delete: `${_model}forms_builder.delete`,
        }}
      />

      <PermissionSection
        title="Assessments Builder"
        icon="assessments_builder"
        featureFlagV2="assessments_builder"
        models={{
          view: `${_model}assessments_builder.view`,
          create: `${_model}assessments_builder.create`,
          edit: `${_model}assessments_builder.edit`,
          delete: `${_model}assessments_builder.delete`,
        }}
      />

      <PermissionSection
        title="Discharges Builder"
        icon="discharges_builder"
        featureFlagV2="discharges_builder"
        models={{
          view: `${_model}discharges_builder.view`,
          edit: `${_model}discharges_builder.edit`,
        }}
      />

      <PermissionSection
        title="Form Submissions"
        icon="web_form"
        featureFlagV2="form_submissions"
        models={{
          view: `${_model}form_submissions.view`,
          create: `${_model}form_submissions.create`,
          edit: `${_model}form_submissions.edit`,
          delete: `${_model}form_submissions.delete`,
        }}
      />

      <PermissionSection
        title="Form Variables"
        icon="variables"
        featureFlagV2={isBehave}
        models={{
          view: `${_model}form_variables.view`,
          create: `${_model}form_variables.create`,
          edit: `${_model}form_variables.edit`,
          delete: `${_model}form_variables.delete`,
        }}
      />
    </>
  )
}

export default StaffPermissionsList
