import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { getClientLink } from '@behavehealth/utils/functions'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import DataFormStatus from '../../components/Statuses/DataFormStatus'

import { NURSING_TITLES } from '../../utils/constants'
import { DATA_FORM_FILTERS } from '../Filters/constants'

export const NursingDataTable = (props: any) => {
  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        formatValue: ({ data, value }: any) => <MainCell to={props.to?.(data)} value={value || NURSING_TITLES[data?.subcategory]} />,
      },
      {
        title: 'Client',
        model: 'client',
        formatValue: ({ value }) => {
          if (!value) return null

          return <MainCell value={value?.name} avatar={value?.avatar || ''} to={`${getClientLink(value)}/nursing`} />
        },
      },
      {
        title: 'Status',
        model: 'status',
        width: 200,
        formatValue: ({ value }: any) => <DataFormStatus status={value} />,
      },
      {
        title: 'Score',
        model: 'score',
        width: 75,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Edited',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
      },
    ],
    [props.to],
  )

  return <DataTable {...props} icon="nursing" title="Nursing Forms" columns={columns} filtersConfig={FILTERS} />
}

const FILTERS = {
  subcategory: {
    label: 'Type',
    type: 'multi_select',
    options: Object.entries(NURSING_TITLES).map(([value, label]) => ({ value, label })),
  },
  ...DATA_FORM_FILTERS,
}
