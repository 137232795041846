import React from 'react'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Overlay from '../../components/Overlay'

import { OrganizationApplicationForm } from './OrganizationApplicationForm'
import { useOverlay } from '../../hooks/useOverlay'

import { LEAD_APPLICATION_FORM_SCHEMA } from './data'

export const LeadApplicationFormOverlay = (props) => {
  const { companyName, schema = LEAD_APPLICATION_FORM_SCHEMA } = props

  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'todo',
    // endpoint: '/todo',
    invalidate: 'todo',
    options: props,
  })

  return (
    <Overlay position="right" maxWidth={40} closeOnBackdrop showBackdrop={true}>
      <Overlay.Header icon="lead_contact_forms" title="Lead Contact Form" />

      <Overlay.Content className="p-5">
        <OrganizationApplicationForm form={form} schema={schema} companyName={companyName} canUpload={true} />
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button glyph="edit" label="Edit Form" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Form?"
              message="Are you sure you want to delete this Form? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button glyph="delete" label="Delete" type="default" color="red" isLoading={isDeleting} fullWidth />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}
