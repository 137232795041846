import React from 'react'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'
import compact from 'lodash/compact'

import { DEFAULT_FILTERS } from '../../constructs/Filters/constants'
import { useSettings } from '../../hooks'
import withSettings from '../../hocs/withSettings'

import Button from '../../components/Button'
import Flex from '../../components/Flex'
import SummonOverlay from '../../components/SummonOverlay'

import { FormBuilderShareOverlay } from './FormBuilderShareOverlay'
import { FormBuilderShareStatus } from './FormBuilderShareStatus'

import { DataTable } from '../../components/DataTable/DataTable'
import { LinkCell } from '../../components/DataTable/cells/LinkCell'
import { MainCell } from '../../components/DataTable/cells/MainCell'

const RootFormsDataTable = (props: any) => {
  const [shareOverlayData, setShareOverlayData]: any = React.useState(null)

  const { tenant, isBehave } = useSettings()

  const handleShareClick = (rowData: any) => {
    if (!rowData) return
    setShareOverlayData(rowData)
  }

  const columns = React.useMemo(
    () =>
      compact([
        {
          width: 260,
          isSticky: true,
          canToggleVisible: false,
          model: 'name',
          title: 'Name',
          formatValue: ({ data, value }: any) => (
            <MainCell
              as={NavLink}
              to={props.to ? props.to(data) : undefined}
              onClick={props.onClick ? props.onClick(data) : undefined}
              value={value}
            />
          ),
        },
        {
          width: 160,
          model: 'use_public_sharing',
          title: 'Share Status',
          formatValue: ({ value, data }: any) => {
            return (
              <Flex centerY flex="1 1 auto" justifyContent="space-between">
                <FormBuilderShareStatus status={value === true ? 'public' : 'private'} />

                <Button hideLabel size={100} type="minimal" glyph="settings" onClick={() => handleShareClick?.(data)} />
              </Flex>
            )
          },
        },
        {
          width: 180,
          model: 'external_id',
          title: 'Shareable Link',
          disableSort: true,
          formatValue: ({ data, value: externalID }: any) => {
            const isPublic = data.use_public_sharing
            const formURL = externalID && tenant && `${process.env.BH_APP_FORM_URL}/${tenant.subdomain}/${externalID}`

            if (!isPublic || !formURL) return null

            return <LinkCell isExternal href={formURL} value="Open Public Link" />
          },
        },
        {
          title: 'Client Tracks',
          model: 'client_tracks',
          type: 'record_client_tracks',
          featureFlag: 'client_tracks',
          editPermission: 'client_tracks.edit',
          disableSort: true,
        },
        {
          width: 200,
          model: 'internal_notes',
          title: 'Internal Notes',
        },
        {
          width: 200,
          model: 'shared_links',
          title: 'Shared Links',
        },
        {
          model: 'created_at',
          title: 'Date Added',
          type: 'date_time',
        },
        {
          model: 'updated_at',
          title: 'Date Updated',
          type: 'date_time',
        },
        props.renderDuplicateCell && {
          title: 'Actions',
          id: 'duplicate',
          width: 130,
          disableSort: true,
          hoverExpand: false,
          formatValue: ({ data }: any) => {
            return props.renderDuplicateCell(data)
          },
        },
      ]),
    [tenant, props.to, props.onClick, props.renderDuplicateCell, isBehave],
  )

  return (
    <>
      <DataTable title="Forms" testKey="form_data_table" icon="web_form" columns={columns} filtersConfig={FILTERS_CONFIG} {...props} />

      <SummonOverlay
        isOpen={!!shareOverlayData}
        onClose={() => setShareOverlayData(null)}
        overlay={<FormBuilderShareOverlay dataID={shareOverlayData?.id} data={shareOverlayData} />}
      />
    </>
  )
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  client_tracks: DEFAULT_FILTERS.client_tracks,
  internal_notes: {
    label: 'Internal Notes',
    type: 'string',
  },
  use_public_sharing: {
    label: 'Public Sharing',
    type: 'boolean',
    trueLabel: 'Public',
    falseLabel: 'Internal',
  },
  shared_links: {
    label: 'Shared Links',
    type: 'string',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}

export const FormsDataTable = withSettings(RootFormsDataTable)
