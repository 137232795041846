import React from 'react'

import { COLORS } from '../../theme'

import NavGroup from '../NavGroup'
import NavItem from '../NavItem'

type Props = {
  organization: object
  isPartner: boolean
}

export const CommunityOrganizationAuthorityNavItems = (props: Props) => {
  const { organization } = props

  const basePath = `/community/organizations/${organization?.id}`

  return (
    <>
      <NavGroup label="General">
        <NavItem label="General Info" icon="general_info" to={`${basePath}/authority/general-info`} color={COLORS.blue} />
        <NavItem
          showMarketing
          feature="todos"
          label="To-Do"
          to={`${basePath}/authority/todo`}
          permission="todos.view"
          featureFlagV2="todos"
        />
        <NavItem
          showMarketing
          label="Calendar"
          to={`${basePath}/authority/calendar`}
          feature="calendar"
          permission="events.view"
          featureFlagV2="calendar"
        />
        <NavItem
          showMarketing
          feature="files"
          label="Files"
          to={`${basePath}/authority/files`}
          permission="organizations.files.view"
          featureFlagV2="files"
        />
        <NavItem
          showMarketing
          feature="contacts"
          label="Contacts"
          to={`${basePath}/authority/contacts`}
          permission="contacts.view"
          featureFlagV2="contacts"
        />
        <NavItem showMarketing feature="properties" label="Locations" to={`${basePath}/authority/locations`} />
      </NavGroup>

      <NavGroup label="Forms">
        <NavItem label="Lead Contact Forms" feature="lead_contact_forms" to={`${basePath}/authority/lead-contact-forms`} />
        <NavItem label="Certifications" feature="certifications_inspections" to={`${basePath}/authority/certification-forms`} />
        <NavItem label="Inspections" feature="certifications_inspections" to={`${basePath}/authority/inspection-forms`} />
        <NavItem
          feature="form_submissions"
          label="Form Inbox"
          to={`${basePath}/authority/form-submissions`}
          featureFlagV2="form_submissions"
          permission="form_submissions.view"
        />
      </NavGroup>

      <NavGroup label="Agreements">
        <NavItem label="Agreements" feature="organization_agreements" to={`${basePath}/authority/agreement-forms`} />
      </NavGroup>

      <NavGroup label="Financials">
        <NavItem
          feature="financials"
          label="Financials"
          to={`${basePath}/authority/financials`}
          permission="ledger.view"
          featureFlagV2="organization_financials"
        />
      </NavGroup>

      <NavGroup label="Complaints & Issues">
        <NavItem label="Grievance Forms" feature="grievance_incident_forms" to={`${basePath}/authority/grievance-forms`} />
        <NavItem label="Incident Reporting" feature="grievance_incident_forms" to={`${basePath}/authority/incident-reporting`} />
      </NavGroup>

      <NavGroup label="Studies & Surveys">
        <NavItem label="Studies" feature="studies_surveys" to={`${basePath}/authority/studies`} />
        <NavItem label="Surveys" feature="studies_surveys" to={`${basePath}/authority/surveys`} />
      </NavGroup>
    </>
  )
}
