import React from 'react'
import clsx from 'clsx'
import size from 'lodash/size'

import { useSettings } from '../../hooks/useSettings'

import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Flex from '../../components/Flex'
import FormSection from '../../components/Forms/FormSection'
import Markup from '../../components/Markup'
import Section from '../../components/Section'
import SmartTextarea from '../../components/Forms/SmartTextarea'

import { CustomQuestion } from './components/CustomQuestion'
import { COMMENT_STATUSES } from './constants'
import { NARRFormStatus } from '../AuthorityOrganizations/NARRFormStatus'

const getIsAnyTrue = (obj: any) => {
  if (!obj) return false

  for (let key in obj) {
    if (obj[key]?.show === true) return true
  }

  return false
}

export const AuthorityFormRenderer = (props: any) => {
  const { authorityName, sections, client, schema, useScrollView, isEditable, parentSection, enableComments }: any = props

  const { timezone } = useSettings()

  if (!sections || !schema) return null

  return (
    <>
      {sections.map((section: any, index: number) => {
        const isLast = index === size(sections) - 1

        const show = schema[section.model]?.show

        if (!show) return null

        const anyFieldsTrue = getIsAnyTrue(schema[section.model]?.fields)

        if (!anyFieldsTrue) return null

        const hasParent = !!parentSection

        const parent = hasParent
          ? {
              id: parentSection.model,
              name: parentSection.title,
            }
          : section.navParent

        return (
          <React.Fragment key={section.model}>
            <Section
              id={`section_${section.model}`}
              key={section.model}
              title={section.title}
              className={hasParent ? '!m-0 !mt-7 !p-0' : '!my-0 !py-6'}
              scrollview={
                useScrollView && {
                  id: section.model,
                  name: section.title,
                  parent,
                }
              }
            >
              <FormSection maxWidth="100%" className={clsx(enableComments ? '!gap-0' : '!gap-5')}>
                {schema?.[section.model]?.custom_text_before && <Markup value={schema?.[section.model]?.custom_text_before} />}

                {schema?.[section.model]?.custom_questions_before?.map?.((question: any) => {
                  return <CustomQuestion key={question._id} question={question} sectionModel={section.model} />
                })}

                {section.fields?.map?.((field: any) => {
                  const FieldComponent = field.component || field.CustomComponent
                  const fieldSettings = schema[section.model]?.fields?.[field.model]

                  if (!fieldSettings?.show) return null

                  const inner = (
                    <FieldComponent
                      key={field.model}
                      client={client}
                      isEditable={isEditable}
                      timezone={props.timezone || timezone}
                      schema={schema}
                      isRequired={fieldSettings.required}
                      authorityName={authorityName}
                      fieldModel={field.model}
                      fieldLabel={field.label}
                      withHover={false}
                    />
                  )

                  if (enableComments) {
                    return <CommentsWrapper>{inner}</CommentsWrapper>
                  }

                  return inner
                })}

                {schema?.[section.model]?.custom_questions_after?.map?.((question: any) => {
                  return <CustomQuestion key={question._id} question={question} sectionModel={section.model} />
                })}
              </FormSection>
            </Section>

            {/* {!isLast && <Divider />} */}
          </React.Fragment>
        )
      })}
    </>
  )
}

const CommentsWrapper = (props: any) => {
  const { children } = props

  const { user } = useSettings()

  const [comments, setComments]: any = React.useState([])
  const commentsCount = size(comments)
  const hasComments = commentsCount > 0

  const [newComment, setNewComment] = React.useState('')
  const [showNewComment, setShowNewComment] = React.useState(false)

  const addComment = () => {
    setComments([...comments, { comment: newComment, name: user?.name || 'Anonymous', status: 'draft' }])
    setNewComment('')
    setShowNewComment(false)
  }

  const commentsVisible = hasComments || showNewComment

  return (
    <div
      className={clsx(
        'relative hover:rounded-[5px] px-1.5 -mx-1.5 hover:bg-hover [&:hover_.HOVER]:!opacity-100 first:border-t border-b border-px border-0 border-solid border-divider',
        true && 'grid mq768:gap-6 mq768:grid-cols-[1fr_280px]',
      )}
    >
      <div className="pt-2 mq768:pb-3">{children}</div>

      <div className="relative content-start grid gap-3 text-[0.96rem] mq768:border-l mq768:border-px mq768:border-0 mq768:border-solid mq768:border-divider mq768:pl-3 py-3">
        {!commentsVisible && (
          <div className="HOVER opacity-100 mq768:opacity-0 mq768:absolute mq768:left-3 mq768:top-1.5">
            <Button
              label="Add Comment"
              size={100}
              type="default"
              glyph="add"
              display="inline-flex"
              onClick={() => setShowNewComment(true)}
            />
          </div>
        )}

        {commentsVisible && (
          <div className="flex items-center justify-between">
            <div className="text-text-muted opacity-80 text-[0.85rem] uppercase font-[700] tracking-[0.5px]">Comments</div>
          </div>
        )}

        {hasComments && (
          <>
            {comments.map(({ comment, name, status }, index) => (
              <Card key={`${comment}-${index}`} className="pt-1 pb-3 px-2">
                <div className="flex items-center flex-nowrap">
                  <div className="w-8 h-8 flex items-center justify-start">
                    <Avatar src="" initials={name} size={20} />
                  </div>

                  <div className="font-[600]">{name}</div>

                  <div className="ml-auto pl-2">
                    <NARRFormStatus status="draft" statuses={COMMENT_STATUSES} />
                  </div>
                </div>

                <div className="pl-8 mt-0 text-text-muted">
                  <div>{comment}</div>

                  <Button label="Edit" glyph="edit" type="default" size={100} display="inline-flex" className="mt-2" />
                </div>
              </Card>
            ))}

            {!showNewComment && (
              <div className="flex justify-start">
                <Button
                  label="Add Comment"
                  size={100}
                  type="default"
                  glyph="add"
                  display="inline-flex"
                  onClick={() => setShowNewComment(true)}
                />
              </div>
            )}
          </>
        )}

        {showNewComment && (
          <>
            <SmartTextarea
              isEditable
              autoFocus
              useQuickText={false}
              useDictation
              placeholder="Your comment"
              value={newComment}
              onUpdate={({ value }) => setNewComment(value)}
              withHover={false}
              key={`comment_${commentsCount}`}
            />

            <div className="mt-2">
              <Flex gap="0.75rem">
                <Button
                  label="Add Comment"
                  type="primary"
                  size={100}
                  color="green"
                  glyph="tick_circle"
                  display="inline-flex"
                  onClick={addComment}
                  isDisabled={!newComment}
                />

                <Button
                  label="Cancel"
                  size={100}
                  type="default"
                  onClick={() => {
                    setShowNewComment(false)
                    setNewComment('')
                  }}
                />
              </Flex>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
