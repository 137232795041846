import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { useAPI } from '@behavehealth/hooks'
import { Contact } from '@behavehealth/declarations'

import { Button, Card, Grid, Page } from '@behavehealth/components'
import { OrganizationContactsTable } from '@behavehealth/components/Tables'
import { NavLink } from 'react-router-dom-v5-compat'

export const Contacts: React.FC = () => {
  const match = useRouteMatch()
  const data: Contact = useSelector((state) => state.data.contacts?.data)
  const loading = useSelector((state) => state.data.contacts?.loading)

  useAPI('contacts', `/organizations/${match.params?.resource_id}/contacts`)

  const isEmpty = size(data) === 0

  return (
    <Page feature="contacts" actions={<Button as={NavLink} label="Add Contact" type="primary" glyph="add" link={'new'} />}>
      <Grid>
        <Card>
          <OrganizationContactsTable
            data={data}
            mainLinkAs={NavLink}
            isLoading={isEmpty && loading}
            localStorageKey="organizations_contacts"
            to={(id) => id}
          />
        </Card>
      </Grid>
    </Page>
  )
}
