import React from 'react'
import merge from 'lodash/merge'
import produce from 'immer'

import Overlay from '../../components/Overlay'
import Dialog from '../../components/Dialog'
import Workflow from '../../components/Workflow/Workflow'
import { DataTable } from '../../components/DataTable/DataTable'

import { OrganizationWorkflowSubheader } from '../Organizations/OrganizationWorkflowSubheader'

const stepsReducer = (state: any, payload: any) => {
  const { step, status } = payload
  if (!status || !state.hasOwnProperty(step)) return state

  return produce(state, (draft: any) => {
    draft[step] = status
  })
}

const initialData = {}
const initialSteps = {
  lead_application_forms: 'todo',
  certifications: 'todo',
  inspections: 'todo',
  agreements: 'todo',
  grievance_forms: 'todo',
  incident_reporting: 'todo',
  studies: 'todo',
  surveys: 'todo',
}

const dataReducer = (state: any, payload: any) => {
  return {
    ...state,
    ...payload,
  }
}

export const AuthorityOrganizationWorkflowOverlay = (props: any) => {
  const { title, glyph, organization, onClose } = props

  const [steps, setSteps] = React.useReducer(stepsReducer, initialSteps)
  const [data, setData] = React.useReducer(dataReducer, initialData)

  return (
    <Overlay
      fullheight
      showBackdrop
      maxWidth={52}
      position="center"
      onClose={onClose}
      closeWrapper={(closeElement: any) => (
        <Dialog
          glyph="delete"
          title="Close without saving?"
          message="All changes will be lost if not saved on the Review & Save step"
          yesColor="red"
          yesLabel="Yes, Close Without Saving"
          onYes={onClose}
        >
          {closeElement}
        </Dialog>
      )}
    >
      <Overlay.Header glyph={glyph} title={title} />

      <OrganizationWorkflowSubheader organization={organization} />

      <Overlay.Content>
        <Workflow name="Certify Organization">
          <Workflow.Panel step="lead_application_forms">
            <Workflow.Header title="Lead Contact Forms" after={<Workflow.Status status={steps.lead_application_forms} />} />
            <Workflow.Content>
              <DataTable asCard title="Lead Contact Forms" icon="authority_forms" />
            </Workflow.Content>
          </Workflow.Panel>

          <Workflow.Panel step="certifications">
            <Workflow.Header title="Certifications" after={<Workflow.Status status={steps.certifications} />} />
            <Workflow.Content>
              <DataTable asCard title="Certifications" icon="authority_forms" />
            </Workflow.Content>
          </Workflow.Panel>

          <Workflow.Panel step="inspections">
            <Workflow.Header title="Inspections" after={<Workflow.Status status={steps.inspections} />} />
            <Workflow.Content>
              <DataTable asCard title="Inspections" icon="authority_forms" />
            </Workflow.Content>
          </Workflow.Panel>

          <Workflow.Panel step="agreements">
            <Workflow.Header title="Agreements" after={<Workflow.Status status={steps.agreements} />} />
            <Workflow.Content>
              <DataTable asCard title="Agreements" icon="authority_forms" />
            </Workflow.Content>
          </Workflow.Panel>

          <Workflow.Panel step="grievance_forms">
            <Workflow.Header title="Grievance Forms" after={<Workflow.Status status={steps.grievance_forms} />} />
            <Workflow.Content>
              <DataTable asCard title="Grievance Forms" icon="authority_forms" />
            </Workflow.Content>
          </Workflow.Panel>

          <Workflow.Panel step="incident_reporting">
            <Workflow.Header title="Incident Reporting" after={<Workflow.Status status={steps.incident_reporting} />} />
            <Workflow.Content>
              <DataTable asCard title="Incident Reporting" icon="authority_forms" />
            </Workflow.Content>
          </Workflow.Panel>

          <Workflow.Panel step="studies">
            <Workflow.Header title="Studies" after={<Workflow.Status status={steps.studies} />} />
            <Workflow.Content>
              <DataTable asCard title="Studies" icon="authority_forms" />
            </Workflow.Content>
          </Workflow.Panel>

          <Workflow.Panel step="surveys">
            <Workflow.Header title="Surveys" after={<Workflow.Status status={steps.surveys} />} />
            <Workflow.Content>
              <DataTable asCard title="Surveys" icon="authority_forms" />
            </Workflow.Content>
          </Workflow.Panel>
        </Workflow>
      </Overlay.Content>
    </Overlay>
  )
}
