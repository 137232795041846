import React from 'react'

import AddressSelectorInput from '../../../components/Forms/AddressSelectorInput'
import Attachments from '../../../components/Forms/Attachments'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import ContextShow from '../../../components/Forms/ContextShow'
import EmailInput from '../../../components/Forms/EmailInput'
import Input from '../../../components/Forms/Input'
import PhoneInput from '../../../components/Forms/PhoneInput'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import SmartTextarea from '../../../components/Forms/SmartTextarea'
import YesNoRadioGroup from '../../../components/Forms/elements/YesNoRadioGroup'
import YesNoRadioGroupWithTextarea from '../../../components/Forms/elements/YesNoRadioGroupWithTextarea'

export const CERTIFICATION_FORM_ORGANIZATION = [
  {
    title: 'Organization Information',
    model: 'organization_info',
    fields: [
      {
        label: 'Member/Application Type',
        model: 'application_type',
        component: ({ isRequired }) => (
          <RadioGroup
            label="Member/Application Type"
            layout="horizontal-dense"
            model="organization_info.application_type"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          >
            <Radio label="New" value="new" />
            <Radio label="Renewal" value="renewal" />
          </RadioGroup>
        ),
      },
      {
        label: 'Organization Name',
        model: 'name',
        component: ({ isRequired }) => (
          <Input
            label="Organization Name"
            model="organization_info.name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Residence Ownership',
        model: 'residence_ownership',
        component: ({ isRequired }) => (
          <Input
            label="Residence Ownership"
            model="organization_info.residence_ownership"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'DBA Name if applicable',
        model: 'dba_name',
        component: ({ isRequired }) => (
          <Input
            label="DBA Name if applicable"
            model="organization_info.dba_name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Tax ID',
        model: 'tax_id',
        component: ({ isRequired }) => (
          <Input
            label="Tax ID"
            model="organization_info.tax_id"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Organization Type',
        model: 'organization_type',
        component: ({ isRequired }) => (
          <Input
            label="Organization Type"
            model="organization_info.organization_type"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'State of Incorporation or Organization',
        model: 'state_of_incorporation',
        component: ({ isRequired }) => (
          <Input
            label="State of Incorporation or Organization"
            model="organization_info.state_of_incorporation"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Year/Date Founded',
        model: 'founded_date',
        component: ({ isRequired }) => (
          <Input
            label="Year/Date Founded"
            model="organization_info.founded_date"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Service Locations (counties)',
        model: 'service_locations',
        component: ({ isRequired }) => (
          <AddressSelectorInput
            label="Service Locations (counties)"
            model="organization_info.service_locations"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Phone Number',
        model: 'phone',
        component: ({ isRequired }) => (
          <PhoneInput
            label="Phone Number"
            model="organization_info.phone"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Fax Number',
        model: 'fax',
        component: ({ isRequired }) => (
          <PhoneInput
            label="Fax Number"
            model="organization_info.fax"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Program Email',
        model: 'email',
        component: ({ isRequired }) => (
          <EmailInput
            label="Program Email"
            model="organization_info.email"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Organization Website',
        model: 'website',
        component: ({ isRequired }) => (
          <Input
            label="Organization Website"
            model="organization_info.website"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Organization Business Address',
        model: 'business_address',
        component: ({ isRequired }) => (
          <AddressSelectorInput
            label="Organization Business Address"
            model="organization_info.business_address"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Program Details',
    model: 'program_details',
    fields: [
      {
        label: 'Does applicant own/operate alcohol & drug or mental health program/facility',
        model: 'owns_drug_mental_facility',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Does applicant own/operate alcohol & drug or mental health program/facility"
            textareaLabel="Please provide details"
            model="program_details.owns_drug_mental_facility"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label:
          'Do any of the owners identified in this application have an ownership interest in or any sort of business relationship with a licensed, independent use, confirmatory lab?',
        model: 'owners_lab_relationship',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Do any of the owners identified in this application have an ownership interest in or any sort of business relationship with a licensed, independent use, confirmatory lab?"
            textareaLabel="Please explain"
            model="program_details.owners_lab_relationship"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'How many residences in application',
        model: 'residences_in_application',
        component: ({ isRequired }) => (
          <Input
            label="How many residences in application"
            model="program_details.residences_in_application"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'How many residences does org operate',
        model: 'residences_operated',
        component: ({ isRequired }) => (
          <Input
            label="How many residences does org operate"
            model="program_details.residences_operated"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Willing to fully participate in affiliate activities',
        model: 'willing_to_participate',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Willing to fully participate in affiliate activities"
            textareaLabel="Please explain why not"
            model="program_details.willing_to_participate"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Read and agree to comply with NARR 3.0, requirements, etc',
        model: 'agree_to_narr',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Read and agree to comply with NARR 3.0, requirements, etc"
            textareaLabel="Please explain why not"
            model="program_details.agree_to_narr"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Mission Statement',
        model: 'has_mission_statement',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Mission Statement"
            model="program_details.has_mission_statement"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Vision Statement',
        model: 'has_vision_statement',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Vision Statement"
            model="program_details.has_vision_statement"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you have a written code of ethics',
        model: 'has_code_of_ethics',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Do you have a written code of ethics"
            textareaLabel="Please explain why not"
            model="program_details.has_code_of_ethics"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you have general liability insurance',
        model: 'has_liability_insurance',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Do you have general liability insurance"
            textareaLabel="Please explain why not"
            model="program_details.has_liability_insurance"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Compliance and Policies',
    model: 'compliance_policies',
    fields: [
      {
        label: 'Do you comply with all state and federal requirements',
        model: 'complies_with_requirements',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Do you comply with all state and federal requirements"
            textareaLabel="Please explain why not"
            model="compliance_policies.complies_with_requirements"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you maintain an accounting system that fully documents all resident financial transactions',
        model: 'has_accounting_system',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Do you maintain an accounting system that fully documents all resident financial transactions"
            textareaLabel="Please explain why not"
            model="compliance_policies.has_accounting_system"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you collect and report accurate process and outcome data',
        model: 'collects_outcome_data',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Do you collect and report accurate process and outcome data"
            textareaLabel="Please explain why not"
            model="compliance_policies.collects_outcome_data"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you adhere to applicable confidentiality laws',
        model: 'adheres_to_confidentiality',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Do you adhere to applicable confidentiality laws"
            textareaLabel="Please explain why not"
            model="compliance_policies.adheres_to_confidentiality"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you keep resident records secure  with limited staff access',
        model: 'secures_resident_records',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Do you keep resident records secure  with limited staff access"
            textareaLabel="Please explain why not"
            model="compliance_policies.secures_resident_records"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you have a grievance policy and procedure',
        model: 'has_grievance_policy',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Do you have a grievance policy and procedure"
            textareaLabel="Please explain why not"
            model="compliance_policies.has_grievance_policy"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you abide by all local building and fire safety codes',
        model: 'abides_by_safety_codes',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Do you abide by all local building and fire safety codes"
            textareaLabel="Please explain why not"
            model="compliance_policies.abides_by_safety_codes"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Additional Information',
    model: 'additional_info',
    fields: [
      {
        label: 'Do any of the owners identified have an ownership interest in behavioral healthcare provider',
        model: 'owners_healthcare_interest',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Do any of the owners identified have an ownership interest in behavioral healthcare provider"
            textareaLabel="Please provide details"
            model="additional_info.owners_healthcare_interest"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you offer housing scholarships for qualified applicants?',
        model: 'offers_scholarships',
        component: ({ isRequired }) => (
          <>
            <YesNoRadioGroup
              label="Do you offer housing scholarships for qualified applicants?"
              model="additional_info.offers_scholarships"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
            <SmartTextarea
              useDictation
              useQuickText={false}
              label="Please explain"
              model="additional_info.offers_scholarships_description"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
          </>
        ),
      },
      {
        label: 'Have you read and understood the Certified Residence requirements?',
        model: 'understands_requirements',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Have you read and understood the Certified Residence requirements?"
            textareaLabel="Please explain why not"
            model="additional_info.understands_requirements"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Have you read, and do you agree to comply with the NARR Standards and Code of Ethics?',
        model: 'agrees_to_narr_standards',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Have you read, and do you agree to comply with the NARR Standards and Code of Ethics?"
            textareaLabel="Please explain why not"
            model="additional_info.agrees_to_narr_standards"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Level you are pursuing',
        model: 'level_pursuing',
        component: ({ isRequired }) => (
          <RadioGroup
            label="Level you are pursuing"
            layout="vertical-dense"
            model="additional_info.level_pursuing"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          >
            <Radio label="Level I" value="level_1" />
            <Radio label="Level II" value="level_2" />
            <Radio label="Level III" value="level_3" />
            <Radio label="Level IV" value="level_4" />
          </RadioGroup>
        ),
      },
      {
        label: 'Type of Dwelling',
        model: 'dwelling_type',
        component: ({ isRequired }) => (
          <RadioGroup
            label="Type of Dwelling"
            layout="vertical-dense"
            model="additional_info.dwelling_type"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          >
            <Radio label="Single Family" value="single_family" />
            <Radio label="Duplex" value="duplex" />
            <Radio label="Triplex" value="triplex" />
            <Radio label="Apartment" value="apartment" />
            <Radio label="Other" value="other" />
          </RadioGroup>
        ),
      },
      {
        label: 'Willing to adopt NARR Standards',
        model: 'willing_to_adopt_narr',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Willing to adopt NARR Standards"
            textareaLabel="Please explain why not"
            model="additional_info.willing_to_adopt_narr"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Describe organization governance and leadership',
        model: 'org_governance',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Describe organization governance and leadership"
            model="additional_info.org_governance"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Legal and Financial Information',
    model: 'legal_financial',
    fields: [
      {
        label: 'Have you or any org associated with you been charged or convicted of Medicaid Fraud or Abuse?',
        model: 'medicaid_fraud_conviction',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Have you or any org associated with you been charged or convicted of Medicaid Fraud or Abuse?"
            textareaLabel="Please provide details"
            model="legal_financial.medicaid_fraud_conviction"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Number of Beds in Program',
        model: 'number_of_beds',
        component: ({ isRequired }) => (
          <Input
            label="Number of Beds in Program"
            model="legal_financial.number_of_beds"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Will organization attend training workshops within 90 days of certification',
        model: 'will_attend_training',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Will organization attend training workshops within 90 days of certification"
            textareaLabel="Please explain why not"
            model="legal_financial.will_attend_training"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you operate other types of housing?',
        model: 'operates_other_housing',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Do you operate other types of housing?"
            textareaLabel="Please provide details"
            model="legal_financial.operates_other_housing"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Business Operations',
    model: 'business_operations',
    fields: [
      {
        label: 'Describe your business',
        model: 'business_description',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Describe your business"
            model="business_operations.business_description"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you maintain formal standards',
        model: 'formal_standards',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Do you maintain formal standards"
            model="business_operations.formal_standards"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you agree to adopt the state standards/code of ethics for all residences',
        model: 'adopts_state_standards',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Do you agree to adopt the state standards/code of ethics for all residences"
            textareaLabel="Please explain why not"
            model="business_operations.adopts_state_standards"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you have a defined process for resolving complaints',
        model: 'has_complaint_process',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Do you have a defined process for resolving complaints"
            textareaLabel="Please explain why not"
            model="business_operations.has_complaint_process"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you maintain and follow procedures for logging and retaining records of complaints',
        model: 'maintains_complaint_records',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Do you maintain and follow procedures for logging and retaining records of complaints"
            textareaLabel="Please explain why not"
            model="business_operations.maintains_complaint_records"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Have you reviewed the health, safety, and management requirements?',
        model: 'reviewed_requirements',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Have you reviewed the health, safety, and management requirements?"
            textareaLabel="Please explain why not"
            model="business_operations.reviewed_requirements"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Program Status and Services',
    model: 'program_status',
    fields: [
      {
        label: 'Current status',
        model: 'current_status',
        component: ({ isRequired }) => (
          <RadioGroup
            label="Current status"
            layout="vertical-dense"
            model="program_status.current_status"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          >
            <Radio label="Taking Applicants" value="taking_applicants" />
            <Radio label="Full" value="full" />
            <Radio label="Waiting List" value="waiting_list" />
            <Radio label="Not taking applications" value="not_taking_applications" />
          </RadioGroup>
        ),
      },
      {
        label: 'Type of Service/Modality/Specialty/Type you provide',
        model: 'service_types',
        component: ({ isRequired }) => (
          <CheckboxGroup
            label="Type of Service/Modality/Specialty/Type you provide"
            layout="vertical-dense"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          >
            <Checkbox label="Medical" value="program_status.service_types.medical" />
            <Checkbox label="Mental health" value="program_status.service_types.mental_health" />
            <Checkbox label="12-step" value="program_status.service_types.twelve_step" />
            <Checkbox label="Faith based" value="program_status.service_types.faith_based" />
            <Checkbox label="Other" value="program_status.service_types.other" />
          </CheckboxGroup>
        ),
      },
      {
        label: 'Substance abuse services provided',
        model: 'substance_abuse_services',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Substance abuse services provided"
            textareaLabel="Please provide details"
            model="program_status.substance_abuse_services"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Counseling Services Provided',
        model: 'counseling_services',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Counseling Services Provided"
            textareaLabel="Please provide details"
            model="program_status.counseling_services"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'DUI Services Provided',
        model: 'dui_services',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="DUI Services Provided"
            textareaLabel="Please provide details"
            model="program_status.dui_services"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'ORS#',
        model: 'ors_number',
        component: ({ isRequired }) => (
          <Input
            label="ORS#"
            model="program_status.ors_number"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Research and Financial Contributions',
    model: 'research_financial',
    fields: [
      {
        label: 'Are you willing and able to support -sponsored research',
        model: 'supports_research',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Are you willing and able to support -sponsored research"
            textareaLabel="Please explain why not"
            model="research_financial.supports_research"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Are you willing and able to contribute financially to the operation of NARR by payment',
        model: 'contributes_financially',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Are you willing and able to contribute financially to the operation of NARR by payment"
            textareaLabel="Please explain why not"
            model="research_financial.contributes_financially"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you intend to conform to affiliate requirements',
        model: 'conforms_to_requirements',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Do you intend to conform to affiliate requirements"
            textareaLabel="Please explain why not"
            model="research_financial.conforms_to_requirements"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: ({ authorityName }) => `Do you agree to cooperate with ${authorityName || '…'} in efforts to resolve complaints received?`,
        model: 'cooperates_with_complaints',
        component: ({ isRequired, authorityName }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label={`Do you agree to cooperate with ${authorityName || '…'} in efforts to resolve complaints received?`}
            textareaLabel="Please explain why not"
            model="research_financial.cooperates_with_complaints"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Additional Program Information',
    model: 'additional_program_info',
    fields: [
      {
        label: 'Recovery Community',
        model: 'recovery_community',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Recovery Community"
            model="additional_program_info.recovery_community"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Forms of payment accepted',
        model: 'payment_forms',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Forms of payment accepted"
            model="additional_program_info.payment_forms"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Why do you want to be a member',
        model: 'membership_reason',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Why do you want to be a member"
            model="additional_program_info.membership_reason"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you/your program have any accreditations, certifications, or licenses?',
        model: 'accreditations',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Do you/your program have any accreditations, certifications, or licenses?"
            model="additional_program_info.accreditations"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Other Memberships/Affiliations/Alliances',
        model: 'other_affiliations',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Other Memberships/Affiliations/Alliances"
            model="additional_program_info.other_affiliations"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Program Name',
        model: 'program_name',
        component: ({ isRequired }) => (
          <Input
            label="Program Name"
            model="additional_program_info.program_name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Existing Program or Future Project',
        model: 'program_status',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Existing Program or Future Project"
            model="additional_program_info.program_status"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Is organizations programing faith based',
        model: 'is_faith_based',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Is organizations programing faith based"
            model="additional_program_info.is_faith_based"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label:
          'Does the program offer special and specific services to meet the needs of any of the following groups (DOC, LGBTQIA+, Veterans)',
        model: 'special_services',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Does the program offer special and specific services to meet the needs of any of the following groups (DOC, LGBTQIA+, Veterans)"
            textareaLabel="Please provide details"
            model="additional_program_info.special_services"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Has this organization received funding from State/Federal Dollars',
        model: 'received_state_federal_funding',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Has this organization received funding from State/Federal Dollars"
            textareaLabel="Please provide details"
            model="additional_program_info.received_state_federal_funding"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Legal and Disciplinary History',
    model: 'legal_disciplinary',
    fields: [
      {
        label:
          'Has your organization had a revocation of a prior license, certificate, or approval issued within the previous 10 years from any in-State or out-of-State agency or provider. Include previous or current organizations associated with the applicant, along with any associated deficiency reports and compliance records.',
        model: 'prior_revocation',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Has your organization had a revocation of a prior license, certificate, or approval issued within the previous 10 years from any in-State or out-of-State agency or provider. Include previous or current organizations associated with the applicant, along with any associated deficiency reports and compliance records."
            textareaLabel="Please provide details"
            model="legal_disciplinary.prior_revocation"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label:
          'Has your organization or a program, partnership, corporation, or provider previously or currently associated with the applicant, surrendered, or defaulted on its license, certificate, or approval, or had it revoked or suspended within the previous 10 years, for any reason?',
        model: 'license_surrender_default',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Has your organization or a program, partnership, corporation, or provider previously or currently associated with the applicant, surrendered, or defaulted on its license, certificate, or approval, or had it revoked or suspended within the previous 10 years, for any reason?"
            textareaLabel="Please provide details"
            model="legal_disciplinary.license_surrender_default"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label:
          'Has your organization or a program, corporation, or provider previously or currently associated with your organization surrendered or defaulted on its license, certification, or approval within the previous five (5) years for reasons related to disciplinary action?',
        model: 'license_surrender_disciplinary',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Has your organization or a program, corporation, or provider previously or currently associated with your organization surrendered or defaulted on its license, certification, or approval within the previous five (5) years for reasons related to disciplinary action?"
            textareaLabel="Please provide details"
            model="legal_disciplinary.license_surrender_disciplinary"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label:
          'Has any staff member currently associated with your organization had a professional license or certification revoked, suspended, or surrendered for reasons related to disciplinary action or misconduct, within the previous ten (10) years',
        model: 'staff_license_revocation',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Has any staff member currently associated with your organization had a professional license or certification revoked, suspended, or surrendered for reasons related to disciplinary action or misconduct, within the previous ten (10) years"
            textareaLabel="Please provide details"
            model="legal_disciplinary.staff_license_revocation"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label:
          'Has any staff member currently associated with your organization been convicted of a felony, sexual offense, assault, or crime of moral turpitude, within the previous 10 years?',
        model: 'staff_felony_conviction',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Has any staff member currently associated with your organization been convicted of a felony, sexual offense, assault, or crime of moral turpitude, within the previous 10 years?"
            textareaLabel="Please provide details"
            model="legal_disciplinary.staff_felony_conviction"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Has any staff member currently associated with your organization been convicted of child abuse or any child sexual abuse?',
        model: 'staff_child_abuse_conviction',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Has any staff member currently associated with your organization been convicted of child abuse or any child sexual abuse?"
            textareaLabel="Please provide details"
            model="legal_disciplinary.staff_child_abuse_conviction"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Has any staff member currently associated with your organization been convicted of abuse or neglect of a vulnerable adult?',
        model: 'staff_vulnerable_adult_abuse',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Has any staff member currently associated with your organization been convicted of abuse or neglect of a vulnerable adult?"
            textareaLabel="Please provide details"
            model="legal_disciplinary.staff_vulnerable_adult_abuse"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Has any staff member currently associated been convicted of a felony within the previous ten (10) years?',
        model: 'staff_recent_felony',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Has any staff member currently associated been convicted of a felony within the previous ten (10) years?"
            textareaLabel="Please provide details"
            model="legal_disciplinary.staff_recent_felony"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label:
          'Has any staff member currently associated with your organization ever been convicted of a sexual assault, sexual abuse, or second-degree assault?',
        model: 'staff_sexual_assault_conviction',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Has any staff member currently associated with your organization ever been convicted of a sexual assault, sexual abuse, or second-degree assault?"
            textareaLabel="Please provide details"
            model="legal_disciplinary.staff_sexual_assault_conviction"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Does any staff member currently associated with your organization have pending charges for a sexual assault?',
        model: 'staff_pending_sexual_assault',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Does any staff member currently associated with your organization have pending charges for a sexual assault?"
            textareaLabel="Please provide details"
            model="legal_disciplinary.staff_pending_sexual_assault"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Financial and Ownership Information',
    model: 'financial_ownership',
    fields: [
      {
        label:
          'Has your organization or a program, partnership, corporation, or provider previously or currently associated with the applicant, received funding within the previous 5 years from any federal, state, or local governmental entity to support the construction, renovation, maintenance, and operation of a recovery residence?',
        model: 'received_government_funding',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Has your organization or a program, partnership, corporation, or provider previously or currently associated with the applicant, received funding within the previous 5 years from any federal, state, or local governmental entity to support the construction, renovation, maintenance, and operation of a recovery residence?"
            textareaLabel="Please provide details"
            model="financial_ownership.received_government_funding"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label:
          'Does your organization or the principals owe money to the Maryland Department of Health or one of its Administrations or any Local Designated Authority?',
        model: 'owes_money_to_health_dept',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Does your organization or the principals owe money to the Maryland Department of Health or one of its Administrations or any Local Designated Authority?"
            textareaLabel="Please provide details"
            model="financial_ownership.owes_money_to_health_dept"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label:
          'Provide a listing of any individual who has a 5 percent or more ownership stake in the recovery residence or legal business entity associated with the recovery residence or, if constituted as a non-profit, a listing of the names, percentage of stake in the recovery residence and contact information of all Board members.',
        model: 'ownership_listing',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Provide a listing of any individual who has a 5 percent or more ownership stake in the recovery residence or legal business entity associated with the recovery residence or, if constituted as a non-profit, a listing of the names, percentage of stake in the recovery residence and contact information of all Board members."
            model="financial_ownership.ownership_listing"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label:
          'Provide the names of any other recovery residences or legal business entities associated with a recovery residence or similar program in which the applicant or the principals have, or have had, an ownership interest within the previous 10 years.',
        model: 'other_recovery_residences',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Provide the names of any other recovery residences or legal business entities associated with a recovery residence or similar program in which the applicant or the principals have, or have had, an ownership interest within the previous 10 years."
            model="financial_ownership.other_recovery_residences"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label:
          'Have you or your organization had a revocation or surrender of a prior license, certificate, or approval issued within the previous five (5) years from any in-state or out-of-state provider?',
        model: 'prior_license_revocation',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Have you or your organization had a revocation or surrender of a prior license, certificate, or approval issued within the previous five (5) years from any in-state or out-of-state provider?"
            textareaLabel="Please provide any associated deficiency reports or compliance records with this application"
            model="financial_ownership.prior_license_revocation"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label:
          'Have you or the program, corporation, or provider previously or currently associated with this application, surrendered or defaulted on its license, certificate, or approval, within the previous five (5) years, for reasons related to disciplinary action and the nature of the disciplinary action? (If yes, please explain)',
        model: 'license_surrender_disciplinary_action',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Have you or the program, corporation, or provider previously or currently associated with this application, surrendered or defaulted on its license, certificate, or approval, within the previous five (5) years, for reasons related to disciplinary action and the nature of the disciplinary action? (If yes, please explain)"
            textareaLabel="Please explain"
            model="financial_ownership.license_surrender_disciplinary_action"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label:
          'Please list the names of any individual employee, staff member, peer, or volunteer currently associated with the applicant who has had a professional license or certification revoked or suspended or has surrendered a professional license or certification for reasons related to disciplinary action or misconduct, or been convicted of a felony, within the previous ten (10) years, and the nature of the disciplinary action or misconduct or felony:',
        model: 'staff_disciplinary_history',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Please list the names of any individual employee, staff member, peer, or volunteer currently associated with the applicant who has had a professional license or certification revoked or suspended or has surrendered a professional license or certification for reasons related to disciplinary action or misconduct, or been convicted of a felony, within the previous ten (10) years, and the nature of the disciplinary action or misconduct or felony:"
            model="financial_ownership.staff_disciplinary_history"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
]

export const CERTIFICATION_FORM_LOCATION = [
  {
    title: 'Residence Information',
    model: 'residence_info',
    fields: [
      {
        label: 'Name of Residence',
        model: 'name',
        component: ({ isRequired }) => (
          <Input
            label="Name of Residence"
            model="residence_info.name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Residence Address',
        model: 'address',
        component: ({ isRequired }) => (
          <AddressSelectorInput
            label="Residence Address"
            model="residence_info.address"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Describe the Residence',
        model: 'description',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Describe the Residence"
            model="residence_info.description"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'License Number',
        model: 'license_number',
        component: ({ isRequired }) => (
          <Input
            label="License Number"
            model="residence_info.license_number"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'CARF/TJC Accreditation Number',
        model: 'accreditation_number',
        component: ({ isRequired }) => (
          <Input
            label="CARF/TJC Accreditation Number"
            model="residence_info.accreditation_number"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'House Manager Details',
    model: 'house_manager',
    fields: [
      {
        label: 'House Manager Name',
        model: 'name',
        component: ({ isRequired }) => (
          <Input
            label="House Manager Name"
            model="house_manager.name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'House Manager Phone',
        model: 'phone',
        component: ({ isRequired }) => (
          <PhoneInput
            label="House Manager Phone"
            model="house_manager.phone"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'House Manager Email',
        model: 'email',
        component: ({ isRequired }) => (
          <EmailInput
            label="House Manager Email"
            model="house_manager.email"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Is house manager compensated for job duties. If so how.',
        model: 'compensation',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Is house manager compensated for job duties. If so how."
            textareaLabel="Compensation details"
            model="house_manager.compensation"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Does the house manager live in the residence',
        model: 'lives_in_residence',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Does the house manager live in the residence"
            textareaLabel="Reason for not living in residence"
            model="house_manager.lives_in_residence"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'House manager hours on duty',
        model: 'hours_on_duty',
        component: ({ isRequired }) => (
          <Input
            label="House manager hours on duty"
            model="house_manager.hours_on_duty"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Residence Capacity',
    model: 'capacity',
    fields: [
      {
        label: 'Number of Clients (residential and outpatient)',
        model: 'total_clients',
        component: ({ isRequired }) => (
          <Input
            label="Number of Clients (residential and outpatient)"
            model="capacity.total_clients"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Number of Units',
        model: 'units',
        component: ({ isRequired }) => (
          <Input
            label="Number of Units"
            model="capacity.units"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Number of Beds',
        model: 'beds',
        component: ({ isRequired }) => (
          <Input
            label="Number of Beds"
            model="capacity.beds"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Number of Bedrooms',
        model: 'bedrooms',
        component: ({ isRequired }) => (
          <Input
            label="Number of Bedrooms"
            model="capacity.bedrooms"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Number of Bathrooms',
        model: 'bathrooms',
        component: ({ isRequired }) => (
          <Input
            label="Number of Bathrooms"
            model="capacity.bathrooms"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Number of Half Bathrooms',
        model: 'half_bathrooms',
        component: ({ isRequired }) => (
          <Input
            label="Number of Half Bathrooms"
            model="capacity.half_bathrooms"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Residence Details',
    model: 'residence_details',
    fields: [
      {
        label: 'Type of Dwelling',
        model: 'dwelling_type',
        component: ({ isRequired }) => (
          <RadioGroup
            label="Type of Dwelling"
            layout="vertical-dense"
            model="residence_details.dwelling_type"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          >
            <Radio label="Single Family" value="single_family" />
            <Radio label="Duplex" value="duplex" />
            <Radio label="Triplex" value="triplex" />
            <Radio label="Apartment" value="apartment" />
            <Radio label="Other" value="other" />
          </RadioGroup>
        ),
      },
      {
        label: 'Level of Support Provided',
        model: 'support_level',
        component: ({ isRequired }) => (
          <RadioGroup
            label="Level of Support Provided"
            layout="vertical-dense"
            model="residence_details.support_level"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          >
            <Radio label="Level I" value="level_1" />
            <Radio label="Level II" value="level_2" />
            <Radio label="Level III" value="level_3" />
            <Radio label="Level IV" value="level_4" />
          </RadioGroup>
        ),
      },
      {
        label: 'Population Served',
        model: 'population_served',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Population Served"
            model="residence_details.population_served"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Are you renting location',
        model: 'is_rented',
        component: ({ isRequired }) => (
          <>
            <YesNoRadioGroup
              label="Are you renting location"
              model="residence_details.is_rented"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />

            <ContextShow when="residence_details.is_rented" is={true}>
              <Input label="Owner Name" model="residence_details.owner_name" />
              <PhoneInput label="Owner Phone" model="residence_details.owner_phone_no" />
              <EmailInput label="Owner Email" model="residence_details.owner_email" />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Does residence permit MAT',
        model: 'permits_mat',
        component: ({ isRequired }) => (
          <>
            <YesNoRadioGroup
              label="Does residence permit MAT"
              model="residence_details.permits_mat"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
            <SmartTextarea
              useDictation
              useQuickText={false}
              label="MAT policy details"
              model="residence_details.permits_mat_description"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
          </>
        ),
      },
      {
        label: 'Is residence abstinence based',
        model: 'is_abstinence_based',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Is residence abstinence based"
            textareaLabel="Non-abstinence policy details"
            model="residence_details.is_abstinence_based"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Recovery Path',
        model: 'recovery_path',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Recovery Path"
            model="residence_details.recovery_path"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Costs and Fees',
    model: 'costs',
    fields: [
      {
        label: 'Cost - General',
        model: 'general',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Cost - General"
            model="costs.general"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Full Move in Cost',
        model: 'full_move_in',
        component: ({ isRequired }) => (
          <Input
            label="Full Move in Cost"
            model="costs.full_move_in"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Pro-rated Amount',
        model: 'pro_rated',
        component: ({ isRequired }) => (
          <Input
            label="Pro-rated Amount"
            model="costs.pro_rated"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Administrative Fee Cost',
        model: 'admin_fee',
        component: ({ isRequired }) => (
          <Input
            label="Administrative Fee Cost"
            model="costs.admin_fee"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Deposit Amount Cost',
        model: 'deposit',
        component: ({ isRequired }) => (
          <Input
            label="Deposit Amount Cost"
            model="costs.deposit"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'First and Last Amount',
        model: 'first_and_last',
        component: ({ isRequired }) => (
          <Input
            label="First and Last Amount"
            model="costs.first_and_last"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Billing Frequency',
        model: 'billing_frequency',
        component: ({ isRequired }) => (
          <Input
            label="Billing Frequency"
            model="costs.billing_frequency"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Program Fee',
        model: 'program_fee',
        component: ({ isRequired }) => (
          <Input
            label="Program Fee"
            model="costs.program_fee"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Private Room Cost',
        model: 'private_room',
        component: ({ isRequired }) => (
          <Input
            label="Private Room Cost"
            model="costs.private_room"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Shared Room Cost',
        model: 'shared_room',
        component: ({ isRequired }) => (
          <Input
            label="Shared Room Cost"
            model="costs.shared_room"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Ownership and Management',
    model: 'ownership',
    fields: [
      {
        label: 'Residence Ownership',
        model: 'type',
        component: ({ isRequired }) => (
          <RadioGroup
            label="Residence Ownership"
            layout="vertical-dense"
            model="ownership.type"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          >
            <Radio label="Owned" value="owned" />
            <Radio label="Rented" value="rented" />
            <Radio label="Leased" value="leased" />
          </RadioGroup>
        ),
      },
      {
        label: 'Owner Written permission',
        model: 'written_permission',
        component: ({ isRequired }) => (
          <Attachments
            label="Owner Written permission"
            model="ownership.written_permission"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Residence Capacity',
        model: 'capacity',
        component: ({ isRequired }) => (
          <Input
            label="Residence Capacity"
            model="ownership.capacity"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Other Available Space',
        model: 'other_space',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Other Available Space"
            model="ownership.other_space"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Multiple Fees for accommodations at Residence',
        model: 'multiple_fees',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Multiple Fees for accommodations at Residence"
            model="ownership.multiple_fees"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Is food included as part of fee',
        model: 'food_included',
        component: ({ isRequired }) => (
          <>
            <YesNoRadioGroup
              label="Is food included as part of fee"
              model="ownership.food_included"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
            <SmartTextarea
              useDictation
              useQuickText={false}
              label="Food inclusion details"
              model="ownership.food_included_description"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
          </>
        ),
      },
      {
        label: 'Is property Closed',
        model: 'is_closed',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Is property Closed"
            textareaLabel="Closure details"
            model="ownership.is_closed"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Gender',
        model: 'gender',
        component: ({ isRequired }) => (
          <Input
            label="Gender"
            model="ownership.gender"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you manage residence funds',
        model: 'manages_funds',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Do you manage residence funds"
            textareaLabel="Fund management details"
            model="ownership.manages_funds"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Date Established',
        model: 'date_established',
        component: ({ isRequired }) => (
          <Input
            label="Date Established"
            model="ownership.date_established"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you offer the same level of service to all locations',
        model: 'same_service_level',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Do you offer the same level of service to all locations"
            textareaLabel="Service level differences"
            model="ownership.same_service_level"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Staff Information',
    model: 'staff',
    fields: [
      {
        label: 'Total number of Staff',
        model: 'total',
        component: ({ isRequired }) => (
          <Input
            label="Total number of Staff"
            model="staff.total"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Number of FT Administrative Staff',
        model: 'ft_admin',
        component: ({ isRequired }) => (
          <Input
            label="Number of FT Administrative Staff"
            model="staff.ft_admin"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Number of Licensed Staff',
        model: 'licensed',
        component: ({ isRequired }) => (
          <Input
            label="Number of Licensed Staff"
            model="staff.licensed"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Number of Certified Staff',
        model: 'certified',
        component: ({ isRequired }) => (
          <Input
            label="Number of Certified Staff"
            model="staff.certified"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Number of Registered staff',
        model: 'registered',
        component: ({ isRequired }) => (
          <Input
            label="Number of Registered staff"
            model="staff.registered"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Number of FT Support Staff (non-clinical)',
        model: 'ft_support',
        component: ({ isRequired }) => (
          <Input
            label="Number of FT Support Staff (non-clinical)"
            model="staff.ft_support"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Total Part-Time Staff',
        model: 'part_time',
        component: ({ isRequired }) => (
          <Input
            label="Total Part-Time Staff"
            model="staff.part_time"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Total Number of Paid Staff',
        model: 'paid',
        component: ({ isRequired }) => (
          <Input
            label="Total Number of Paid Staff"
            model="staff.paid"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Total Number of Volunteer Staff',
        model: 'volunteer',
        component: ({ isRequired }) => (
          <Input
            label="Total Number of Volunteer Staff"
            model="staff.volunteer"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Additional Information',
    model: 'additional_info',
    fields: [
      {
        label: 'Is residence approved by state DOC',
        model: 'doc_approved',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Is residence approved by state DOC"
            textareaLabel="Reason for non-approval"
            model="additional_info.doc_approved"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Pool Available',
        model: 'pool_available',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Pool Available"
            model="additional_info.pool_available"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Current status',
        model: 'current_status',
        component: ({ isRequired }) => (
          <RadioGroup
            label="Current status"
            layout="vertical-dense"
            model="additional_info.current_status"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          >
            <Radio label="Taking Applicants" value="taking_applicants" />
            <Radio label="Full" value="full" />
            <Radio label="Waiting List" value="waiting_list" />
            <Radio label="Not taking applications" value="not_taking_applications" />
          </RadioGroup>
        ),
      },
      {
        label: 'Faith Based',
        model: 'faith_based',
        component: ({ isRequired }) => (
          <YesNoRadioGroup
            label="Faith Based"
            model="additional_info.faith_based"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Is substance use testing performed',
        model: 'substance_testing',
        component: ({ isRequired }) => (
          <>
            <YesNoRadioGroup
              label="Is substance use testing performed"
              model="additional_info.substance_testing"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
            <SmartTextarea
              useDictation
              useQuickText={false}
              label="Substance testing details"
              model="additional_info.substance_testing_description"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
          </>
        ),
      },
      {
        label: 'Financial Arrangements and Opportunities',
        model: 'financial_arrangements',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="Financial Arrangements and Opportunities"
            model="additional_info.financial_arrangements"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you accept minors',
        model: 'accepts_minors',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Do you accept minors"
            textareaLabel="Minor acceptance details"
            model="additional_info.accepts_minors"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'List any special/additional services',
        model: 'special_services',
        component: ({ isRequired }) => (
          <SmartTextarea
            useDictation
            useQuickText={false}
            label="List any special/additional services"
            model="additional_info.special_services"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Do you have a clinical (PHP or IOP) license from state',
        model: 'clinical_license',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Do you have a clinical (PHP or IOP) license from state"
            textareaLabel="Clinical license details"
            model="additional_info.clinical_license"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Resident Age Groups',
        model: 'age_groups',
        component: ({ isRequired }) => (
          <Input
            label="Resident Age Groups"
            model="additional_info.age_groups"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Location',
        model: 'location_type',
        component: ({ isRequired }) => (
          <RadioGroup
            label="Location"
            layout="vertical-dense"
            model="additional_info.location_type"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          >
            <Radio label="Urban" value="urban" />
            <Radio label="Rural" value="rural" />
            <Radio label="Suburban" value="suburban" />
          </RadioGroup>
        ),
      },
      {
        label: 'Bedroom Sq. Ft.',
        model: 'bedroom_sqft',
        component: ({ isRequired }) => (
          <Input
            label="Bedroom Sq. Ft."
            model="additional_info.bedroom_sqft"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Drug Testing Policies',
    model: 'drug_testing',
    fields: [
      {
        label: 'Onsite urine collection for testing',
        model: 'onsite_urine_collection',
        component: ({ isRequired }) => (
          <>
            <YesNoRadioGroup
              label="Onsite urine collection for testing"
              model="drug_testing.onsite_urine_collection"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
            <SmartTextarea
              useDictation
              useQuickText={false}
              label="Urine collection details"
              model="drug_testing.onsite_urine_collection_description"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
          </>
        ),
      },
      {
        label: 'Onsite drug testing',
        model: 'onsite_testing',
        component: ({ isRequired }) => (
          <>
            <YesNoRadioGroup
              label="Onsite drug testing"
              model="drug_testing.onsite_testing"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
            <SmartTextarea
              useDictation
              useQuickText={false}
              label="Onsite testing details"
              model="drug_testing.onsite_testing_description"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
          </>
        ),
      },
      {
        label: 'Third party service for onsite sample collection',
        model: 'third_party_collection',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Third party service for onsite sample collection"
            textareaLabel="Third party collection details"
            model="drug_testing.third_party_collection"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'We send samples to a lab for confirmation',
        model: 'lab_confirmation',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="We send samples to a lab for confirmation"
            textareaLabel="Lab confirmation details"
            model="drug_testing.lab_confirmation"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Does third party/and or lab bill to insurance',
        model: 'insurance_billing',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Does third party/and or lab bill to insurance"
            textareaLabel="Insurance billing details"
            model="drug_testing.insurance_billing"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Does your recovery residence bill this cost to insurance',
        model: 'residence_insurance_billing',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Does your recovery residence bill this cost to insurance"
            textareaLabel="Residence insurance billing details"
            model="drug_testing.residence_insurance_billing"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'We collect saliva or other bodily fluids for lab testing',
        model: 'other_fluid_collection',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="We collect saliva or other bodily fluids for lab testing"
            textareaLabel="Other fluid collection details"
            model="drug_testing.other_fluid_collection"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Residence Policies',
    model: 'policies',
    fields: [
      {
        label: 'Are children or significant others permitted to live at the residence?',
        model: 'children_permitted',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Are children or significant others permitted to live at the residence?"
            textareaLabel="Children/SO policy details"
            model="policies.children_permitted"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Is social participation required of residents',
        model: 'social_participation',
        component: ({ isRequired }) => (
          <>
            <YesNoRadioGroup
              label="Is social participation required of residents"
              model="policies.social_participation"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
            <SmartTextarea
              useDictation
              useQuickText={false}
              label="Social participation policy details"
              model="policies.social_participation_description"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
          </>
        ),
      },
      {
        label: 'Is the location accessible to public transportation or is transportation provided',
        model: 'transportation',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Is the location accessible to public transportation or is transportation provided"
            textareaLabel="Transportation details"
            model="policies.transportation"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Square Footage',
        model: 'square_footage',
        component: ({ isRequired }) => (
          <Input
            label="Square Footage"
            model="policies.square_footage"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Number of Refrigerators',
        model: 'refrigerators',
        component: ({ isRequired }) => (
          <Input
            label="Number of Refrigerators"
            model="policies.refrigerators"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Does the program limit the use or dosage of any form of medication',
        model: 'medication_limits',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            label="Does the program limit the use or dosage of any form of medication"
            textareaLabel="Medication limit details"
            model="policies.medication_limits"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Does program provide safe storage of all medications',
        model: 'medication_storage',
        component: ({ isRequired }) => (
          <>
            <YesNoRadioGroup
              label="Does program provide safe storage of all medications"
              model="policies.medication_storage"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
            <SmartTextarea
              useDictation
              useQuickText={false}
              label="Medication storage details"
              model="policies.medication_storage_description"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
          </>
        ),
      },
      {
        label: 'Is there at least one full bathroom for each six (6) residents?',
        model: 'bathroom_ratio',
        component: ({ isRequired }) => (
          <YesNoRadioGroupWithTextarea
            showTextareaWhen={false}
            label="Is there at least one full bathroom for each six (6) residents?"
            textareaLabel="Bathroom ratio details"
            model="policies.bathroom_ratio"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
]

export const CERTIFICATION_FORM_CONTACT = [
  {
    title: 'Owner/Operator Information',
    model: 'owner_operator_info',
    fields: [
      {
        label: 'Name of Owner/Operator',
        model: 'name',
        component: ({ isRequired }) => (
          <Input
            label="Name of Owner/Operator"
            model="owner_operator_info.name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Contact Name',
        model: 'contact_name',
        component: ({ isRequired }) => (
          <Input
            label="Contact Name"
            model="owner_operator_info.contact_name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Contact Person Title',
        model: 'contact_title',
        component: ({ isRequired }) => (
          <Input
            label="Contact Person Title"
            model="owner_operator_info.contact_title"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Contact Person Phone Number',
        model: 'contact_phone',
        component: ({ isRequired }) => (
          <PhoneInput
            label="Contact Person Phone Number"
            model="owner_operator_info.contact_phone"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Contact Person Email Address',
        model: 'contact_email',
        component: ({ isRequired }) => (
          <EmailInput
            label="Contact Person Email Address"
            model="owner_operator_info.contact_email"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Contact Address',
        model: 'contact_address',
        component: ({ isRequired }) => (
          <AddressSelectorInput
            label="Contact Address"
            model="owner_operator_info.contact_address"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Contact Social Security Number',
        model: 'contact_ssn',
        component: ({ isRequired }) => (
          <Input
            label="Contact Social Security Number"
            model="owner_operator_info.contact_ssn"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Contact Date of Birth',
        model: 'contact_dob',
        component: ({ isRequired }) => (
          <Input
            label="Contact Date of Birth"
            model="owner_operator_info.contact_dob"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Qualifications',
    model: 'qualifications',
    fields: [
      {
        label: 'Credentials/Licenses/Certifications',
        model: 'credentials',
        component: ({ isRequired }) => (
          <>
            <SmartTextarea
              useDictation
              useQuickText={false}
              label="Credentials/Licenses/Certifications"
              model="qualifications.credentials"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />

            <Attachments
              label="Credentials/Licenses/Certifications"
              model="qualifications.credentials_attachments"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
          </>
        ),
      },
      {
        label: 'Previous Training',
        model: 'previous_training',
        component: ({ isRequired }) => (
          <>
            <SmartTextarea
              useDictation
              useQuickText={false}
              label="Previous Training"
              model="qualifications.previous_training"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />

            <Attachments
              label="Credentials/Licenses/Certifications"
              model="qualifications.credentials_attachments"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
          </>
        ),
      },
    ],
  },
  {
    title: 'Billing Information',
    model: 'billing_info',
    fields: [
      {
        label: 'Billing Contact Name',
        model: 'contact_name',
        component: ({ isRequired }) => (
          <Input
            label="Billing Contact Name"
            model="billing_info.contact_name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Billing Contact Address',
        model: 'contact_address',
        component: ({ isRequired }) => (
          <AddressSelectorInput
            label="Billing Contact Address"
            model="billing_info.contact_address"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Billing Contact Phone',
        model: 'contact_phone',
        component: ({ isRequired }) => (
          <PhoneInput
            label="Billing Contact Phone"
            model="billing_info.contact_phone"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Billing Contact Email',
        model: 'contact_email',
        component: ({ isRequired }) => (
          <EmailInput
            label="Billing Contact Email"
            model="billing_info.contact_email"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
  {
    title: 'Voting Information',
    model: 'voting_info',
    fields: [
      {
        label: 'Voting Member Name',
        model: 'member_name',
        component: ({ isRequired }) => (
          <Input
            label="Voting Member Name"
            model="voting_info.member_name"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Voting Member Email',
        model: 'member_email',
        component: ({ isRequired }) => (
          <EmailInput
            label="Voting Member Email"
            model="voting_info.member_email"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
      {
        label: 'Is Proxy Voting Allowed',
        model: 'proxy_voting',
        component: ({ isRequired }) => (
          <>
            <YesNoRadioGroup
              label="Is Proxy Voting Allowed"
              model="voting_info.proxy_voting"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
            <SmartTextarea
              useDictation
              useQuickText={false}
              label="Please explain"
              model="voting_info.proxy_voting_description"
              withHover={false}
              validations={
                isRequired && {
                  presence: {
                    message: 'This field is required',
                  },
                }
              }
            />
          </>
        ),
      },
    ],
  },
  {
    title: 'Directory Information',
    model: 'directory_info',
    fields: [
      {
        label: 'Please list contact phone number for admissions to be listed in the KRHN directory',
        model: 'admissions_phone',
        component: ({ isRequired }) => (
          <PhoneInput
            label="Please list contact phone number for admissions to be listed in the KRHN directory"
            model="directory_info.admissions_phone"
            withHover={false}
            validations={
              isRequired && {
                presence: {
                  message: 'This field is required',
                },
              }
            }
          />
        ),
      },
    ],
  },
]
