import React from 'react'
import { Link, NavLink, Navigate, Route, Routes } from 'react-router-dom-v5-compat'

import Button from '@behavehealth/components/Button'
import PageHeader from '@behavehealth/components/PageHeader'
import Tabs from '@behavehealth/components/Tabs'
import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { useSettings } from '@behavehealth/hooks/useSettings'

import { CommunityLeads } from './community_invites/community_leads'
import { CommunityInvitesSent } from './community_invites/community_invites_sent'
import { CommunityInvitesReceived } from './community_invites/community_invites_received'

import { PartnershipInviteSentOverlay } from '@behavehealth/constructs/Partnerships/PartnershipInviteSentOverlay'
import { PartnershipInviteReceivedOverlay } from '@behavehealth/constructs/Partnerships/PartnershipInviteReceivedOverlay'

export const CommunityInvites: React.FC = () => {
  const { isBehave } = useSettings()

  return (
    <>
      <div css={STYLES.root}>
        <div className="bg-white sticky top-0 z-10 flex-0">
          <PageHeader
            title="Community Inbox"
            icon="community_invites"
            aside={
              <Button
                as={Link}
                label="Explore Community →"
                icon="community"
                type="minimal"
                link="/community/explore"
                permission="community_explore.view"
              />
            }
          />

          <Tabs>
            <Tabs.List className="px-4">
              {isBehave && <Tabs.Item as={NavLink} label="Leads" to="leads" />}
              <Tabs.Item as={NavLink} label="Invites Sent" to="sent" />
              <Tabs.Item as={NavLink} label="Invites Received" to="received" />
            </Tabs.List>
          </Tabs>
        </div>

        <div css={STYLES.content}>
          <Routes>
            <Route index element={<Navigate to="sent" replace />} />

            <Route path="leads/*" element={<CommunityLeads />} />
            <Route path="sent/*" element={<CommunityInvitesSent />} />
            <Route path="received/*" element={<CommunityInvitesReceived />} />
          </Routes>
        </div>
      </div>

      <AnimatedRoutes>
        <Route path="sent/:status/:id" element={<PartnershipInviteSentOverlay useV6Router />} />
        <Route path="received/:status/:id" element={<PartnershipInviteReceivedOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100%',
    overflow: 'hidden',
  },

  content: {
    padding: '1rem',
    overflowY: 'auto',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: '100%',
  },
}
