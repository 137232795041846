import React from 'react'
import snakeCase from 'lodash/snakeCase'

import { H4, P } from '../../components/Typography'
import { COLORS } from '../../theme'

import Alert from '../../components/Alert'
import AmountInput from '../../components/Forms/AmountInput'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextHide from '../../components/Forms/ContextHide'
import ContextShow from '../../components/Forms/ContextShow'
import DataArray from '../../components/Forms/DataArray'
import DateInput from '../../components/Forms/DateInput'
import DateOfBirthInput from '../../components/Forms/DateOfBirthInput'
import Divider from '../../components/Divider'
import EmailInput from '../../components/Forms/EmailInput'
import Flex from '../../components/Flex'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import PhoneInput from '../../components/Forms/PhoneInput'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Select from '../../components/Forms/Select'
import SexualOrientationSelect from '../../components/Forms/elements/SexualOrientationSelect'
import SignaturePad from '../../components/Forms/SignaturePad'
import Textarea from '../../components/Forms/Textarea'

import { FileAttachmentsInput } from '../../components/Forms/FileAttachmentsInput'

import {
  AddictionAreasCheckboxGroup,
  AddressInputs,
  ContactInputs,
  ContactMethodsCheckboxGroup,
  ContactMethodsRadioGroup,
  DrugsOfChoiceSelector,
  EthnicitySelect,
  GamblingTypesCheckboxGroup,
  GenderIdentitySelect,
  HeightInput,
  HighestEducationLevelSelect,
  InsurancePolicyInputs,
  LegalRequirementsInputs,
  MaritalStatusRadioGroup,
  MedicalConditionsCheckboxGroup,
  MedicalEquipmentCheckboxGroup,
  MedicationDescriptionSelect,
  PreferredPaymentMethodRadioGroup,
  PrimaryLanguageSelect,
  PrimaryTransportationModeRadioGroup,
  ProgramTypeRadioGroup,
  ProviderTypesCheckboxGroup,
  RelationshipSelect,
  ReligiousPreferenceSelect,
  SexSelect,
  TwelveStepMeetingsCheckboxGroup,
  WeightInput,
  YesNoRadioGroup,
  YesNoRadioGroupWithTextarea,
} from '../../components/Forms/elements'

import { ORGANIZATIONS_TYPE_PROVIDERS, UATests } from '../../utils/constants'
import URLInput from '../../components/Forms/URLInput'

type FieldProps = {
  companyName: string
  isRequired: boolean
}

type FormCardProps = {
  children: React.ReactNode
  description: string
  headerAside: React.ReactNode
  maxWidth?: boolean | string
  title: string
}

type DataArraySectionProps = {
  children: React.ReactNode
  description: string
  itemTitle: string
  maxWidth?: boolean | string
  model: string
  title: string
}

const FormCard = (props: FormCardProps) => {
  const { title, description, headerAside, children, maxWidth } = props

  return (
    <div
      css={{
        borderRadius: 5,
        border: `2px solid ${COLORS.divider}`,
        padding: '0.5rem 1.25rem 1.25rem',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '0.5rem',
        }}
      >
        <div className="my-2 mx-0">
          {title && <H4>{title}</H4>}
          {description && <P className="!mt-1 !mx-0 !mb-0">{description}</P>}
        </div>
        {headerAside}
      </div>

      <FormSection maxWidth={maxWidth}>{children}</FormSection>
    </div>
  )
}

const DataArraySection = (props: DataArraySectionProps) => {
  const { children, description, itemTitle, model, title, maxWidth } = props

  return (
    <>
      {(title || description) && (
        <div className="!mt-2">
          {title && <H4>{title}</H4>}
          {description && <P className="!mt-1 !mx-0 !mb-0">{description}</P>}
        </div>
      )}

      <DataArray model={model}>
        {({ orderedIds, add, remove, isEditable }) => (
          <>
            {orderedIds?.map((id: string, idx: number) => (
              <FormCard
                key={id}
                maxWidth={maxWidth}
                title={`${itemTitle} #${idx + 1}`}
                headerAside={isEditable && <Button type="minimal" color="red" glyph="delete" label="Remove" onClick={() => remove(id)} />}
              >
                {children(id)}
              </FormCard>
            ))}

            {isEditable && (
              <div>
                <Button label={`Add New ${itemTitle}`} glyph="add" type="default" display="inline-flex" size={200} onClick={add} />
              </div>
            )}
          </>
        )}
      </DataArray>
    </>
  )
}

export const data = [
  {
    title: 'Organization',
    model: 'organization',
    glyph: 'behave_health',
    isOpen: true,
    isRequired: true,
    fields: [
      {
        label: 'Organization Name',
        model: 'name',
        isDisabled: true,
        isChecked: true,
        isRequired: true,
        component: () => (
          <>
            <Input label="Organization Name" model="organization.name" />

            <div className="!hidden">
              <Select isEditable={false} label="Type" model="category" defaultValue="vendor" value="provider">
                <Option label="Vendor" value="vendor" />
                <Option label="Community Resource" value="resource" />
                <Option label="Provider" value="provider" />
              </Select>
            </div>
          </>
        ),
      },
      {
        label: 'Category',
        model: 'category',
        isChecked: true,
        isRequired: true,
        component: () => (
          <>
            <Select
              label="Category"
              model="subcategory"
              defaultValue="sober_living_home"
              validations={{
                presence: {
                  message: 'Please select an organization category',
                },
              }}
            >
              {Object.keys(ORGANIZATIONS_TYPE_PROVIDERS).map((provider) => (
                <Option label={ORGANIZATIONS_TYPE_PROVIDERS[provider]} value={provider} />
              ))}
            </Select>
          </>
        ),
      },
      {
        label: 'Email',
        model: 'email',
        isChecked: true,
        component: () => <EmailInput label="Email" model="organization.email" />,
      },
      {
        label: 'Phone Number',
        model: 'phone_no',
        isChecked: true,
        component: () => (
          <Flex horizontal gap="1rem">
            <PhoneInput css={{ flex: '1 1 auto' }} model="phone_no" label="Phone Number" type="tel" />
            <Input css={{ width: 145 }} glyph="phone_ext" label="Phone No Extension" model="phone_no_ext" />
          </Flex>
        ),
      },
      {
        label: 'Fax',
        model: 'fax',
        component: () => <Input glyph="fax" label="Fax" model="fax" />,
      },
      {
        label: 'Notes',
        model: 'notes',
        component: () => <Textarea useQuickText model="additional_info" label="Notes" />,
      },
      {
        label: 'Address',
        model: 'address',
        component: () => <AddressInputs label="Address" model="organization.address" />,
      },
      {
        label: 'Website',
        model: 'website',
        component: () => <URLInput label="Website" model="website_url" glyph="website" />,
      },
      {
        label: 'Facebook',
        model: 'facebook',
        component: () => <URLInput glyph="facebook" label="Facebook" model="facebook" />,
      },
      {
        label: 'Instagram',
        model: 'instagram',
        component: () => <URLInput glyph="instagram" label="Instagram" model="instagram" />,
      },
      {
        label: 'Google Business',
        model: 'google_business',
        component: () => <URLInput glyph="googleplus" label="Google Business" model="google_business" />,
      },
      {
        label: 'WhatApp',
        model: 'whatsapp',
        component: () => <URLInput glyph="whatsapp" label="WhatApp" model="whatsapp" />,
      },
      {
        label: 'Zoom',
        model: 'zoom',
        component: () => <URLInput glyph="zoom" label="Zoom" model="zoom" />,
      },
      {
        label: 'Google',
        model: 'google_meet',
        component: () => <URLInput glyph="googleplus" label="Google Meet" model="google_meet" />,
      },
      {
        label: 'Skype',
        model: 'skype',
        component: () => <URLInput glyph="skype" label="Skype" model="skype" />,
      },
    ],
  },
  {
    title: 'Home / Location',
    model: 'locations',
    glyph: 'behave_health',
    isDataArray: true,
    fields: [
      {
        label: 'Name of Residence',
        model: 'name',
        isDisabled: true,
        isChecked: true,
        isRequired: true,
        component: () => <Input label="Name of Residence" model="locations.name" />,
      },
      {
        label: 'Residence Business Address',
        model: 'email',
        component: () => <AddressInputs label="Residence Business Address" model="locations.address" />,
      },
    ],
  },
  {
    title: 'Contact',
    model: 'contact',
    glyph: 'behave_health',
    isDataArray: true,
    fields: [
      {
        label: 'First Name',
        model: 'first_name',
        isDisabled: true,
        isChecked: true,
        isRequired: true,
        component: () => (
          <Input
            label="First Name"
            model="contact.first_name"
            validations={{
              presence: {
                message: 'Please enter your first name',
              },
            }}
          />
        ),
      },
      {
        label: 'Middle Name',
        model: 'middle_name',
        isDisabled: false,
        isRequiredDisabled: true,
        isChecked: true,
        component: () => <Input label="Middle Name" model="contact.middle_name" />,
      },
      {
        label: 'Last Name',
        model: 'last_name',
        isDisabled: true,
        isChecked: true,
        isRequired: true,
        component: () => (
          <Input
            label="Last Name"
            model="contact.last_name"
            validations={{
              presence: {
                message: 'Please enter your last name',
              },
            }}
          />
        ),
      },
      {
        label: 'Email Address',
        model: 'email',
        component: () => <EmailInput label="Email Address" model="contact.email" />,
      },
      {
        label: 'Phone Number',
        model: 'phone_no',
        component: () => <PhoneInput label="Phone Number" model="contact.phone_no" />,
      },
    ],
  },
]
