import React from 'react'
import { NavLink, Route } from 'react-router-dom-v5-compat'
import kebabCase from 'lodash/kebabCase'
import size from 'lodash/size'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Status from '@behavehealth/components/Status'
import Tabs from '@behavehealth/components/Tabs'
import Divider from '@behavehealth/components/Divider'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'
import { NavGroupLabel } from '@behavehealth/components/NavGroup'

import { AUTHORITY_FORMS } from '@behavehealth/constructs/AuthorityOrganizations/data'
import { AuthorityFormBuilder } from '@behavehealth/constructs/Authority/AuthorityFormBuilder'
import { AUTHORITY_FORMS_NAV } from '@behavehealth/constructs/Authority/authority_forms'

const RootAuthorityForms = () => {
  return (
    <>
      <AuthorityFormsIndex />

      <AnimatedRoutes>
        <Route path=":slug/:id" element={<AuthorityFormBuilder useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const AuthorityFormsIndex = () => {
  const to = React.useCallback((data) => data.id, [])

  const columns = React.useMemo(
    () => [
      {
        width: 260,
        id: 'name',
        model: 'name',
        title: 'Name',
        formatValue: ({ value, data }: any) => <MainCell as={NavLink} to={kebabCase(value)} value={value} />,
      },
      {
        width: 200,
        model: 'status',
        title: 'Status',
        formatValue: () => <Status label="Active" color="green" />,
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'form_submissions.edit',
        disableSort: true,
      },
      {
        title: 'Date Created',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Edited',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <Page
      title="Authority Forms"
      icon="authority_forms"
      actions={
        <Dropdown testKey="add_new_dropdown" label={`Add New…`} buttonType="primary" glyph="add">
          {AUTHORITY_FORMS_NAV.map((group, index) => (
            <React.Fragment key={group.title}>
              {index > 0 && <Divider />}
              <NavGroupLabel label={group.title} className="px-4 pt-3 pb-1" />

              {group.forms.map((form) => (
                <DropdownItem
                  key={form.id}
                  as={NavLink}
                  link={`${kebabCase(form.slug)}/new`}
                  icon={group.icon}
                  label={form.title}
                  isDisabled={!form.sections}
                />
              ))}
            </React.Fragment>
          ))}
        </Dropdown>
      }
    >
      <Grid>
        <Tabs defaultTab="all">
          <Tabs.List className="-mt-4 mb-4">
            <Tabs.Item label="All Forms" name="all" />
            <Tabs.Item label="Certifications" name="certifications" />
            <Tabs.Item label="Inspections" name="inspections" />
            <Tabs.Item label="Complaints" name="complaints" />
            <Tabs.Item label="Issues" name="issues" />
            <Tabs.Item label="Studies" name="studies" />
            <Tabs.Item label="Surveys" name="surveys" />
          </Tabs.List>
        </Tabs>

        <DataTable
          asCard
          to={to}
          columns={columns}
          title="Authority Forms"
          data={Object.values(AUTHORITY_FORMS).map((form) => ({ id: Math.random(), name: form }))}
          meta={{ count: size(AUTHORITY_FORMS) }}
        />
      </Grid>
    </Page>
  )
}

export const AuthorityForms = withPageError(RootAuthorityForms)
